import React from 'react';
import {AppLayout} from './components/Layout';
import Routes from './Routes';
import RecoilProvider from './containers/RecoilProvider';
import CurrentUser from './containers/CurrentUser';
import {MetaMaskProvider} from 'metamask-react';
import 'react-datepicker/dist/react-datepicker.css';

const App: React.FC = () => {
  return (
    <RecoilProvider>
      <MetaMaskProvider>
        <CurrentUser>
          <AppLayout>
            <Routes />
          </AppLayout>
        </CurrentUser>
      </MetaMaskProvider>
    </RecoilProvider>
  );
};

export default App;
