import styled from 'styled-components';
import {theme} from '../theme';
import {Text} from '../Typography/Typography';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 440px;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftArrowWrapper = styled.div`
  cursor: pointer;
  width: 9px;
  margin-right: 24px;
`;

export const RightArrowWrapper = styled.div`
  cursor: pointer;
  margin-left: 24px;
  width: 9px;
`;
export const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  cursor: pointer;
  height: 41px;
  border-radius: 50%;
  background-color: ${(props) => (props.theme.current ? theme.pallet.blueScale._7 : theme.pallet.background.quinary)};
  margin-right: 11px;
  margin-left: 11px;
`;

export const StepValue = styled(Text).attrs({
  level: 1,
  strong: true,
})``;
