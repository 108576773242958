import React from 'react';
import {PacmanLoader} from 'react-spinners';
import {theme} from '../theme';
import {LoaderWrapper} from './styles';

export const Loader: React.FC = () => {
  return (
    <LoaderWrapper>
      <PacmanLoader color={theme.pallet.blueScale._7} />
    </LoaderWrapper>
  );
};
