import React from 'react';
import {
  FormWrapper,
  FormLine,
  FormTitle,
  BackButtonWrapper,
  BackButtonText,
  ConfirmStepWrapper,
  FirstColumn,
  SecondColumn,
  DataCenterLogoWrapper,
  TotalCost,
  DataCenterInfoWrapper,
  DataCenterPrice,
  TotalCostWrapper,
  CardInfoWrapper,
  MetamaskConfirmWrapper,
  AgreeTerms,
  AgreeTermsWrap,
  Link,
} from './styles';
import {DataCenterAssets} from './index';
import {Button} from '../../ui-kit/Buttons/Button';
import {Input} from '../../ui-kit/Form/Input';
import {Text} from '../../ui-kit/Typography/Typography';
import {Checkbox} from '../../ui-kit/Form/Checkbox';
import {IconSvg} from '../../ui-kit/Icons/Svg';
import {CardFormType} from '../../types/card';
import {FormFooter} from './FormFooter';
import {hiddenCardNumberValue} from '../../helpers/format';
interface Props {
  backStep: () => void;
  zone: string;
  cardData: CardFormType;
  checkTerms: boolean;
  dataCenter: string;
  region: string;
  handlerCheckTerms: (e: React.ChangeEvent<HTMLInputElement>) => void;
  zoneData: {name: string; options: {value: string; label: string}[]}[] | null;
  addValidationRequest: () => Promise<void>;
  amount: string;
  symbol: string;
}

export const ComfirmStep: React.FC<Props> = ({
  zoneData,
  cardData,
  checkTerms,
  region,
  dataCenter,
  zone,
  backStep,
  addValidationRequest,
  handlerCheckTerms,
  amount,
  symbol,
}) => {
  return (
    <FormWrapper>
      <FormTitle>
        Check and confirm:
        <BackButtonWrapper onClick={backStep}>
          <IconSvg type="arrowLeft" width="9" height="16" viewBox="0 0 9 16" />
          <BackButtonText>Back</BackButtonText>
        </BackButtonWrapper>
      </FormTitle>
      <FormLine />
      <ConfirmStepWrapper>
        <FirstColumn>
          <DataCenterLogoWrapper>
            {DataCenterAssets.find((el) => el.value === dataCenter)?.image || null}
          </DataCenterLogoWrapper>
          <Button disabled={true}>{DataCenterAssets.find((el) => el.value === dataCenter)?.label}</Button>
          <DataCenterInfoWrapper>
            {region}
            <DataCenterPrice>
              {zoneData?.find((el) => el.name === zone)?.options.find((el) => el.label)?.value}
            </DataCenterPrice>
          </DataCenterInfoWrapper>
        </FirstColumn>
        <SecondColumn>
          <TotalCostWrapper>
            <Text color={'gray'}>Total cost:</Text>
            <TotalCost>
              {amount} {symbol}
            </TotalCost>
          </TotalCostWrapper>
          <Input
            name={'cardName'}
            sizetype={'middle'}
            placeholdertype={'primary'}
            disabled={true}
            value={cardData.cardName.value}
          />
          <Input
            name={'cardNumber'}
            sizetype={'middle'}
            placeholdertype={'primary'}
            value={hiddenCardNumberValue(cardData.cardNumber.value)}
            disabled={true}
            options={{delimiter: '     '}}
          />
          <CardInfoWrapper>
            <Input
              name={'date'}
              sizetype={'middle'}
              placeholdertype={'primary'}
              aligntext={'center'}
              value={cardData.date.value}
              disabled={true}
              options={{
                date: true,
                datePattern: ['m', 'y'],
              }}
            />
            <Input
              name={'cvcCode'}
              type={'password'}
              sizetype={'middle'}
              placeholdertype={'primary'}
              aligntext={'center'}
              disabled={true}
              value={cardData.cvcCode.value}
            />
          </CardInfoWrapper>
        </SecondColumn>
      </ConfirmStepWrapper>
      <MetamaskConfirmWrapper>
        <AgreeTermsWrap>
          <Checkbox $isChecked={checkTerms} onChange={handlerCheckTerms} />
          <Link>
            <AgreeTerms>agree user terms</AgreeTerms>
          </Link>
        </AgreeTermsWrap>
        <Button type={'primary'} size={'large'} onClick={addValidationRequest}>
          Metamask Approve
        </Button>
      </MetamaskConfirmWrapper>
      <FormLine />
      <FormFooter />
    </FormWrapper>
  );
};
