import React, {SetStateAction} from 'react';
import {InvoiceList} from '../../types/user';
import {Layout} from '../../ui-kit/Layout';
import {Columns} from '../../ui-kit/Table/types';
import {CopyIcon} from '../../ui-kit/Icons/CopyIcon';
import {Text} from '../../ui-kit/Typography/Typography';
import {
  AsideWrapper,
  IdentifyWrapper,
  PayButton,
  PaySubtitle,
  RepayButton,
  RepaySubtitle,
  TableContainer,
  TitleText,
  TitleWrapper,
  Wrapper,
} from './styles';
import List from './List';
import {CopyWrapper} from '../Contacts/styles';
import {dateToDDMMYYHHMM} from '../../helpers/date';
import {LinkButton} from '../../ui-kit/Buttons/LinkButton';
import {IconSvg} from '../../ui-kit/Icons/Svg';
import {PaymentStatus, UserCardType} from '../../types/validator';
import CardForm from './Form';
import {CardFormType} from '../../types/card';
type InvoicesPagePropsType = {
  invoices: InvoiceList[];
  setPage: React.Dispatch<SetStateAction<number>>;
  page: number;
  count: number;
  pageSize: number;
  cardsData: {cards: UserCardType[]; defaultCardId?: string};
  setDefaultCard: (cardId: string) => void;
  deleteCard: (cardId: string) => void;
  submitAddCard: () => Promise<boolean>;
  setNewCardData: React.Dispatch<React.SetStateAction<CardFormType>>;
  newCardData: CardFormType;
  repaySub: (id: string) => () => void;
  paySub: (link: string) => () => void;
};
export const InvoicesPage: React.FC<InvoicesPagePropsType> = ({
  invoices,
  page,
  pageSize,
  setPage,
  count,
  cardsData,
  setDefaultCard,
  deleteCard,
  submitAddCard,
  setNewCardData,
  newCardData,
  repaySub,
  paySub,
}) => {
  const columns: Columns<InvoiceList> = [
    {
      label: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (item: InvoiceList) => (
        <>
          <IdentifyWrapper color={'blueGreen'}>{item?.id}</IdentifyWrapper>
          <CopyWrapper>
            <CopyIcon dataToCopy={item.id} />
          </CopyWrapper>
        </>
      ),
    },
    {
      label: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (item: InvoiceList) => (
        <>
          <Text>{item?.description}</Text>
        </>
      ),
    },
    {
      label: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (item: InvoiceList) => (
        <Text color={item?.status === PaymentStatus.uncollectible ? 'red' : 'blueGreen'}>
          {item?.status?.toUpperCase()}
        </Text>
      ),
    },
    {
      label: 'Date',
      key: 'created',
      dataIndex: 'created',
      render: (item: InvoiceList) => <Text color={'blueGreen'}>{dateToDDMMYYHHMM(item?.created)}</Text>,
    },
    {
      label: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (item: InvoiceList) => (
        <Text color={'blueGreen'}>
          {item?.amount / 100} {item?.currency?.toUpperCase()}
        </Text>
      ),
    },
    {
      label: 'Info',
      key: 'invoice_pdf',
      dataIndex: 'invoice_pdf',
      render: (item: InvoiceList) => (
        <>
          {item?.status !== PaymentStatus.cancelled && (
            <>
              {item?.status === PaymentStatus.uncollectible ? (
                <RepayButton
                  $isDisable={!cardsData?.cards?.length}
                  onClick={repaySub(item?.subscription)}
                  disabled={!cardsData?.cards?.length}>
                  <RepaySubtitle $isDisable={!cardsData?.cards?.length}>Repay using your default card</RepaySubtitle>
                </RepayButton>
              ) : item?.status === PaymentStatus.open ? (
                <PayButton
                  $isDisable={!cardsData?.cards?.length}
                  onClick={paySub(item?.url)}
                  disabled={!cardsData?.cards?.length}>
                  <PaySubtitle $isDisable={!cardsData?.cards?.length}>Pay</PaySubtitle>
                </PayButton>
              ) : (
                <LinkButton type={'secondary'} href={item?.invoice_pdf}>
                  <IconSvg type={'downArrowActive'} viewBox={'0 -3 22 22'} />
                </LinkButton>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Layout
      RightSideBar={
        <AsideWrapper>
          <CardForm
            cardsData={cardsData}
            setDefaultCard={setDefaultCard}
            deleteCard={deleteCard}
            submitAddCard={submitAddCard}
            setNewCardData={setNewCardData}
            newCardData={newCardData}
          />
        </AsideWrapper>
      }>
      <Wrapper>
        <TitleWrapper>
          <TitleText>Invoices</TitleText>
        </TitleWrapper>
        <TableContainer>
          <List
            data={invoices}
            columns={columns}
            paginator={{current: page, pageSize: pageSize, total: count, handlePage: setPage}}
          />
        </TableContainer>
      </Wrapper>
    </Layout>
  );
};
