import React from 'react';
import {useBurnTokenNFT} from '../../../hooks/tokens';
import {ButtonSend} from './styles';

export const NFTBurnButton: React.FC<{tokenAddress: string; tokenID: string; name: string}> = ({
  tokenAddress,
  tokenID,
  name,
}) => {
  const {onBurn, loading} = useBurnTokenNFT(tokenAddress);

  const handleClick = () => {
    onBurn(tokenID, name);
  };

  return (
    <ButtonSend disabled={loading} onClick={handleClick}>
      Destroy
    </ButtonSend>
  );
};
