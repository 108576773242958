import React from 'react';
import {useSendTokenNFT} from '../../../hooks/tokens';
import {NFTTokenSend} from '../../../components/Assets/NFTTokenSend/Form';
import {useGetContacts} from '../../../hooks/contacts';

export const NFTTokenSendContainer: React.FC<{tokenAddress: string; tokenID: string; modalClose?: () => void}> = ({
  tokenAddress,
  tokenID,
  modalClose,
}) => {
  const {onSend, error, loading} = useSendTokenNFT();
  const {contacts} = useGetContacts();

  const handleSend = async (to: string, tokenAddress: string, tokenID: string) => {
    const result = await onSend(to, tokenAddress, tokenID);
    if (result) modalClose && modalClose();
  };

  return (
    <NFTTokenSend
      contacts={contacts}
      tokenAddress={tokenAddress}
      onSend={handleSend}
      loading={loading}
      error={error}
      tokenID={tokenID}
    />
  );
};
