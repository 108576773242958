import React, {useState} from 'react';
import {
  BackButtonText,
  BackButtonWrapper,
  BtnManageProperties,
  ButtonSecondarySelect,
  DropDownWrapper,
  Header,
  InputsWrapper,
  InputWrapper,
  InputWrapperCollection,
  PropertiesText,
  PropertiesWrapper,
  PropertyInputsWrapper,
  PropertyWrapper,
  RequiredField,
  Sub,
  TokenMintForm,
} from './styles';
import {ErrorMessage} from '../../../ui-kit/Form/ErrorMessage/ErrorMessage';
import {Input} from '../../../ui-kit/Form/Input';
import {IFieldsMineToken, IFieldsMintData} from '../../../types/tokens';
import {validationMint} from '../../../helpers/validations';
import {ContactType} from '../../../types/contacts';
import {InputAutocomplete} from '../../../ui-kit/Form/InputAutocomplete';
import {Layout} from '../../../ui-kit/Layout';
import {Title, Text} from '../../../ui-kit/Typography/Typography';
import {IconSvg} from '../../../ui-kit/Icons/Svg';
import {DropDownSelect} from '../../../ui-kit/Form/Select/DropDownSelect';
import {Options} from '../../../ui-kit/Form/Select/types';
import {DatePicker} from '../../../ui-kit/DatePicker';
import {Switch} from '../../../ui-kit/Switch';

interface ITokenCreateFT {
  onMint: (tokenAddress: string, mintData: IFieldsMintData) => Promise<boolean>;
  status: boolean;
  error: string;
  viewer: string;
  contacts: ContactType[];
  collection: Options[];
  selectedCollection: string;
  changeCollection: (address: string) => void;
  forwardBack: () => void;
}

const options = [{label: 'Text'}, {label: 'Date'}, {label: 'Boolean'}] as Options[];

export const Mint: React.FC<ITokenCreateFT> = ({
  onMint,
  status,
  error,
  viewer,
  contacts,
  collection,
  selectedCollection,
  changeCollection,
  forwardBack,
}) => {
  const [fields, setFields] = useState<IFieldsMineToken>({
    to: {value: viewer || '', error: ''},
    tokenID: {value: '', error: ''},
    external_url: {value: '', error: ''},
    image_url: {value: '', error: ''},
    name: {value: '', error: ''},
  });
  const [newItemType, setNewItemType] = useState<string>('Text');
  const [properties, setProperties] = useState<
    {type: string; label: string; value: string | Date | boolean; error: string}[]
  >([]);

  const handleSetNewItemType = (label: string) => {
    setNewItemType(label);
  };

  const handleAddProperties = () => {
    const canAdd =
      !properties.length ||
      properties.every((item) => (item.label && item.value) || (item.label && item.type === 'Boolean'));
    if (canAdd && newItemType) {
      setProperties([
        ...properties,
        {
          type: newItemType,
          label: '',
          value: newItemType === 'Text' ? '' : newItemType === 'Date' ? new Date() : false,
          error: '',
        },
      ]);
    } else {
      const result = properties.map((item) => {
        if (!item.label || (!item.value && item.type !== 'Boolean')) {
          return {type: item.type, label: item.label, value: item.value, error: 'Both fields is required'};
        } else {
          return item;
        }
      });
      setProperties(result);
    }
  };

  const handleDeleteProperties = (index: number) => {
    const newArray = [...properties];
    newArray.splice(index, 1);
    setProperties(newArray);
  };

  const handlePropertiesChangeLabels = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setProperties([
      ...properties.map((item, i) => (i === index ? {...item, label: event.target.value, error: ''} : item)),
    ]);
  };

  const handlePropertiesChangeValuesAsText = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setProperties([
      ...properties.map((item, i) => (i === index ? {...item, value: event.target.value, error: ''} : item)),
    ]);
  };

  const handlePropertiesChangeValuesAsDate = (date: Date | null, index: number) => {
    setProperties([...properties.map((item, i) => (i === index ? {...item, value: date || '', error: ''} : item))]);
  };

  const handlePropertiesChangeValuesAsBoolean = (index: number) => {
    setProperties([
      ...properties.map((item, i) => (i === index ? {...item, value: Boolean(!item.value), error: ''} : item)),
    ]);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'tokenID' && !isNaN(Number(value)) && !value.includes(' ')) {
      setFields({...fields, [name]: {value: value, error: ''}});
    } else if (name !== 'tokenID') setFields({...fields, [name]: {value: value, error: ''}});
  };

  const handlerSubmit = async () => {
    const validate = validationMint(fields);
    if (validate) {
      return setFields({
        ...fields,
        [validate.field]: {value: fields[validate.field]?.value, error: validate.error},
      });
    }
    if (fields.to?.value && fields.tokenID?.value) {
      const result = await onMint(selectedCollection, {
        to: fields.to?.value,
        tokenID: fields?.tokenID.value,
        external_url: fields?.external_url?.value || '',
        image_url: fields?.image_url?.value || '',
        name: fields.name?.value || '',
        attributes: properties || [],
      });
      if (result) {
        setFields({
          tokenID: {value: '', error: ''},
          external_url: {value: '', error: ''},
          image_url: {value: '', error: ''},
          name: {value: '', error: ''},
          to: {value: viewer, error: ''},
        });
        setProperties([]);
      }
    }
  };

  const handleSetAddress = (address: string) => {
    setFields({
      ...fields,
      to: {value: address, error: ''},
    });
  };

  return (
    <Layout>
      <TokenMintForm>
        <InputsWrapper>
          <Header>
            <Title strong={true} level={4}>
              Create New Item
            </Title>
            <BackButtonWrapper onClick={forwardBack}>
              <IconSvg type="arrowLeft" width="9" height="16" viewBox="0 0 9 16" />
              <BackButtonText>Back</BackButtonText>
            </BackButtonWrapper>
          </Header>
          <RequiredField>Required Fields</RequiredField>
          <InputWrapper>
            <Text color={'whiteGray'}>
              TokenID<Text color={'blueGreen'}>*</Text>
            </Text>
            <Sub>ID of token within collection</Sub>
            <Input
              placeholdertype={'ghost'}
              name="tokenID"
              status={fields.tokenID?.error ? 'error' : undefined}
              errorMessage={fields.tokenID?.error && fields.tokenID?.error}
              onChange={handleChange}
              value={fields.tokenID?.value}
              placeholder={'1234'}
            />
          </InputWrapper>
          <InputWrapper>
            <Text color={'whiteGray'}>
              Name<Text color={'blueGreen'}>*</Text>
            </Text>
            <Sub>Name of the item.</Sub>
            <Input
              placeholdertype={'ghost'}
              name="name"
              status={fields?.name?.error ? 'error' : undefined}
              errorMessage={fields.name?.error && fields.name?.error}
              onChange={handleChange}
              value={fields.name?.value}
              placeholder={'Item name'}
            />
          </InputWrapper>
          <InputWrapper>
            <Text color={'whiteGray'}>Image Url</Text>
            <Sub> URL to the image of the item.</Sub>
            <Input
              placeholdertype={'ghost'}
              name="image_url"
              status={fields.image_url?.error ? 'error' : undefined}
              errorMessage={fields.image_url?.error && fields.image_url?.error}
              onChange={handleChange}
              value={fields.image_url?.value}
              placeholder={'https/...'}
            />
          </InputWrapper>
          <InputWrapper>
            <Text color={'whiteGray'}>External Url</Text>
            <Sub>URL that will appear below the assets image and will allow users to view the item on your site.</Sub>
            <Input
              placeholdertype={'ghost'}
              name="external_url"
              status={fields.external_url?.error ? 'error' : undefined}
              errorMessage={fields.external_url?.error && fields.external_url?.error}
              onChange={handleChange}
              value={fields.external_url?.value}
              placeholder={'https/...'}
            />
          </InputWrapper>
          <InputWrapper>
            <Text color={'whiteGray'}>Recipient</Text>
            <Sub>Token recipient address.</Sub>
            <InputAutocomplete
              placeholdertype={'ghost'}
              status={fields.to?.error ? 'error' : undefined}
              errorMessage={fields.to?.error && fields.to?.error}
              data={contacts}
              autocompleteField={'address'}
              promptField={'address'}
              searchField={'name'}
              setValue={handleSetAddress}
              name={'to'}
              onChange={handleChange}
              value={fields.to?.value}
              placeholder={'Address'}
            />
          </InputWrapper>
          <InputWrapperCollection>
            <Text color={'whiteGray'}>Collection</Text>
            <Sub>Select one of yours NFT collection</Sub>
            <DropDownSelect
              name={'Select collection'}
              onChange={changeCollection}
              selectOption={selectedCollection}
              options={collection}
            />
          </InputWrapperCollection>
          <PropertiesWrapper>
            <IconSvg width={'45'} height={'18'} type={'sandwich'} />
            <PropertiesText>
              <Text color={'whiteGray'}>Properties</Text>
              <Sub>Add attributes to the item.</Sub>
            </PropertiesText>
            <DropDownWrapper>
              <DropDownSelect
                name={'Type'}
                onChange={handleSetNewItemType}
                selectOption={newItemType}
                options={options}
              />
            </DropDownWrapper>
            <BtnManageProperties onClick={handleAddProperties}>
              <IconSvg width={'20'} height={'48'} viewBox={'-2 3 24 24'} type={'bigPlus'} />
            </BtnManageProperties>
          </PropertiesWrapper>
          {properties.map((item, index) => {
            return (
              <PropertyWrapper key={index}>
                <PropertyInputsWrapper>
                  <Input
                    sizetype={'small'}
                    status={properties[index]?.error ? 'error' : undefined}
                    placeholdertype={'ghost'}
                    onChange={(event) => handlePropertiesChangeLabels(event, index)}
                    value={properties[index]?.label || ''}
                    placeholder={'Label'}
                  />
                  {item.type === 'Text' && (
                    <Input
                      sizetype={'small'}
                      status={properties[index]?.error ? 'error' : undefined}
                      placeholdertype={'ghost'}
                      onChange={(event) => handlePropertiesChangeValuesAsText(event, index)}
                      value={String(properties[index]?.value || '')}
                      placeholder={'Value'}
                    />
                  )}
                  {item.type === 'Date' && (
                    <DatePicker
                      date={new Date(String(properties[index]?.value))}
                      onChange={(date) => handlePropertiesChangeValuesAsDate(date, index)}
                    />
                  )}
                  {item.type === 'Boolean' && (
                    <Switch
                      checked={Boolean(properties[index]?.value)}
                      onClick={() => handlePropertiesChangeValuesAsBoolean(index)}
                    />
                  )}
                  <BtnManageProperties onClick={() => handleDeleteProperties(index)}>
                    <IconSvg width={'20'} height={'48'} viewBox={'-2 0 24 24'} type={'bigMinus'} />
                  </BtnManageProperties>
                </PropertyInputsWrapper>
                {properties[index]?.error && <ErrorMessage errorMessage={properties[index]?.error || ''} />}
              </PropertyWrapper>
            );
          })}
          <ButtonSecondarySelect onClick={handlerSubmit} disabled={status}>
            Mint Token
          </ButtonSecondarySelect>
          {error && <ErrorMessage errorMessage={error} />}
        </InputsWrapper>
      </TokenMintForm>
    </Layout>
  );
};
