import styled from 'styled-components';
import {LinkButton} from '../Buttons/LinkButton';
import {theme} from '../theme';
import {Text} from '../Typography/Typography';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ContentWrapper = styled.div<{$isActive: boolean}>`
  display: ${({$isActive}) => ($isActive ? 'block' : 'none')};
  width: 100%;
`;

export const TabButton = styled(LinkButton)<{$isActive: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 190px;
  border-radius: 10px;
  background-color: ${({$isActive}) => ($isActive ? theme.pallet.common.grey4 : theme.pallet.common.grey5)};
  cursor: pointer;

  span {
    color: ${({$isActive}) => ($isActive ? theme.pallet.common.white : theme.pallet.common.gray)};
  }
`;

export const TabTitle = styled(Text).attrs({
  strong: true,
})``;

export const TabWrapper = styled.div``;
