import React from 'react';
import {ContactType} from '../../../types/contacts';
import {Table} from '../../../ui-kit/Table';
import {HiddenContentType, Columns} from '../../../ui-kit/Table/types';

interface Props {
  data: ContactType[];
  columns: Columns<ContactType>;
  hiddenContent?: HiddenContentType[];
  paginator: {
    current: number;
    pageSize: number;
    total: number;
    handlePage: React.Dispatch<React.SetStateAction<number>>;
  };
}

const List: React.FC<Props> = ({data, columns, paginator}) => {
  return (
    <>
      <Table<ContactType>
        type={'custom'}
        dataSource={data}
        columns={columns}
        pagination={paginator}
        emptyMessage={'There ara no contacts'}
      />
    </>
  );
};

export default List;
