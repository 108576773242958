import React from 'react';
import {AccountInfoBody, AccountInfoStatus, CopyAddress, LinkWrapper, TransactionsWrapper} from './styles';
import {Subtitle, Text} from '../../ui-kit/Typography/Typography';
import {CopyIcon} from '../../ui-kit/Icons/CopyIcon';
import {Button} from '../../ui-kit/Buttons/Button';
import {Transactions} from './Transactions';

interface IProps {
  address: string;
  signOut: () => void;
  clearTransactions: () => void;
  transactions: any[];
}

export const Menu: React.FC<IProps> = ({address, signOut, transactions, clearTransactions}) => {
  const handleSighOut = () => {
    signOut();
  };

  return (
    <>
      <AccountInfoBody>
        <AccountInfoStatus>
          <Subtitle level={2} font={'secondary'}>
            Connected
          </Subtitle>
          <LinkWrapper>
            <Button onClick={handleSighOut} type={'secondary'}>
              <Subtitle color={'blueGreen'} level={2} font={'secondary'}>
                Disconnect
              </Subtitle>
            </Button>
          </LinkWrapper>
        </AccountInfoStatus>
        <Text ellipsis={true} color={'blueLight4'}>
          {address}
        </Text>
        <CopyAddress>
          <CopyIcon color={'secondary'} dataToCopy={address}>
            <Subtitle font={'secondary'}>Copy address</Subtitle>
          </CopyIcon>
        </CopyAddress>
      </AccountInfoBody>
      <TransactionsWrapper>
        <Transactions data={transactions} onClear={clearTransactions} />
      </TransactionsWrapper>
    </>
  );
};
