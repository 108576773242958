import React from 'react';
import {useSendTokenFT} from '../../../hooks/tokens';
import {FTTokenSend} from '../../../components/Assets/FTTokenSend/Form';
import {useGetContacts} from '../../../hooks/contacts';

export const FTTokenSendContainer: React.FC<{tokenAddress: string; closeSendWindow?: () => void}> = ({
  tokenAddress,
  closeSendWindow,
}) => {
  const {onSend, error, loading} = useSendTokenFT();
  const {contacts} = useGetContacts();

  const handleSend = async (to: string, tokenAddress: string, amount: string) => {
    await onSend(to, tokenAddress, amount);
    closeSendWindow && closeSendWindow();
  };

  return (
    <FTTokenSend tokenAddress={tokenAddress} contacts={contacts} onSend={handleSend} loading={loading} error={error} />
  );
};
