declare global {
  interface Window {
    _env_: {
      REACT_APP_APPLICATION_ID: string | undefined;
      REACT_APP_GRAPHQL_URI: string | undefined;
      REACT_APP_API_KEY: string | undefined;
      REACT_APP_RPC_URL: string | undefined;
      REACT_APP_EXPLORER_URL: string | undefined;
      REACT_APP_EXPLORER_API: string | undefined;
      REACT_APP_TRANSACTION_CHAIN_ID: string | undefined;
      REACT_APP_RECIPIENT_ADDRESS: string | undefined;
      REACT_APP_USDT_ADDRESS: string | undefined;
      REACT_APP_VALIDATOR_COST: string | undefined;
      REACT_APP_WATERFALL_CHAIN_ID: string | undefined;
      REACT_APP_NET_NAME: string | undefined;
      REACT_APP_VALIDATOR_CONFIRMATION: string | undefined;
      REACT_APP_ETH_RPC: string | undefined;
      REACT_APP_WATERFALL_SERVER: string | undefined;
    };
  }
}
export const APPLICATION_ID =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_APPLICATION_ID
    : process.env.REACT_APP_APPLICATION_ID) || 'wallet';
export const ETH_RPC =
  (typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_ETH_RPC : process.env.REACT_APP_ETH_RPC) ||
  'https://goerli.infura.io/v3/a70f5c41bfe64047877cc0de36030b5c';
export const NET_NAME =
  (typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_NET_NAME : process.env.REACT_APP_NET_NAME) ||
  'Waterfall';
export const GRAPHQL_URI =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_GRAPHQL_URI
    : process.env.REACT_APP_GRAPHQL_URI) || 'https://server-wallet-wf.dev.moonion.com/graphql';
export const API_KEY =
  (typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_API_KEY : process.env.REACT_APP_API_KEY) ||
  'Ad89F34694E3785f852555aDf4914A27';
export const CONFIRMATION_COUNT =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_VALIDATOR_CONFIRMATION
    : process.env.REACT_APP_VALIDATOR_CONFIRMATION) || '35';
export const RPC_URL =
  (typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_RPC_URL : process.env.REACT_APP_RPC_URL) ||
  'https://rpc3.waterfall.network/rpc';
export const EXPLORER_URL =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_EXPLORER_URL
    : process.env.REACT_APP_EXPLORER_URL) || 'https://explorer3.waterfall.network/';
export const EXPLORER_API =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_EXPLORER_API
    : process.env.REACT_APP_EXPLORER_API) || 'https://explorer-wf.dev.moonion.com/api';
export const TRANSACTION_CHAIN_ID =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_TRANSACTION_CHAIN_ID
    : process.env.REACT_APP_TRANSACTION_CHAIN_ID) || '0x5';
export const RECIPIENT_ADDRESS =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_RECIPIENT_ADDRESS
    : process.env.REACT_APP_RECIPIENT_ADDRESS) || '0x51edfab464c00dd78dB84598Cc7eA0D30E9eF674';
export const USDT_ADDRESS =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_USDT_ADDRESS
    : process.env.REACT_APP_USDT_ADDRESS) || '0x865efe3C0CE725f5F05d2a6b4aF7224231Ec32E8';
export const VALIDATOR_COST =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_VALIDATOR_COST
    : process.env.REACT_APP_VALIDATOR_COST) || '0.0001';
export const WATERFALL_CHAIN_ID =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_WATERFALL_CHAIN_ID
    : process.env.REACT_APP_WATERFALL_CHAIN_ID) || '0x13e50a24';
export const WATERFALL_SERVER =
  (typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_WATERFALL_SERVER
    : process.env.REACT_APP_WATERFALL_SERVER) || 'https://server-wallet.testnet4.waterfall.network/\n';
console.log('APPLICATION_ID', APPLICATION_ID);
export const dataMock = [
  {
    address: '0x230a1ac45690b9ae11.....',
    someNumOne: 0.00007,
    someNumTwo: 0.00007,
    ff: '29.04.22',
    gg: '29.04.22',
    status: 'Redeem',
    server: 'pending...',
    details: ['Date -   12/12/22', 'Status -  server activation'],
  },
  {
    address: '0x230a1ac45690b9ae11.....',
    someNumOne: 0.00007,
    someNumTwo: 0.00007,
    ff: '29.04.22',
    gg: '29.04.22',
    status: 'Redeem',
    server: 'pending...',
    details: ['Date -   12/12/22', 'Status -  server activation'],
  },
  {
    address: '0x230a1ac45690b9ae11.....',
    someNumOne: 0.00007,
    someNumTwo: 0.00007,
    ff: '29.04.22',
    gg: '29.04.22',
    status: 'Redeem',
    server: 'pending...',
    details: ['Date -   12/12/22', 'Status -  server activation'],
  },
  {
    address: '0x230a1ac45690b9ae11.....',
    someNumOne: 0.00007,
    someNumTwo: 0.00007,
    ff: '29.04.22',
    gg: '29.04.22',
    status: 'Redeem',
    server: 'pending...',
    details: ['Date -   12/12/22', 'Status -  server activation'],
  },
  {
    address: '0x230a1ac45690b9ae11.....',
    someNumOne: 0.00007,
    someNumTwo: 0.00007,
    ff: '29.04.22',
    gg: '29.04.22',
    status: 'Redeem',
    server: 'pending...',
    details: ['Date -   12/12/22', 'Status -  server activation'],
  },
];
