import React from 'react';
import {StepOneWrapper, FormLine, FormTitle, SelectDataCentreWrapper} from './styles';
import {Select} from '../../ui-kit/Form/Select/PrimarySelect';
import {DataCenterAssets} from './index';

interface Props {
  chooseDataCenter: (datacenter: string) => void;
  dataCenter: string;
  backStep: () => void;
}

export const DataCenterStep: React.FC<Props> = ({chooseDataCenter, dataCenter}) => {
  return (
    <StepOneWrapper>
      <FormTitle>Select please...</FormTitle>
      <FormLine />
      <SelectDataCentreWrapper>
        <Select onChange={chooseDataCenter} name={'dataCenter'} selectOption={dataCenter} options={DataCenterAssets} />
      </SelectDataCentreWrapper>
    </StepOneWrapper>
  );
};
