import React, {useRef, useState} from 'react';
import {
  ButtonSecondarySelect,
  CardImg,
  CardImgWrapper,
  InputWrapper,
  StyledInputImage,
  TokenCreateForm,
} from './styles';
import {ErrorMessage} from '../../../ui-kit/Form/ErrorMessage/ErrorMessage';
import {Input} from '../../../ui-kit/Form/Input';
import {web3} from '../../../hooks/metamask';
import Image from '../../TestPage/assets/image.svg';

interface ITokenAdd {
  onAdd: (tokenID: string, file?: File) => Promise<boolean>;
  transactionStatus: boolean;
  error: string;
}

export const AddForm: React.FC<ITokenAdd> = ({onAdd, transactionStatus, error}) => {
  const [fields, setFields] = useState<{
    address: {value: string; error: string};
    img: {value: string; error: string; file?: File};
  }>({address: {value: '', error: ''}, img: {value: '', error: '', file: undefined}});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'address' && !event.target.value.includes(' ')) {
      setFields({...fields, address: {value: event.target.value, error: ''}});
    }
    if (event.target.name === 'img') {
      const file = event.target?.files?.[0];
      if (file) setFields({...fields, img: {value: file?.name, error: '', file: file}});
    }
  };

  const handlerSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!fields.address.value.length)
      return setFields({...fields, address: {value: fields.address.value, error: 'Address is required'}});
    if (!web3.utils.isAddress(fields.address.value))
      setFields({...fields, address: {value: fields.address.value, error: 'Address is incorrect'}});
    const result = await onAdd(fields.address.value, fields.img.file);
    if (result)
      setFields({
        address: {value: '', error: ''},
        img: {value: '', error: '', file: undefined},
      });
  };

  const ref = useRef<HTMLInputElement>(null);

  const handlerSetImage = () => {
    ref.current?.click();
  };

  return (
    <TokenCreateForm onSubmit={handlerSubmit}>
      <InputWrapper>
        <Input
          name={'address'}
          status={fields.address.error ? 'error' : undefined}
          errorMessage={fields.address.error && fields.address.error}
          onChange={handleChange}
          value={fields.address.value}
          placeholder={'Address'}
        />
        <CardImgWrapper onClick={handlerSetImage}>
          <Input
            value={fields.img?.value}
            status={fields.img?.error ? 'error' : undefined}
            errorMessage={fields.img?.error && fields.img?.error}
            disabled={true}
            placeholder={'Image'}
            addonAfter={<CardImg src={Image} />}
          />
        </CardImgWrapper>
        <StyledInputImage name="img" onChange={handleChange} ref={ref} />
        <ButtonSecondarySelect disabled={transactionStatus}>Import Token</ButtonSecondarySelect>
        {error && <ErrorMessage errorMessage={error} />}
      </InputWrapper>
    </TokenCreateForm>
  );
};
