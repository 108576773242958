import React, {ReactNode, useEffect} from 'react';
import {
  ModalDialog,
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalBody,
  ModalContent,
  ModalFooter,
} from './styles';

export type ModalProps = {
  visible: boolean;
  title?: string | ReactNode;
  children: string | ReactNode;
  footer?: string | ReactNode;
  onClose: () => void;
  maxWidth?: number;
};

export const ModalWindow: React.FC<ModalProps> = ({visible, title, children, footer, onClose, maxWidth}) => {
  const onKeydown = ({key}: any) => {
    if (key === 'Escape') {
      return onClose();
    }
  };

  const stopPropagation = (e: any) => e.stopPropagation();

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  if (!visible) return null;

  return (
    <ModalWrapper className={'modal-wrapper'} onClick={onClose}>
      <ModalDialog className={'modal-dialog'} maxWidth={maxWidth} onClick={stopPropagation}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <ModalClose onClick={onClose}>&times;</ModalClose>
        </ModalHeader>
        <ModalBody>
          <ModalContent>{children}</ModalContent>
        </ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalDialog>
    </ModalWrapper>
  );
};
