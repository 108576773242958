import styled from 'styled-components';
import {Button} from '../../../ui-kit/Buttons/Button';
import {theme} from '../../../ui-kit/theme';
import {Subtitle, Text} from '../../../ui-kit/Typography/Typography';
export const InputsWrapper = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  margin: 40px 0;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const InputWrapperCollection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  div > button > div {
    width: calc(100% - 28px);
  }
`;

export const PropertiesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;

  svg {
    position: relative;
    margin-top: 6px;
  }
`;

export const PropertiesText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TokenMintForm = styled.div`
  margin-top: 64px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  width: 700px;
  border-radius: 10px;
  background-color: ${theme.pallet.background.tertiary};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 60px;
`;

export const BackButtonText = styled(Text).attrs({
  strong: true,
  color: 'gray',
})``;

export const ButtonSecondarySelect = styled(Button).attrs({
  variant: 'primary',
})`
  margin-top: 5px;
  height: 60px;
`;

export const RequiredField = styled(Subtitle).attrs({
  level: 1,
})`
  margin-top: 5px;
  &:before {
    content: '*';
    color: ${theme.pallet.common.blueGreen};
  }
`;

export const Sub = styled(Subtitle).attrs({
  color: 'grey7',
})`
  font-size: 12px;
`;

export const DropDownWrapper = styled.div`
  min-width: 155px;
  div {
    height: 48px;
    margin-top: 0;
    button {
      div {
        gap: 5px;
        align-items: baseline;
        justify-content: center;
      }
    }
    div {
      top: 55px;
      width: 100%;
      height: fit-content;
      box-sizing: border-box;
      padding: 5px 5px;
      padding-right: 0px;
    }
    & div:nth-child(2) {
      padding: 10px 18px;
      padding-right: 10px;
    }
  }
`;

export const BtnManageProperties = styled.div`
  height: 48px;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.pallet.background.primary};
  border-radius: 10px;
  cursor: pointer;
  svg {
    stroke: ${theme.pallet.common.grey7};
  }
  &:hover {
    svg {
      stroke: ${theme.pallet.common.blueLight2};
    }
  }
`;

export const PropertyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PropertyInputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;
