import styled from 'styled-components';
import {Text} from '../../../../ui-kit/Typography/Typography';

export const HistoryWrapper = styled.div`
  width: 100%;
  min-height: 520px;
  display: flex;
  flex-direction: column;
`;

export const Name = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Address = styled(Text).attrs({
  level: 1,
  ellipsis: true,
})``;

export const OperationType = styled.div`
  position: relative;
  bottom: -3px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;

  &:hover {
    & > span {
      visibility: visible;
    }
  }
`;

export const Tooltip = styled(Text).attrs({
  level: 1,
})`
  visibility: hidden;
  background: rgba(19, 19, 23, 0.88);
  color: #fff;
  text-align: center;
  padding: 7px 22px 9px 18px;
  border-radius: 10px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 26px;
  z-index: 1;
`;

export const DateText = styled(Text).attrs({
  level: 1,
})`
  text-align: center;
  @media (max-width: 1366px) {
    max-width: 100px;
    white-space: pre-wrap;
  }
`;

export const CopyIconWrapper = styled.div`
  padding-left: 10px;
`;
