import styled from 'styled-components';
import {theme} from '../../ui-kit/theme';
import {Subtitle, Text} from '../../ui-kit/Typography/Typography';

export const ContentMenuWrapper = styled.div`
  width: 320px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: ${theme.pallet.background.tertiary};
  padding: 20px 20px;
  border-radius: 10px;
`;

export const AccountInfoBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.pallet.background.primary};
  min-height: 145px;
  padding: 14px 15px 15px 20px;
  border-radius: 10px;
`;

export const AccountInfoStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
`;

export const CopyAddress = styled.div`
  margin-top: 15px;
`;

export const LinkWrapper = styled.div`
  max-width: 90px;
`;

export const TransactionsWrapper = styled.div`
  margin-top: 15px;
  padding: 0 4px;
`;

export const TransactionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ClearAll = styled(Subtitle).attrs({
  nowrap: true,
  color: 'grey7',
  underline: true,
})`
  cursor: pointer;
`;

export const ButtonContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 18px;
  height: 50px;
  justify-content: space-between;
  width: calc(100% - 28px);

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading {
    fill: ${theme.pallet.common.white};
    animation: spin 1.5s normal linear infinite;
  }
`;
export const ModalWrapper = styled.div`
  .modal-wrapper {
    position: absolute;
  }
  .modal-dialog {
    top: 150px;
    right: 140px;

    @media (max-width: 1550px) {
      right: 40px;
    }
    @media (max-width: 1300px) {
      right: 15px;
    }
  }
`;

export const Address = styled(Text).attrs({
  level: 1,
})`
  width: 136px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
