import React, {Dispatch, SetStateAction} from 'react';
import {CollectionWrapper, EmptyState, EmptyText, PaginatorWrapper} from './styles';
import CollectionItem from './CollectionItem';
import {Paginator} from '../../../ui-kit/Paginator';
import {CollectionItemType} from '../../../types/transaction';

export interface IProps {
  data: CollectionItemType[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalCount: number;
  tokenAddress: string;
}

const CollectionList: React.FC<IProps> = ({data, totalCount, setPage, page, tokenAddress}) => {
  return (
    <>
      {data.length ? (
        <CollectionWrapper>
          {data.map((item, index) => {
            return <CollectionItem tokenAddress={tokenAddress} data={item} key={index} />;
          })}
        </CollectionWrapper>
      ) : (
        <EmptyState>
          <EmptyText>There are no pictures yet</EmptyText>
        </EmptyState>
      )}
      <PaginatorWrapper>
        <Paginator current={page} total={totalCount} onPage={setPage} />
      </PaginatorWrapper>
    </>
  );
};

export default CollectionList;
