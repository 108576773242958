import {gql} from '@apollo/client';

export const Validator = gql`
  fragment ValidatorFragment on Validator {
    id
    type
    validatorPublicKey
    coordinatorPublicKey
    validatorBalance
    coordinatorBalance
    activationDate
    status
    Instance {
      id
      datacenter
      zone
      region
      status
    }
  }
`;

export const Instances = gql`
  fragment InstanceFragment on Instance {
    id
    datacenter
    region
    status
    zone
    isSyncing
    objectId
    Validators {
      edges {
        node {
          id
          type
          validatorPublicKey
          coordinatorPublicKey
          validatorBalance
          coordinatorBalance
          activationDate
          status
        }
      }
    }
  }
`;
