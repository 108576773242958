import React, {useState} from 'react';
import {InvoicesPage} from '../../components/Invoices';
import {useGetInvoices, useUserCards} from '../../hooks/user';
import {CardFormType} from '../../types/card';
import {validatesName, validateZipPostalCode, validationCardNumber, validationExpDate} from '../../helpers/validations';
import {useRepaySubscription} from '../../hooks/validator';

export const InvoicesContainer: React.FC = () => {
  const {repayUserSubscription} = useRepaySubscription();
  const {setPage, page, pageSize, count, invoices, refetch} = useGetInvoices();
  const {userCardSetDefault, userCardDelete, cardsData, userCardAdd} = useUserCards();
  const [newCardData, setNewCardData] = useState<CardFormType>({
    id: '',
    cardName: {value: '', error: ''},
    cardNumber: {value: '', error: ''},
    cardBrand: '',
    cvcCode: {value: '', error: ''},
    date: {value: '', error: ''},
  });

  const handleRepay = (id: string) => async () => {
    await repayUserSubscription(id);
    await refetch();
  };

  const handlePay = (link: string) => () => open(link);

  const handleSubmitAddCard = async () => {
    if (validatesName(newCardData?.cardName?.value)) {
      setNewCardData((prevState) => {
        return {
          ...prevState,
          cardName: {value: prevState?.cardName?.value, error: validatesName(newCardData?.cardName?.value)},
        };
      });
      return false;
    }
    if (validateZipPostalCode(newCardData?.cvcCode?.value)) {
      setNewCardData((prevState) => {
        return {
          ...prevState,
          cvcCode: {value: prevState?.cvcCode?.value, error: validatesName(newCardData?.cvcCode?.value)},
        };
      });
      return false;
    }
    if (validationExpDate(newCardData?.date?.value)) {
      setNewCardData((prevState) => {
        return {
          ...prevState,
          date: {value: prevState?.date?.value, error: validationExpDate(newCardData?.date?.value)},
        };
      });
      return false;
    }
    if (validationCardNumber(newCardData?.cardNumber?.value, newCardData?.cardBrand)) {
      setNewCardData((prevState) => {
        return {
          ...prevState,
          cardNumber: {
            value: prevState?.cardNumber?.value,
            error: validationCardNumber(newCardData?.cardNumber?.value, newCardData?.cardBrand),
          },
        };
      });
      return false;
    }
    await userCardAdd({
      cardNumber: newCardData?.cardNumber?.value,
      cardType: newCardData?.cardBrand,
      nameOnCard: newCardData?.cardName?.value,
      cvc: newCardData?.cvcCode?.value,
      expiryDate: newCardData?.date?.value,
    });
    return true;
  };

  return (
    <InvoicesPage
      invoices={invoices}
      setPage={setPage}
      page={page}
      count={count}
      pageSize={pageSize}
      cardsData={cardsData}
      deleteCard={userCardDelete}
      setDefaultCard={userCardSetDefault}
      submitAddCard={handleSubmitAddCard}
      newCardData={newCardData}
      setNewCardData={setNewCardData}
      repaySub={handleRepay}
      paySub={handlePay}
    />
  );
};
