import {useRecoilValue} from 'recoil';
import {user as userState} from '../states/session';
import {useIsAuthenticated} from './auth';
import {useEffect, useState} from 'react';
import {useMetaMask} from 'metamask-react';
import {getBalance} from './metamask';
import {useLocation} from 'react-router';
import {useQuery as useQ} from 'react-query';
import {useMutation, useQuery} from '@apollo/client';
import {
  GetUserCardRequest,
  GetUserCardResponse,
  getUserInvoices,
  getUserCards,
  getUserInvoicesResponse,
  setDefaultUserCard,
  SetDefaultUserCardRequest,
  DeleteUserCardRequest,
  deleteUserCard,
  addUserCard,
  AddUserCardRequest,
} from '../queries/card';
import {PAGE_SIZE} from '../constants/data';
import {InvoiceList} from '../types/user';
import {UserCardType} from '../types/validator';
import {GQLInvoiceOrder} from '../graphql.schema';

export const useViewer = () => {
  return useRecoilValue(userState) || null;
};

export const useViewerId = (field?: 'id' | 'objectId'): string | undefined => {
  return useViewer()?.[field || 'id'];
};

export const useUserCards = () => {
  const id = useViewerId();
  const [getUserCardsData] = useMutation<GetUserCardResponse, GetUserCardRequest>(getUserCards);
  const [setDefaultCard] = useMutation<boolean, SetDefaultUserCardRequest>(setDefaultUserCard);
  const [addCard] = useMutation<boolean, AddUserCardRequest>(addUserCard);
  const [deleteCard] = useMutation<boolean, DeleteUserCardRequest>(deleteUserCard);
  const [loading, setLoading] = useState<boolean>(false);
  const [cardsData, setCardsData] = useState<{cards: UserCardType[]; defaultCardId?: string}>({
    defaultCardId: '',
    cards: [],
  });
  useEffect(() => {
    if (!id) return;
    getCards();
  }, [id]);

  const userCardAdd = async (values: AddUserCardRequest) => {
    if (loading) return;
    setLoading(true);
    await addCard({variables: {...values}});
    await getCards();
    setLoading(false);
  };
  const userCardDelete = async (cardId: string) => {
    if (loading) return;
    setLoading(true);
    await deleteCard({variables: {cardId: cardId}});
    await getCards();
    setLoading(false);
  };

  const userCardSetDefault = async (cardId: string) => {
    if (loading) return;
    setLoading(true);
    setCardsData((prevState) => {
      return {
        ...prevState,
        defaultCardId: cardId,
      };
    });
    await setDefaultCard({variables: {cardId: cardId}});
    await getCards();
    setLoading(false);
  };
  const getCards = async () => {
    if (!id) return;
    const response = await getUserCardsData({
      variables: {
        userId: id,
      },
    });
    setCardsData({
      defaultCardId: response?.data?.paymentGetUserCards?.defaultCardId || '',
      cards: response?.data?.paymentGetUserCards?.cards || [],
    });
    return response?.data?.paymentGetUserCards;
  };

  return {
    cardsData,
    getCards,
    userCardAdd,
    userCardDelete,
    userCardSetDefault,
  };
};

export const useGetInvoices = () => {
  const id = useViewerId('objectId');
  const {data, refetch} = useQuery<getUserInvoicesResponse>(getUserInvoices, {
    skip: !id,
    variables: {
      User: {
        have: {
          objectId: {equalTo: id},
        },
      },
      order: GQLInvoiceOrder.createdAt_DESC,
    },
    fetchPolicy: 'no-cache',
  });
  const [invoices, setInvoices] = useState<InvoiceList[]>([]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (data?.invoices?.edges?.length) {
      setInvoices(
        data?.invoices?.edges?.map((item) => {
          const desc = item?.node?.description?.split(' ');
          return {
            amount: item?.node?.total,
            currency: item?.node?.currency,
            status: item?.node?.status,
            id: item?.node?.number,
            subscription: item?.node?.subscription,
            invoice_pdf: item?.node?.invoice_pdf,
            created: new Date(item?.node?.created * 1000),
            description: desc?.length ? `${desc?.[2]} ${desc?.[3]}` : '',
            url: item?.node?.url,
          };
        }),
      );
    }
  }, [data?.invoices?.edges]);

  return {
    refetch: refetch,
    invoices: invoices.slice((page - 1) * PAGE_SIZE, PAGE_SIZE * page),
    page,
    setPage,
    pageSize: PAGE_SIZE,
    count: invoices?.length / PAGE_SIZE,
  };
};
export const useUserBalance = () => {
  const [balance, setBalance] = useState<string>('0');
  const location = useLocation().pathname;
  const {ethereum} = useMetaMask();
  const user = useRecoilValue(userState);
  const isAuth = useIsAuthenticated();
  const {data: response, refetch} = useQ(['balance', {user}], () => getBalance(user?.address as string), {
    enabled: !!user?.address,
    initialData: '0',
  });

  useEffect(() => {
    setBalance(response || '0');
  }, [response]);

  useEffect(() => {
    if (isAuth && ethereum && user?.address) {
      refetch();
    }
  }, [isAuth, ethereum, user]);
  return {
    balance,
    location,
    address: user?.address,
  };
};
