import React from 'react';
import {Table} from '../../../ui-kit/Table';
import {Columns} from '../../../ui-kit/Table/types';
import {InvoiceList} from '../../../types/user';

interface Props {
  data: InvoiceList[];
  columns: Columns<InvoiceList>;
  paginator: {
    current: number;
    pageSize: number;
    total: number;
    handlePage: React.Dispatch<React.SetStateAction<number>>;
  };
}

const List: React.FC<Props> = ({data, columns, paginator}) => {
  return (
    <Table<InvoiceList>
      type={'primary'}
      dataSource={data}
      columns={columns}
      pagination={paginator}
      emptyMessage={'There ara no invoices'}
    />
  );
};

export default List;
