import React, {useRef, useState} from 'react';
import {
  ButtonSecondarySelect,
  CardImg,
  CardImgWrapper,
  DefaultDecimal,
  InputWrapper,
  StyledInputImage,
  TokenCreateForm,
} from './styles';
import {ErrorMessage} from '../../../ui-kit/Form/ErrorMessage/ErrorMessage';
import {Input} from '../../../ui-kit/Form/Input';
import {IFieldsCreateTokenFT, IOptions} from '../../../types/tokens';
import {validationFormFT} from '../../../helpers/validations';
import Image from '../../TestPage/assets/image.svg';

interface ITokenCreateFT {
  onCreate: (fields: IOptions, file?: File) => Promise<boolean>;
  status: boolean;
  error: string;
}

const FTCreateForm: React.FC<ITokenCreateFT> = ({onCreate, status, error}) => {
  const [fields, setFields] = useState<IFieldsCreateTokenFT>({
    name: {value: '', error: ''},
    symbol: {value: '', error: ''},
    decimals: {value: '', error: ''},
    total: {value: '', error: ''},
    img: {value: '', error: '', file: undefined},
  });
  const ref = useRef<HTMLInputElement>(null);

  const handlerSetImage = () => {
    ref.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if ((name === 'decimals' || name === 'total') && !isNaN(Number(value)) && !value.includes(' ')) {
      setFields({...fields, [name]: {value: value, error: ''}});
    } else if (name === 'symbol' && !value.includes(' ')) {
      setFields({...fields, [name]: {value: value.toUpperCase(), error: ''}});
    } else if (name === 'name') {
      setFields({...fields, [name]: {value: value, error: ''}});
    } else if (name === 'img') {
      const file = e.target?.files?.[0];
      setFields({...fields, [name]: {value: file?.name, error: '', file: file}});
    }
  };

  const handlerSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const validate = validationFormFT(fields);
    if (validate) {
      return setFields({
        ...fields,
        [validate.field]: {value: fields[validate.field]?.value, error: validate.error},
      });
    }

    const option = {
      name: fields.name?.value,
      symbol: fields.symbol?.value,
      decimals: fields.decimals?.value || '18',
      totalSupply: fields.total?.value,
    };
    const result = await onCreate(option, fields.img?.file);
    if (result)
      setFields({
        name: {value: '', error: ''},
        symbol: {value: '', error: ''},
        decimals: {value: '', error: ''},
        total: {value: '', error: ''},
        img: {value: '', error: '', file: undefined},
      });
  };

  return (
    <TokenCreateForm onSubmit={handlerSubmit}>
      <InputWrapper>
        <Input
          name="name"
          status={fields.name?.error ? 'error' : undefined}
          errorMessage={fields.name?.error && fields.name?.error}
          onChange={handleChange}
          value={fields.name?.value}
          placeholder={'Name'}
        />
        <Input
          name="symbol"
          status={fields.symbol?.error ? 'error' : undefined}
          errorMessage={fields.symbol?.error && fields.symbol?.error}
          onChange={handleChange}
          value={fields.symbol?.value}
          placeholder={'Symbol'}
        />
        <Input
          name="decimals"
          status={fields.decimals?.error ? 'error' : undefined}
          errorMessage={fields.decimals?.error && fields.decimals?.error}
          maxLength={2}
          onChange={handleChange}
          value={fields.decimals?.value}
          placeholder={'Decimals'}
          addonAfter={!fields.decimals?.value && <DefaultDecimal>18</DefaultDecimal>}
        />
        <Input
          name="total"
          status={fields.total?.error ? 'error' : undefined}
          errorMessage={fields.total?.error && fields.total?.error}
          onChange={handleChange}
          value={fields.total?.value}
          placeholder={'Total Supply'}
        />
        <CardImgWrapper onClick={handlerSetImage}>
          <Input
            value={fields.img?.value}
            status={fields.img?.error ? 'error' : undefined}
            errorMessage={fields.img?.error && fields.img?.error}
            disabled={true}
            placeholder={'Image'}
            addonAfter={<CardImg src={Image} />}
          />
        </CardImgWrapper>
        <StyledInputImage name="img" onChange={handleChange} ref={ref} />
        <ButtonSecondarySelect disabled={status}>Create Token</ButtonSecondarySelect>
        {error && <ErrorMessage errorMessage={error} />}
      </InputWrapper>
    </TokenCreateForm>
  );
};

export default FTCreateForm;
