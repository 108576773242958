import React, {useState} from 'react';
import {FTTokenSendContainer} from '../../../containers/Assets/FTTokenSend';
import {ModalWindow} from '../../../ui-kit/Modal';
import {ButtonSend} from './styles';

export const FTSendButton: React.FC<{tokenAddress: string; name: string}> = ({tokenAddress, name}) => {
  const [isSendWindowOpen, setIsSendWindowOpen] = useState(false);
  const closeSendWindow = () => setIsSendWindowOpen(false);
  const openSendWindow = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsSendWindowOpen(true);
  };

  return (
    <>
      <ModalWindow visible={isSendWindowOpen} title={`Send ${name} Token`} onClose={closeSendWindow}>
        <FTTokenSendContainer tokenAddress={tokenAddress} closeSendWindow={closeSendWindow} />
      </ModalWindow>
      <ButtonSend type={'secondary'} onClick={openSendWindow}>
        Send
      </ButtonSend>
    </>
  );
};
