import styled from 'styled-components';
import {LinkButton} from '../../../ui-kit/Buttons/LinkButton';
import {Text} from '../../../ui-kit/Typography/Typography';

export const BalanceWrapper = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
`;

export const Link = styled(LinkButton)`
  max-width: 125px;
  height: 45px;
`;

export const Name = styled(Text).attrs({
  level: 2,
  strong: true,
})`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NameWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    div {
      color: transparent;
    }
  }
`;

export const Action = styled.div`
  min-width: 125px;
  cursor: pointer;
`;
