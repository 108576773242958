import React, {useMemo} from 'react';
import {InstanceType, ValidatorType} from '../../types/validator';
import {Columns} from '../../ui-kit/Table/types';
import {Text} from '../../ui-kit/Typography/Typography';
import {ButtonWrapper, Address, TableWrapper, ColumnWrapper, Tooltip, ActionButton, HiddenWrapper} from './styles';
import List from './List';
import {SeparatePoints} from '../../ui-kit/Icons/SeparatePoints';
import {Button} from '../../ui-kit/Buttons/Button';
import {useHistory} from 'react-router';
import {route} from '../../constants/route';
import {PAGE_SIZE} from '../../constants/data';
import {LinkText, StyledLink} from '../Home/styles';
import {CopyIcon} from '../../ui-kit/Icons/CopyIcon';
import {compareDataWithCurrentDate, dateToDDMMYY} from '../../helpers/date';
import Table from '../../ui-kit/Table';

interface Props {
  data: InstanceType<ValidatorType[]>[];
  count?: number;
  page?: number;
  loading: boolean;
  updateValidatorStatus: (id: string) => Promise<void>;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  stakingAccess: boolean;
}

export const Validators: React.FC<Props> = ({
  data,
  count,
  page,
  setPage,
  loading,
  stakingAccess,
  updateValidatorStatus,
}) => {
  const history = useHistory();
  const pageCount = count ? Math.ceil(count / PAGE_SIZE) : Math.ceil(1 / PAGE_SIZE);
  const handlerGoToAddValidPage = () => {
    history.push(route.addValidator);
  };
  const handlerUpdateStatus = (id: string) => () => {
    const Button = document.getElementById(id);
    if (Button) {
      if (Button.textContent !== 'Loading') {
        Button.textContent = 'Loading';
        updateValidatorStatus(id);
      }
    }
  };
  const columns: Columns<InstanceType<ValidatorType[]>> = [
    {
      label: 'ID',
      key: 'objectId',
      dataIndex: 'objectId',
      render: (item: InstanceType<ValidatorType[]>) => (
        <>
          <Address>{item?.objectId?.toUpperCase()}</Address>
        </>
      ),
    },
    {
      label: 'Total Coordinator Balance',
      key: 'coordinatorBalanceTotal',
      dataIndex: 'coordinatorBalanceTotal',
      render: (item: InstanceType<ValidatorType[]>) => (
        <Text color={'blueGreen'} ellipsis={true} level={3}>
          {!item.coordinatorPublicKeys?.length || !item.validatorPublicKeys?.length
            ? ''
            : item.coordinatorBalanceTotal || '-'}
        </Text>
      ),
    },
    {
      label: 'Total Validator Balance',
      key: 'validatorBalanceTotal',
      dataIndex: 'validatorBalanceTotal',
      render: (item: InstanceType<ValidatorType[]>) => (
        <Text color={'blueGreen'} ellipsis={true} level={3}>
          {!item.coordinatorPublicKeys?.length || !item.validatorPublicKeys?.length
            ? ''
            : item.validatorBalanceTotal || '-'}
        </Text>
      ),
    },
    {
      label: 'Launch Date',
      key: 'activationDate',
      dataIndex: 'activationDate',
      render: (item: InstanceType<ValidatorType[]>) => (
        <Text>
          {!item.coordinatorPublicKeys?.length || !item.validatorPublicKeys?.length
            ? ''
            : item.activationDate
            ? dateToDDMMYY(item.activationDate)
            : '-'}
        </Text>
      ),
    },
    {
      label: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (item: InstanceType<ValidatorType[]>) => <Text color="blueLight3">{item.status}</Text>,
    },
    {
      label: 'Actions',
      key: 'action',
      dataIndex: 'action',
      render: (item: InstanceType<ValidatorType[]>) =>
        (compareDataWithCurrentDate(item.endStakeDate) && (
          <ActionButton selected={compareDataWithCurrentDate(item.endStakeDate)}>Redeem</ActionButton>
        )) || (
          <ActionButton id={item?.id} onClick={handlerUpdateStatus(item.id || '')}>
            Update
          </ActionButton>
        ),
    },
    {
      label: 'Details',
      dataIndex: 'Validators',
      render: (item: InstanceType<ValidatorType[]>) => (
        <HiddenWrapper>
          <Table<ValidatorType>
            type={'secondary'}
            dataSource={item?.Validators || []}
            columns={columnsHidden}
            emptyMessage={'There are no validators here yet'}
          />
        </HiddenWrapper>
      ),
    },
  ];
  const hiddenContent = [
    {
      dataIndex: 'Validators',
      contentHidingComponent: (select: boolean) => <SeparatePoints select={select} />,
    },
  ];
  const memoizedPagination = useMemo(() => {
    if (page && PAGE_SIZE && pageCount && setPage)
      return {
        current: page,
        pageSize: PAGE_SIZE,
        total: pageCount,
        handlePage: setPage,
      };
  }, [page, PAGE_SIZE, pageCount, setPage]);

  return (
    <>
      <TableWrapper>
        <StyledLink to={route.earn}>
          <LinkText>Instances</LinkText>
        </StyledLink>
        <List
          emptyMessage={!stakingAccess ? 'USA citizens cannot use stacking for now' : undefined}
          data={data}
          columns={columns}
          hiddenContent={hiddenContent}
          loading={loading}
          paginator={memoizedPagination}
        />
      </TableWrapper>
      {stakingAccess && (
        <ButtonWrapper>
          <Button type={'primary'} size={'large'} onClick={handlerGoToAddValidPage}>
            Stacking
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

const columnsHidden: Columns<ValidatorType> = [
  {
    label: 'Coordinator Address',
    key: 'coordinatorPublicKey',
    dataIndex: 'coordinatorPublicKey',
    render: (item: ValidatorType) => (
      <>
        <ColumnWrapper>
          <Address>{item?.coordinatorPublicKey}</Address>
          <Tooltip>{item?.coordinatorPublicKey}</Tooltip>
          {item?.coordinatorPublicKey && <CopyIcon dataToCopy={item?.coordinatorPublicKey || ''} />}
        </ColumnWrapper>
      </>
    ),
  },
  {
    label: 'Validator Address',
    key: 'validatorPublicKey',
    dataIndex: 'validatorPublicKey',
    render: (item: ValidatorType) => (
      <>
        <ColumnWrapper>
          <Address>{item?.validatorPublicKey}</Address>
          <Tooltip>{item?.validatorPublicKey}</Tooltip>
          {item?.validatorPublicKey && <CopyIcon dataToCopy={item?.validatorPublicKey || ''} />}
        </ColumnWrapper>
      </>
    ),
  },
  {
    label: 'Coordinator Balance',
    key: 'coordinatorBalance',
    dataIndex: 'coordinatorBalance',
    render: (item: ValidatorType) => (
      <>
        <Text color={'blueGreen'} ellipsis={true} level={3}>
          {!item.coordinatorBalance?.length || !item.coordinatorBalance?.length ? '' : item.coordinatorBalance || '-'}
        </Text>
      </>
    ),
  },
  {
    label: 'Validator Balance',
    key: 'validatorBalance',
    dataIndex: 'validatorBalance',
    render: (item: ValidatorType) => (
      <>
        <Text color={'blueGreen'} ellipsis={true} level={3}>
          {!item.validatorBalance?.length || !item.validatorBalance?.length ? '' : item.validatorBalance || '-'}
        </Text>
      </>
    ),
  },
  {
    label: 'Start of Staking',
    key: 'activationDate',
    dataIndex: 'activationDate',
    render: (item: ValidatorType) => <Text>{item?.activationDate && dateToDDMMYY(item?.activationDate)}</Text>,
  },
  {
    label: 'End of Staking',
    key: 'endStakeDate',
    dataIndex: 'endStakeDate',
    render: (item: ValidatorType) => <Text>{item?.endStakeDate && dateToDDMMYY(item?.endStakeDate)}</Text>,
  },
  {
    label: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (item: InstanceType<ValidatorType[]>) => <Text color="blueLight3">{item.status}</Text>,
  },
];
