import React, {useState} from 'react';
import {ModalWindow} from '../../../ui-kit/Modal';
import {ButtonSend} from './styles';
import {NFTTokenSendContainer} from '../../../containers/Assets/NFTTokenSend';

export const NFTSendButton: React.FC<{tokenAddress: string; name: string; tokenID: string}> = ({
  tokenAddress,
  name,
  tokenID,
}) => {
  const [isSendWindowOpen, setIsSendWindowOpen] = useState(false);
  const closeSendWindow = () => setIsSendWindowOpen(false);
  const openSendWindow = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsSendWindowOpen(true);
  };

  return (
    <>
      <ModalWindow visible={isSendWindowOpen} title={`Send ${name} Token`} onClose={closeSendWindow}>
        <NFTTokenSendContainer tokenAddress={tokenAddress} tokenID={tokenID} modalClose={closeSendWindow} />
      </ModalWindow>
      <ButtonSend type={'secondary'} onClick={openSendWindow}>
        Send
      </ButtonSend>
    </>
  );
};
