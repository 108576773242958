import React from 'react';
import {useSignOut} from '../../hooks/auth';
import MenuButton from '../../components/Account/MenuButton';
import {useViewer} from '../../hooks/user';
import {useGetTransaction, useTransactionsMethods, useTransactionsPending} from '../../hooks/transactions';

export const AccountContainer = () => {
  const viewer = useViewer();
  const {signOut} = useSignOut();
  const {loading} = useTransactionsPending();
  const {transactions} = useGetTransaction();
  const {clearTransactions} = useTransactionsMethods();

  if (!viewer) return null;

  return (
    <MenuButton
      transactions={transactions}
      clearTransactions={clearTransactions}
      loading={loading}
      signOut={signOut}
      address={viewer.address}
    />
  );
};
