import React from 'react';
import {RecoilRoot} from 'recoil';
import {useQuery} from '@apollo/client';
import {Viewer as ViewerQuery, ViewerResponseType} from '../queries/auth';
import {session} from '../states/session';
import {isServer} from '../helpers/validations';

interface Props {
  children: React.ReactNode;
}

const RecoilProvider: React.FC<Props> = ({children}) => {
  const {data, loading} = useQuery<ViewerResponseType>(ViewerQuery, {
    ...(isServer() ? {fetchPolicy: 'cache-only'} : {}),
    ssr: true,
  });
  if (loading) return null;
  return (
    <RecoilRoot
      initializeState={({set}) => {
        if (!data?.viewer) return;
        set(session, data.viewer);
      }}>
      {children}
    </RecoilRoot>
  );
};

export default RecoilProvider;
