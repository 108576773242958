import {UserCardType} from '../types/validator';
import {gql} from '@apollo/client';
import {Card} from './fragments/cards';

export interface GetUserCardRequest {
  userId: string;
}

export interface GetUserCardResponse {
  paymentGetUserCards: {
    cards?: UserCardType[];
    defaultCardId?: string;
  };
}

export const getUserCards = gql`
  mutation getUserCards($userId: String!) {
    paymentGetUserCards(input: {userId: $userId}) {
      cards {
        ...CardFragment
      }
      defaultCardId
    }
  }
  ${Card}
`;

export interface DeleteUserCardRequest {
  cardId: string;
}

export const deleteUserCard = gql`
  mutation deleteUserCard($cardId: String!) {
    paymentDeleteCard(input: {cardId: $cardId})
  }
`;

export interface SetDefaultUserCardRequest {
  cardId: string;
}
export const setDefaultUserCard = gql`
  mutation setDefaultUserCard($cardId: String!) {
    paymentSetDefaultCard(input: {cardId: $cardId})
  }
`;

export interface AddUserCardRequest {
  nameOnCard: string;
  cardNumber: string;
  expiryDate: string;
  cvc: string;
  cardType: string;
}
export const addUserCard = gql`
  mutation addUserCard($nameOnCard: String, $cardNumber: String!, $expiryDate: String!, $cvc: String!) {
    paymentAddCard(input: {nameOnCard: $nameOnCard, cardNumber: $cardNumber, expiryDate: $expiryDate, cvc: $cvc})
  }
`;
export interface getUserInvoicesResponse {
  invoices: {
    edges: [
      {
        node: {
          id: string;
          currency: string;
          status: string;
          total: number;
          subscription: string;
          invoice_pdf: string;
          created: number;
          number: string;
          description: string;
          url: string;
        };
      },
    ];
  };
}

export const getUserInvoices = gql`
  query getUserInvoices($where: InvoiceWhereInput, $order: [InvoiceOrder!]) {
    invoices(where: $where, order: $order) {
      edges {
        node {
          id
          currency
          status
          total
          subscription
          invoice_pdf
          created
          number
          description
          url
        }
      }
    }
  }
`;
