import React from 'react';
import {CheckboxContainer, HiddenCheckbox, StyledCheckbox} from './styles';
import {CheckboxProps} from './types';

export const Checkbox: React.FC<CheckboxProps> = ({$isChecked, onChange, ...props}) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={$isChecked} onChange={onChange} />
      <StyledCheckbox {...props} $isChecked={$isChecked} />
    </CheckboxContainer>
  );
};
