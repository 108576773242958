import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Handover} from '@verifai/websdk-react';
import {modalStyles} from './index';
import {Layout} from '../../ui-kit/Layout';

export const HandoverComponent = () => {
  return (
    <Layout>
      <Handover theme={modalStyles} display="inline" />
    </Layout>
  );
};
