import React from 'react';
import {theme} from '../../ui-kit/theme';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WebSDK from '@verifai/websdk-react';

interface Props {
  sendSessionId: (sessionId: string) => Promise<void>;
  token: string;
  handlerChangeModalStatus: () => void;
  status: boolean;
}

export const modalStyles = {
  corners: {
    modalRadius: '10',
    buttonRadius: '10',
  },
  colors: {
    modalBackground: theme.pallet.background.tertiary,
    imageBackground: '#f3f3f3',
    textHeader: theme.pallet.common.white,
    textDescription: theme.pallet.common.gray,
    buttonBack: theme.pallet.common.gray,
    buttonMain: theme.pallet.blueScale._7,
    buttonMainHover: theme.pallet.blueScale._7,
    bodyText: theme.pallet.common.white,
    bodyErrorText: theme.pallet.common.red,
  },
};

export const KYCModal: React.FC<Props> = ({token, sendSessionId, handlerChangeModalStatus, status}) => {
  return (
    <WebSDK
      token={token}
      faceMatchMobileOnly={true}
      show={status}
      uploadTypes={['phone']}
      onSuccess={sendSessionId}
      theme={modalStyles}
      display={'inline'}
      closable={true}
      onCanceled={handlerChangeModalStatus}
    />
  );
};
