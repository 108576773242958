import styled from 'styled-components';
import {Button} from '../../ui-kit/Buttons/Button';
import {styles} from '../../ui-kit/Form/Input/styles';
import {Text, Title} from '../../ui-kit/Typography/Typography';
import {theme} from '../../ui-kit/theme';

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 51px;
  margin-bottom: 63px;
  & > svg {
    position: absolute;
    top: 12px;
    right: 6px;
  }
`;

export const CopyWrapper = styled.div`
  margin-left: 8px;
`;

export const ContactsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AsideWrapper = styled.div`
  max-width: 340px;
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #202227;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 63px 0;
`;

export const QrCodeData = styled(Text).attrs({
  level: 2,
})`
  margin-top: 20px;
`;

export const BackGroundQrCodeContainer = styled.div`
  background: white;
  padding: 16px;
  border-radius: 10px;
  & > div {
    padding: 0;
  }
`;

export const AddContactFormContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 73px;
  border-radius: 10px;
  max-width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #202227;
  box-sizing: border-box;
  gap: 20px;
`;
export const AddContactFormContainerQr = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #202227;
  & > form > label {
    margin-bottom: 20px;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 200px;
`;

export const InputSearch = styled.input`
  ${styles};
  border-radius: 10px;
  width: 100%;
  background: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 60px;
  color: #d2e1f2;
  text-indent: 30px;
`;

export const ContactsButton = styled(Button).attrs({
  variant: 'primary',
  selected: true,
})`
  height: 61px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const Address = styled(Text).attrs({
  level: 2,
})`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
`;

export const TitleText = styled(Title).attrs({
  level: 2,
  color: 'white',
})``;

export const FormModalContainer = styled.div<{$noModal?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.pallet.common.darkGray};
  border-radius: 10px;
  padding: ${({$noModal}) => ($noModal ? '20px' : '0px')}};
  & > div > button {
    width: ${({$noModal}) => ($noModal ? '100%' : '150px')}};
  }
  & > div > button:nth-child(2) {
    margin-right: 0;
  }
`;

export const ModalButtonWrapper = styled.div<{$space?: boolean}>`
  width: 100%;
  display: flex;
  justify-content: ${({$space}) => ($space ? 'space-between' : 'flex-end')}}
`;

export const StyledModalButton = styled(Button).attrs({
  variant: 'primary',
  selected: true,
})`
  height: 61px;
  width: 200px;
`;

export const DeleteButton = styled(Button).attrs({
  variant: 'secondary',
  selected: true,
})`
  height: 61px;
  width: 200px;
  background-color: ${theme.pallet.common.red};
  border-color: ${theme.pallet.common.red};
  &:hover {
    background-color: ${theme.pallet.common.red};
  }
`;

export const NameWrapper = styled(Text).attrs({
  level: 2,
})`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  & > div {
    border-radius: 50%;
    margin-right: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;
  & > button {
    width: 70px;
  }
  & > button:nth-child(1) {
    margin-right: 10px;
  }
`;
