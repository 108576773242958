import {gql} from '@apollo/client';
import {Rates} from './fragments/rates';
import {DataCenterType, InstanceType, ValidatorPaymentType, ValidatorType} from '../types/validator';
import {Instances, Validator} from './fragments/validator';
import {
  GQLInstanceOrder,
  GQLInstanceWhereInput,
  GQLValidatorOrder,
  GQLValidatorPaymentOrder,
  GQLValidatorPaymentWhereInput,
  GQLValidatorWhereInput,
} from '../graphql.schema';

export interface GetRatesResponse {
  getRates: {
    rates: DataCenterType[];
  };
}

export interface AddValidationRequest {
  datacenter: string;
  card?: {
    cardNumber: string;
    cvc: string;
    expiryDate: string;
    nameOnCard: string;
  };
  cardId?: string;
  zoneName: string;
  outerId: string;
  regionId: string;
  paymentMethod: string;
}

export interface AddValidationResponse {
  addValidator: {
    instanceId: string;
    url: string;
  };
}

export interface ValidatorTypeQuery {
  edges: [
    {
      node: ValidatorType;
    },
  ];
}

export const validator = gql`
  mutation addValidation(
    $datacenter: String!
    $card: AddCardToUserInp
    $cardId: String
    $zoneName: String!
    $outerId: String!
    $regionId: String!
    $paymentMethod: PaymentMethod!
  ) {
    addValidator(
      input: {
        paymentMethod: $paymentMethod
        datacenter: $datacenter
        card: $card
        cardId: $cardId
        zoneName: $zoneName
        outerId: $outerId
        regionId: $regionId
      }
    ) {
      url
      instanceId
    }
  }
`;

export const getRates = gql`
  mutation getRates {
    getRates {
      rates {
        ...RatesFragment
      }
    }
  }
  ${Rates}
`;

export interface PaymentsData {
  address: string;
  amount: string;
  chainId: number;
  contract: string;
  countConfirmation: number;
  label: string;
  rpc: string;
  symbol: string;
  value: string;
}

export interface GetPaymentsResponse {
  getPaymentConfig: {
    config: PaymentsData[];
  };
}

export interface GetPaymentsRequest {
  datacenter?: string;
  zoneName?: string;
  regionId?: string;
}

export const getPayments = gql`
  mutation getPaymentConfig($datacenter: String, $zoneName: String, $regionId: String) {
    getPaymentConfig(input: {datacenter: $datacenter, zoneName: $zoneName, regionId: $regionId}) {
      config {
        address
        amount
        chainId
        contract
        countConfirmation
        label
        rpc
        symbol
        value
      }
    }
  }
`;

export interface GetValidatorPaymentsRequest {
  skip: number;
  first: number;
  order: GQLValidatorPaymentOrder;
  where?: GQLValidatorPaymentWhereInput;
}

export interface GetValidatorPaymentsResponse {
  validatorPayments: {
    edges: [
      {
        node: ValidatorPaymentType;
      },
    ];
  };
}

export const getValidatorPayments = gql`
  query getValidatorPayments(
    $skip: Int
    $first: Int
    $where: ValidatorPaymentWhereInput
    $order: [ValidatorPaymentOrder!]
  ) {
    validatorPayments(skip: $skip, first: $first, where: $where, order: $order) {
      edges {
        node {
          id
          network
          outerId
          Validators {
            edges {
              node {
                id
              }
            }
          }
          amount
        }
      }
    }
  }
`;

export interface GetValidatorsRequest {
  skip: number;
  first: number;
  order: GQLValidatorOrder;
  where?: GQLValidatorWhereInput;
}
export interface GetValidatorsResponse {
  validators: {
    edges: [
      {
        node: ValidatorType;
      },
    ];
    count: number;
  };
}

export const getValidators = gql`
  query getValidators($skip: Int, $first: Int, $where: ValidatorWhereInput, $order: [ValidatorOrder!]) {
    validators(skip: $skip, first: $first, where: $where, order: $order) {
      edges {
        node {
          ...ValidatorFragment
        }
      }
      count
    }
  }
  ${Validator}
`;

export interface GetValidatorRequest {
  id: string;
}

export interface GetValidatorResponse {
  validator: ValidatorType;
}
export const getValidator = gql`
  query getValidator($id: ID!) {
    validator(id: $id) {
      ...ValidatorFragment
    }
  }
  ${Validator}
`;

export interface GetInstancesRequest {
  skip: number;
  first: number;
  order: GQLInstanceOrder;
  where?: GQLInstanceWhereInput;
}
export interface GetInstancesResponse {
  instances: {
    edges: [
      {
        node: InstanceType<ValidatorTypeQuery>;
      },
    ];
    count: number;
  };
}

export const getInstances = gql`
  query getInstances($skip: Int, $first: Int, $where: InstanceWhereInput, $order: [InstanceOrder!]) {
    instances(skip: $skip, first: $first, where: $where, order: $order) {
      edges {
        node {
          ...InstanceFragment
        }
      }
      count
    }
  }
  ${Instances}
`;

export interface GetInstanceRequest {
  id: string;
}
export interface GetInstanceResponse {
  instance: InstanceType<ValidatorTypeQuery>;
}

export const getInstance = gql`
  query getInstance($id: ID!) {
    instance(id: $id) {
      ...InstanceFragment
    }
  }
  ${Instances}
`;

export interface RepaySubscriptionRequest {
  subId: string;
}
export interface RepaySubscriptionResponse {
  repaySubscription: string;
}

export const repaySubscription = gql`
  mutation repaySubscription($subId: String!) {
    repaySubscription(input: {subId: $subId})
  }
`;
