import {gql} from '@apollo/client';

export const SaveTokenQuery = gql`
  mutation saveTokenQuery($fields: CreateAppFileFieldsInput!) {
    createAppFile(input: {fields: $fields}) {
      appFile {
        objectId
      }
    }
  }
`;

export const GetTokenQuery = gql`
  query appFiles($where: AppFileWhereInput!) {
    appFiles(where: $where) {
      edges {
        node {
          name
          filePath
        }
      }
    }
  }
`;
