import React from 'react';
import {Validators} from '../../components/Validators';
import {useGetInstances} from '../../hooks/validator';
import {Layout} from '../../ui-kit/Layout';
import {ContainerWrapper} from './styles';
import {route} from '../../constants/route';
import {PAGE_SIZE} from '../../constants/data';
import {useViewer} from '../../hooks/user';

export const ValidatorsContainer = () => {
  const {data, count, page, setPage, loading, updateValidatorStatus} = useGetInstances(PAGE_SIZE, route.earn);
  const viewer = useViewer();
  const stakingAccess = !Boolean(viewer?.isVerified && viewer?.kycData?.country_code_parsed === 'USA');
  return (
    <Layout>
      <ContainerWrapper>
        <Validators
          stakingAccess={stakingAccess}
          data={data}
          count={count}
          page={page}
          setPage={setPage}
          loading={loading}
          updateValidatorStatus={updateValidatorStatus}
        />
      </ContainerWrapper>
    </Layout>
  );
};
