import styled from 'styled-components';
import {theme} from '../theme';

export const Input = styled.button`
  height: 50px;
  width: 100%;
  background-color: ${theme.pallet.background.primary};
  border-radius: 10px;
  border: 0;
  color: ${theme.pallet.common.white};
`;

export const StyledCalendar = styled.div`
  width: 100%;
  .react-datepicker__navigation-icon {
    :before {
      border-color: ${theme.pallet.common.blueGreen};
    }
  }
  .react-datepicker__header {
    padding: 10px;
    border-color: ${theme.pallet.common.blueGreen};
  }
  .react-datepicker__month-container {
    div {
      color: ${theme.pallet.common.white};
      font-family: Poppins, sans-serif;
      background-color: ${theme.pallet.background.primary};
    }
  }
  .react-datepicker {
    border: 0;
    border-radius: 10px;
    background-color: ${theme.pallet.common.blueGreen};
  }
  .react-datepicker__month {
    margin: 0;
    padding: 10px;
  }
`;
