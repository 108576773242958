import React, {useState} from 'react';
import {ContentWrapper, MainWrapper, TabButton, TabsWrapper, TabTitle, TabWrapper} from './styles';
import {ITabNode} from './types';

interface Type {
  children: JSX.Element[];
  defaultActiveKey?: string;
}

export const Tabs: React.FC<Type> = ({children, defaultActiveKey}) => {
  const [active, setActive] = useState<string>(defaultActiveKey || children[0]?.props.name || '');

  return (
    <MainWrapper>
      <TabsWrapper>
        {children.map((item, index) => {
          return (
            <TabPane active={active} setActive={setActive} {...item.props} key={index}>
              {item.props.children}
            </TabPane>
          );
        })}
      </TabsWrapper>
      {children.map((item, index) => {
        return (
          <ContentWrapper key={index} $isActive={item.props.tab === active}>
            {item.props.children}
          </ContentWrapper>
        );
      })}
    </MainWrapper>
  );
};

export const TabPane: React.FC<ITabNode> = ({to, tab, active, setActive, onClick}) => {
  const name = tab;
  const handleClick = () => {
    setActive && setActive(name);
    onClick && onClick();
  };
  return (
    <TabWrapper id={name} onClick={handleClick}>
      <TabButton $isActive={name === active} to={to}>
        <TabTitle>{name}</TabTitle>
      </TabButton>
    </TabWrapper>
  );
};
