import React, {useCallback, useState} from 'react';
import {SendTransactionForm} from '../../components/Contacts/Form';
import {transfer, useValidChain} from '../../hooks/metamask';
import {useViewer} from '../../hooks/user';
import {useTransactionsMethods} from '../../hooks/transactions';
import {Transaction, TypeOfTransactions} from '../../types/transaction';
import {toLocalTransaction} from '../../helpers/transaction';
import {useGetContacts} from '../../hooks/contacts';
import {errorValues} from '../../helpers/metamaskHelper';

type SendTransactionContainer = {
  sendTo?: string;
  closeModal?: () => void;
  noModal?: boolean;
};

export const SendTransactionContainer: React.FC<SendTransactionContainer> = ({sendTo, closeModal, noModal}) => {
  const user = useViewer();
  const currentAddress = user?.address;
  const {addTransaction} = useTransactionsMethods();
  const {contacts} = useGetContacts();
  const {checkChain} = useValidChain();
  const [error, setError] = useState<string>('');

  const sendCrypto = useCallback(
    async (to = sendTo, amount: string) => {
      if (currentAddress && to) {
        try {
          await checkChain();
          const hash = await transfer(currentAddress, to, amount);
          closeModal && closeModal();
          const transaction: Transaction = {
            transactionHash: hash,
            transactionType: TypeOfTransactions.sendWater,
            data: {amount: amount, recipient: to, symbol: 'WAT'},
          };
          if (hash) addTransaction(toLocalTransaction(transaction, contacts));
          return true;
        } catch (e) {
          console.log(e);
          errorValues[e.code]?.message ? setError(errorValues[e.code]?.message || '') : setError('Transaction failed');
          return false;
        }
      }
      return false;
    },
    [currentAddress, transfer, checkChain],
  );

  return (
    <SendTransactionForm error={error} contacts={contacts} onSubmit={sendCrypto} sendTo={sendTo} noModal={noModal} />
  );
};
