import React from 'react';
import {Circle, SeparateWrapper} from './styles';

interface Props {
  type?: 'primary' | 'secondary';
  select?: boolean;
}

export const SeparatePoints: React.FC<Props> = ({select, type = 'primary'}) => {
  return (
    <>
      <SeparateWrapper theme={{select: select}}>
        {type === 'secondary' && <Circle theme={{type}} />}
        <Circle theme={{type}} />
        <Circle theme={{type}} />
        <Circle theme={{type}} />
      </SeparateWrapper>
    </>
  );
};
