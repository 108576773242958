import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import ApolloProvider from './ApolloProvider';
import {QueryClient, QueryClientProvider} from 'react-query';

export const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
