import styled from 'styled-components';
import {Button} from '../../../ui-kit/Buttons/Button';

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 25px 0;
`;

export const TokenCreateForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const ButtonSecondarySelect = styled(Button).attrs({
  variant: 'primary',
})`
  align-self: flex-end;
  margin-top: 5px;
  height: 60px;
  max-width: 200px;
`;

export const ButtonSend = styled(Button).attrs({
  type: 'secondary',
})`
  min-height: 45px;
`;
