import React, {useState} from 'react';
import {ModalWindow} from '../../ui-kit/Modal';
import {ButtonModal} from './styles';
import AddCard from './AddCard';
import {CardFormType} from '../../types/card';

type ActionButtonProps = {
  setCardData: React.Dispatch<React.SetStateAction<CardFormType>>;
  cardData: CardFormType;
  submit: () => Promise<boolean>;
};

export const ActionButton: React.FC<ActionButtonProps> = ({submit, setCardData, cardData}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const closeWindow = () => setIsWindowOpen(false);
  const openWindow = () => setIsWindowOpen(true);
  const handleSubmit = async () => {
    const result = await submit?.();
    if (!result) return;
    closeWindow();
  };

  return (
    <>
      <ButtonModal type={'primary'} onClick={openWindow}>
        Add Card
      </ButtonModal>
      <ModalWindow visible={isWindowOpen} title={'Add New Card'} onClose={closeWindow}>
        <AddCard cardData={cardData} setCardData={setCardData} submit={handleSubmit} />
      </ModalWindow>
    </>
  );
};
