import styled from 'styled-components';
import {theme} from '../../../ui-kit/theme';
import {Text} from '../../../ui-kit/Typography/Typography';
import {LinkButton} from '../../../ui-kit/Buttons/LinkButton';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading {
    fill: ${theme.pallet.common.gray};
    animation: spin 1.5s normal linear infinite;
  }
`;

export const Title = styled(Text).attrs({
  ellipsis: true,
  level: 2,
  color: 'gray',
  nowrap: true,
})`
  width: calc(100% - 40px);
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const Action = styled(LinkButton)`
  min-width: 125px;
  cursor: pointer;
  background-color: transparent;
`;

export const LoadingWrapper = styled.div`
  cursor: pointer;
  background-color: transparent;
`;
