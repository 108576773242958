import React from 'react';
import {TopAppBarContainer} from '../../containers/Layout/TopAppBar';
import {Layout} from './Layout';
import {useIsAuthenticated} from '../../hooks/auth';
import {Footer} from './Footer';

interface Props {
  children: React.ReactNode;
}

export const AppLayout: React.FC<Props> = ({children}) => {
  const isAuth = useIsAuthenticated();
  const topAppBar = isAuth ? <TopAppBarContainer /> : null;

  return (
    <Layout topAppBar={topAppBar} footer={<Footer />}>
      {children}
    </Layout>
  );
};
