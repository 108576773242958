import styled, {keyframes} from 'styled-components';
import {Title} from '../../ui-kit/Typography/Typography';
import {theme} from '../theme';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
`;

export const ModalWrapper = styled.div`
  cursor: default;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: ${appear};
  animation-duration: 300ms;
`;

export const ModalDialog = styled.div<{maxWidth?: number}>`
  width: 100%;
  min-width: 360px;
  max-width: ${({maxWidth}) => (maxWidth ? `${maxWidth}px` : 'fit-content')};
  position: absolute;
  background-color: ${theme.pallet.common.darkGray};
  border-radius: 8px;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: ${slideIn};
  animation-duration: 0.5s;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.3);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.pallet.common.blueGreen};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 17px 0 17px;
`;

export const ModalTitle = styled(Title).attrs({
  variant: 'primary',
  level: 2,
  weight: 'semi-bold',
  color: 'whiteGray',
})``;

export const ModalClose = styled.span`
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  font-size: 40px;
  color: ${theme.pallet.common.white};
`;

export const ModalBody = styled.div`
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 1rem;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  font-size: 20px;
  color: ${theme.pallet.common.blueLight};
`;

export const ModalFooter = styled.div`
  margin: 1rem;
  font-size: 22px;
  color: ${theme.pallet.common.gray};
`;
