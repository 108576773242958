import {Footer as FooterWrapper, SecurityWrapper, StripeWrapper} from './styles';
import {Text} from '../../ui-kit/Typography/Typography';
import React from 'react';
import {IconSvg} from '../../ui-kit/Icons/Svg';

export const FormFooter = () => {
  return (
    <FooterWrapper>
      <SecurityWrapper>
        <IconSvg type="lock" width="26" height="34" viewBox="0 0 26 34" />
        <Text color={'gray'}>
          Guaranteed{' '}
          <Text color={'gray'} strong={true}>
            safe and secure
          </Text>{' '}
          checkout
        </Text>
      </SecurityWrapper>
      <StripeWrapper>
        Powered by
        <IconSvg type="stripe" width="50" height="21" viewBox="0 0 50 21" />
      </StripeWrapper>
    </FooterWrapper>
  );
};
