import React from 'react';
import {useCollectionNFT, useHistoryByAddress} from '../../../hooks/tokens';
import {useParams} from 'react-router-dom';
import {TokenTypes} from '../../../types/tokens';
import {FTViewPage} from '../../../components/Assets/FTViewPage';
import {NFTViewPage} from '../../../components/Assets/NFTViewPage';
import {useHistory} from 'react-router';

export const ViewPageContainer = () => {
  const {type} = useParams<{type: string}>();
  if (type === TokenTypes.FT) {
    return <FTViewPageContainer />;
  } else return <NFTViewPageContainer />;
};

export const NFTViewPageContainer = () => {
  const {address} = useParams<{address: string}>();
  const {collection, isOwner, setPage, page, totalCount} = useCollectionNFT(address);
  const {
    historyList,
    setPageHistory,
    pageHistory,
    pageSize,
    totalCount: totalCountHistory,
    loading: loadingHistory,
  } = useHistoryByAddress(address);
  const {push} = useHistory();

  const forwardMint = () => {
    push(`/assets/nft/${address}/mint`);
  };

  return (
    <NFTViewPage
      forwardMint={forwardMint}
      isOwner={isOwner}
      setPage={setPage}
      page={page}
      totalCount={totalCount}
      collection={collection}
      tokenAddress={address}
      historyList={historyList}
      setPageHistory={setPageHistory}
      pageHistory={pageHistory}
      pageSizeHistory={pageSize}
      totalCountHistory={totalCountHistory}
      loadingHistory={loadingHistory}
    />
  );
};

export const FTViewPageContainer = () => {
  const {address} = useParams<{address: string}>();
  const {historyList, setPageHistory, pageHistory, pageSize, totalCount, loading, balance} =
    useHistoryByAddress(address);

  return (
    <FTViewPage
      tokenAddress={address}
      tokenBalance={balance}
      historyList={historyList}
      setPageHistory={setPageHistory}
      pageHistory={pageHistory}
      pageSizeHistory={pageSize}
      totalCountHistory={totalCount}
      loadingHistory={loading}
    />
  );
};
