import React, {ReactNode} from 'react';
import {ContentWrapper, CopyIconWrapper} from './styles';
import {IconSvg} from './Svg';

interface Props {
  dataToCopy: string;
  children?: ReactNode;
  color?: 'primary' | 'secondary';
}

export const CopyIcon: React.FC<Props> = ({dataToCopy, children, color}) => {
  const saveToBuffer = () => {
    navigator.clipboard.writeText(dataToCopy);
  };
  return (
    <ContentWrapper onClick={saveToBuffer}>
      <CopyIconWrapper color={color}>
        <IconSvg type="copy" width="18" height="18" viewBox="0 0 18 18" />
      </CopyIconWrapper>
      {children}
    </ContentWrapper>
  );
};
