import React from 'react';
import {StepTwoWrapper, FormLine, FormTitle, BackButtonWrapper, BackButtonText, SelectPaymentWrapper} from './styles';
import {IconSvg} from '../../ui-kit/Icons/Svg';
import {DropSelect} from '../../ui-kit/Form/Select/DropDownSelect';
import {Options} from '../../ui-kit/Form/Select/types';
interface Props {
  backStep: () => void;
  paymentList: Options[];
  choosePaymentMethod: (arg1: string, arg2: string) => void;
  selectOption: string;
}

export const PaymentVariantStep: React.FC<Props> = ({backStep, paymentList, choosePaymentMethod, selectOption}) => {
  return (
    <StepTwoWrapper>
      <FormTitle>
        Select a Payment Method:
        <BackButtonWrapper onClick={backStep}>
          <IconSvg type="arrowLeft" width="9" height="16" viewBox="0 0 9 16" />
          <BackButtonText>Back</BackButtonText>
        </BackButtonWrapper>
      </FormTitle>
      <FormLine />
      <SelectPaymentWrapper>
        <DropSelect
          name={'Payment method'}
          options={paymentList}
          onChange={choosePaymentMethod}
          selectOption={selectOption}
          label={'Payment method'}
        />
      </SelectPaymentWrapper>
    </StepTwoWrapper>
  );
};
