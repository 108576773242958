import React from 'react';
import {useKYC} from '../../hooks/kyc';
import {KYCModal} from '../../components/KYC';
import {Loader} from '../../ui-kit/Loader';

interface Props {
  status: boolean;
  handlerChangeModalStatus: () => void;
}

export const KYCContainer: React.FC<Props> = ({status, handlerChangeModalStatus}) => {
  const {kycToken, sendSessionId} = useKYC(handlerChangeModalStatus);
  if (kycToken.length === 0) {
    return <Loader />;
  }
  return (
    <KYCModal
      token={kycToken}
      sendSessionId={sendSessionId}
      status={status}
      handlerChangeModalStatus={handlerChangeModalStatus}
    />
  );
};
