import React, {useEffect, useRef, useState} from 'react';
import {
  DropSelectWrapper,
  DropSelectValue,
  DropArrowWrapper,
  DropValueWrapper,
  DropSelectContentWrapper,
  DropMenu,
  DropMenuItem,
} from './styles';
import {Button} from '../../Buttons/Button';
import {Text} from '../../Typography/Typography';
import {DropDownMultiple, DropDownMultipleType} from './types';
import {IconSvg} from '../../Icons/Svg';

export const DropDownSelect = ({selectOption, onChange, name, options}: DropDownMultiple) => {
  const optionRef = useRef<HTMLHeadingElement>(null);
  const [menuStatus, setStatus] = useState<boolean>(false);

  const setMenuStatus = () => {
    setStatus(!menuStatus);
  };
  const handleChange = (label: string) => () => {
    onChange(label, name);
    setMenuStatus();
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!optionRef?.current?.contains(event.target)) {
        setStatus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionRef]);
  const selectOptionObject = options?.find((el) => el.label === selectOption);
  return (
    <DropSelectWrapper ref={optionRef}>
      <Button selected={selectOption === selectOptionObject?.label} onClick={setMenuStatus}>
        <DropSelectContentWrapper>
          <DropValueWrapper>
            <Text>{selectOptionObject?.label ? selectOptionObject?.label : name}</Text>
            {selectOptionObject?.value && <DropSelectValue>{selectOptionObject?.value}</DropSelectValue>}
          </DropValueWrapper>
          <DropArrowWrapper>
            {menuStatus ? (
              <IconSvg type="downArrowActive" width="19" height="13" viewBox="0 0 19 13" />
            ) : (
              <IconSvg type={'downArrow'} width="19" height="13" viewBox="0 0 19 13" />
            )}
          </DropArrowWrapper>
        </DropSelectContentWrapper>
      </Button>
      {menuStatus && options && (
        <DropMenu>
          {options?.map((el, index) => (
            <DropMenuItem
              key={index}
              onClick={handleChange(el.label)}
              color={el?.label === selectOption ? 'gray3' : 'white'}>
              {el.label}
            </DropMenuItem>
          ))}
        </DropMenu>
      )}
    </DropSelectWrapper>
  );
};

export const DropSelectMultiple: React.FC<DropDownMultipleType> = ({onChange, selectOption, multipleOption}) => {
  return (
    <>
      {multipleOption.map((el, index) => (
        <DropDownSelect
          key={index}
          name={el.name}
          onChange={onChange}
          selectOption={selectOption}
          options={el.options}
        />
      ))}
    </>
  );
};
