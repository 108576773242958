import {useEffect} from 'react';
import {useSetRecoilState, useRecoilValue} from 'recoil';
import {useMutation, useApolloClient} from '@apollo/client';
import {isLoad} from '../states/app';
import {session as sessionState, SessionStateType} from '../states/session';
import {setToken, getToken} from '../libs/auth';
import {useViewer} from './user';
import {isString} from '../helpers/validations';
import {checkMetaMask} from '../helpers/metamaskHelper';

import {
  loginWithMetaMask,
  createAuthMetamask,
  createAuthMMRequestType,
  createAuthMMResponseType,
  AuthWithResponseType,
  AuthWithRequestType,
  SignOutResponseType,
  SignOut,
} from '../queries/auth';
import {Viewer as ViewerQuery, ViewerResponseType} from '../queries/auth';
import {useMetaMask} from 'metamask-react';

export const useIsAuthenticated = (): boolean => {
  return isString(useViewer()?.id);
};

export function useFetchSession() {
  const setSession = useSetRecoilState(sessionState);
  const setIsLoad = useSetRecoilState(isLoad);

  const client = useApolloClient();

  useEffect(() => {
    const _fetchData = async (): Promise<boolean> => {
      let data;
      try {
        const token = await getToken();
        if (!token) {
          setSession(null);
          setIsLoad(true);
          return false;
        }
        const result = await client.query<ViewerResponseType>({
          query: ViewerQuery,
        });
        data = result?.data?.viewer;
        if (!data) {
          setToken(null);
          setSession(null);
          setIsLoad(true);
          return false;
        }
      } catch (e) {
        console.log(e);
      }
      data = {...data, user: data?.user};
      setSession(data as SessionStateType);
      setIsLoad(true);
      return true;
    };

    _fetchData();
  }, [setSession]);
}

export const useIsAppLoad = (): boolean => {
  return useRecoilValue(isLoad);
};

type AuthDataTypeMetaMask = {
  address: string;
};
type DataToSign = {
  id: string;
  token: string;
};

export const useAuth = () => {
  const setSession = useSetRecoilState(sessionState);
  const {connect} = useMetaMask();
  const [SignUpWithRequest] = useMutation<AuthWithResponseType, AuthWithRequestType>(loginWithMetaMask);
  const [CreateMMAuthRequest] = useMutation<createAuthMMResponseType, createAuthMMRequestType>(createAuthMetamask);
  const ethereum = window.ethereum;
  const authWithMetaMask = async <T>(
    authData: T,
    SignUpWithRequest: (params: any) => any,
  ): Promise<DataToSign | undefined> => {
    try {
      const result = await SignUpWithRequest({
        variables: {
          address: authData,
        },
      });
      const data = result?.data?.getAuthId;
      if (!data) throw new Error('Registration failed');
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const signInWithMetaMask = async () => {
    const connection = checkMetaMask();
    if (!connection) {
      alert('install metamask');
      return;
    }
    const accounts = await connect();
    if (!accounts || !accounts[0]) {
      return;
    }
    try {
      const authData: AuthDataTypeMetaMask = {
        address: accounts[0],
      };
      const data = await authWithMetaMask(authData.address, CreateMMAuthRequest);
      if (!data) {
        return;
      }
      const signature = await ethereum.request({
        method: 'personal_sign',
        params: [`I am signing my one-time nonce: ${data.token}`, accounts[0], ''],
      });
      const newResult = await SignUpWithRequest({
        variables: {
          authData: {
            metamask: {
              sign: signature,
              id: data.token,
            },
          },
        },
      });
      const newData = newResult.data?.logInWith?.viewer;
      if (!newData) throw new Error('Sign is no-valid');
      if (!('sessionToken' in newData)) throw new Error('Sign is no-valid');
      await setToken(newData.sessionToken as string);
      await setSession({
        sessionToken: newData.sessionToken,
        user: {...newData.user},
      });
    } catch (error) {
      console.log(error);
      return;
    }
  };
  return {
    signInWithMetaMask,
  };
};

export const useSignOut = () => {
  const setSession = useSetRecoilState(sessionState);
  const [SignOutRequest, {loading}] = useMutation<undefined, SignOutResponseType>(SignOut);

  const _signOut = async () => {
    let data = false;
    try {
      await SignOutRequest();
      data = true;
    } catch (error) {
      console.log(error);
    }

    if (!data) {
      throw new Error('something went wrong');
    }
    await setToken(null);
    await setSession(null);
  };

  return {signOut: _signOut, loading};
};
