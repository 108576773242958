import {EXPLORER_API} from '../constants/env';
import {TokensType} from '../types/tokens';
import {PAGE_SIZE} from '../constants/data';

export const explorer_api = {
  getByTokensType: async (tokens: TokensType[], viewer: string, offset: number) => {
    let strings = tokens.reduce((acc, item) => acc + `tokens=${item.contractAddress.toLowerCase()}&`, '');
    if (tokens.length === 1) strings = strings + `tokens=0xa&`;
    const response = await fetch(
      `${EXPLORER_API}/tokens/${viewer.toLowerCase()}/tx?parameters=[decodeInput]&${strings}limit=${PAGE_SIZE}&offset=${
        offset || 0
      }`,
    );
    return await response.json();
  },
  getByTokenAndAddress: async (address: string, viewer: string, offset: number) => {
    const response = await fetch(
      `${EXPLORER_API}/token/${address.toLowerCase()}/tx/${viewer.toLowerCase()}?parameters=[decodeInput]&limit=${PAGE_SIZE}&offset=${
        offset || 0
      }`,
    );
    return await response.json();
  },
  getNFTCollection: async (address: string, viewer: string, offset: number) => {
    const response = await fetch(
      `${EXPLORER_API}/account/${viewer.toLowerCase()}/${address.toLowerCase()}?limit=${PAGE_SIZE}&offset=${
        offset || 0
      }`,
    );
    return await response.json();
  },
  getNFTByTokenID: async (address: string, tokenID: string, offset: number) => {
    const response = await fetch(
      `${EXPLORER_API}/token/${address.toLowerCase()}/tx/wrc721/${tokenID.toLowerCase()}?parameters=[decodeInput]&limit=${PAGE_SIZE}&offset=${
        offset || 0
      }`,
    );
    return await response.json();
  },
  getAccountTokens: async (viewer: string) => {
    const response = await fetch(`${EXPLORER_API}/account/${viewer.toLowerCase()}/tokens/owner`);
    return await response.json();
  },
  getByUser: async (viewer: string, offset?: number, limit?: number) => {
    const response = await fetch(
      `${EXPLORER_API}/account/${viewer.toLowerCase()}/tx?parameters=[decodeInput]&limit=${limit || PAGE_SIZE}&offset=${
        offset || 0
      }`,
    );
    return await response.json();
  },
};
