import React, {useState} from 'react';
import {ErrorMessage} from '../../../ui-kit/Form/ErrorMessage/ErrorMessage';
import {Input} from '../../../ui-kit/Form/Input';
import {ButtonSecondarySelect, InputWrapper, TokenCreateForm} from './styles';
import {IFieldsSendToken} from '../../../types/tokens';
import {ContactType} from '../../../types/contacts';
import {InputAutocomplete} from '../../../ui-kit/Form/InputAutocomplete';
import {web3} from '../../../hooks/metamask';

interface IProps {
  onSend: (to: string, tokenAddress: string, tokenID: string) => void;
  loading: boolean;
  error: string;
  tokenAddress: string;
  tokenID: string;
  contacts: ContactType[];
}

export const NFTTokenSend: React.FC<IProps> = ({onSend, loading, error, tokenAddress, tokenID, contacts}) => {
  const [fields, setFields] = useState<IFieldsSendToken>({
    address: {value: '', error: ''},
    value: {value: tokenID, error: ''},
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'address') {
      setFields({...fields, [e.target.name]: {value: e.target.value, error: ''}});
    }
  };
  const handlerSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!web3.utils.isAddress(fields.address?.value || ''))
      return setFields({...fields, address: {value: fields.address?.value, error: 'Address is incorrect'}});
    if (fields.address?.value && fields.value?.value) onSend(fields.address.value, tokenAddress, fields.value.value);
  };

  const handleSetAddress = (address: string) => {
    setFields({
      ...fields,
      address: {value: address, error: ''},
    });
  };

  return (
    <TokenCreateForm onSubmit={handlerSubmit}>
      <InputWrapper>
        <InputAutocomplete
          status={fields.address?.error ? 'error' : undefined}
          errorMessage={fields.address?.error && fields.address?.error}
          data={contacts}
          autocompleteField={'address'}
          promptField={'address'}
          searchField={'name'}
          setValue={handleSetAddress}
          name={'address'}
          onChange={handleChange}
          value={fields.address?.value}
          placeholder={'Address'}
        />
        <Input
          disabled={true}
          name={'value'}
          status={fields.value?.error ? 'error' : undefined}
          errorMessage={fields.value?.error && fields.value.error}
          onChange={handleChange}
          value={fields.value?.value}
          placeholder={'TokenID'}
        />
        {error && <ErrorMessage errorMessage={error} />}
      </InputWrapper>
      <ButtonSecondarySelect disabled={loading}>Send</ButtonSecondarySelect>
    </TokenCreateForm>
  );
};
