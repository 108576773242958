import React, {Dispatch, SetStateAction} from 'react';
import {
  BalanceText,
  HistoryWrapperTable,
  SendWrapper,
  TitleTable,
  TokenBalance,
  TokenName,
  TokenText,
  TokenWrapper,
  TotalText,
} from './styles';
import {TransactionTable} from '../../../types/tokens';
import {TransactionFTList} from '../TransactionsList/TransactionFTList';
import {FTSendButton} from '../FTTokenSend';
import {Layout} from '../../../ui-kit/Layout';
import {Subtitle} from '../../../ui-kit/Typography/Typography';
import {CopyIcon} from '../../../ui-kit/Icons/CopyIcon';
import Avatar from 'react-avatar';
import {WATERFALL_SERVER} from '../../../constants/env';

interface IDerailPageFT {
  tokenAddress: string;
  historyList: TransactionTable[];
  setPageHistory: Dispatch<SetStateAction<number>>;
  loadingHistory: boolean;
  pageHistory: number;
  pageSizeHistory: number;
  totalCountHistory: number;
  tokenBalance: {name: string; symbol: string; balance: string; filePath: string};
}

export const FTViewPage: React.FC<IDerailPageFT> = ({
  tokenAddress,
  pageHistory,
  setPageHistory,
  loadingHistory,
  pageSizeHistory,
  totalCountHistory,
  historyList,
  tokenBalance,
}) => {
  return (
    <Layout>
      <TitleTable>FT Balance</TitleTable>
      <TokenWrapper>
        <TokenName>
          <Avatar
            className={'avatar'}
            size={'46'}
            name={tokenBalance.name}
            src={tokenBalance.filePath ? `${WATERFALL_SERVER}${tokenBalance.filePath}` : '#'}
            round="50%"
          />
          <TokenText>{tokenBalance.name}</TokenText>
          <CopyIcon color={'secondary'} dataToCopy={tokenAddress}>
            <Subtitle font={'secondary'}>Copy address</Subtitle>
          </CopyIcon>
        </TokenName>
        <TokenBalance>
          <TotalText>total</TotalText>
          <BalanceText>
            {tokenBalance.balance} {tokenBalance.symbol}
          </BalanceText>
          <SendWrapper>
            <FTSendButton name={tokenBalance.name} tokenAddress={tokenAddress} />
          </SendWrapper>
        </TokenBalance>
      </TokenWrapper>
      <HistoryWrapperTable>
        <TransactionFTList
          loadingHistory={loadingHistory}
          historyList={historyList}
          pageHistory={pageHistory}
          setPageHistory={setPageHistory}
          pageSize={pageSizeHistory}
          totalCount={totalCountHistory}
        />
      </HistoryWrapperTable>
    </Layout>
  );
};
