import React from 'react';
import {FooterWrapper} from './styles';
import {Text} from '../../ui-kit/Typography/Typography';
export const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Text color="gray">Waterfall 2022</Text>
      <Text color="gray">Copyright © 2022</Text>
    </FooterWrapper>
  );
};
