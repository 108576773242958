export const route = {
  loginPage: '/login',
  homePage: '/',
  addValidator: '/validators/add',
  assets: '/assets',
  tokenList: '/assets/:type',
  viewPage: '/assets/:type/:address',
  mint: '/assets/nft/:address/mint',
  contracts: '/contracts',
  seedTheNetwork: '/seed-the-network',
  kycVerification: '/kyc/verification*',
  contacts: '/contacts',
  invoices: '/invoices',
  earn: '/earn',
};
