import React from 'react';
import {StyledSvgIconProps} from './types';
import {
  AWS,
  AwsOff,
  AlibabaOff,
  DigitalOff,
  Digital,
  HcloudOff,
  Hcloud,
  GcloudOff,
  Gcloud,
  Alibaba,
  ArrowLeft,
  Lock,
  Stripe,
  DownArrow,
  DownArrowActive,
  Visa,
  AmericanExpress,
  MasterCard,
  Logo,
  CopyIcon,
  ArrowLeftTwo,
  ArrowRight,
  ArrowRightLong,
  ArrowIncome,
  ArrowOutcome,
  Pending,
  Done,
  Search,
  BigPlus,
  Sandwich,
  BigMinus,
} from './paths';

export const SvgChildren: React.FC<StyledSvgIconProps> = ({type}) => {
  if (type === 'aws') {
    return <AWS />;
  }
  if (type === 'awsOff') {
    return <AwsOff />;
  }
  if (type === 'hcloud') {
    return <Hcloud />;
  }
  if (type === 'hcloudOff') {
    return <HcloudOff />;
  }
  if (type === 'gcloud') {
    return <Gcloud />;
  }
  if (type === 'gcloudOff') {
    return <GcloudOff />;
  }
  if (type === 'digital') {
    return <Digital />;
  }
  if (type === 'digitalOff') {
    return <DigitalOff />;
  }
  if (type === 'alibaba') {
    return <Alibaba />;
  }
  if (type === 'alibabaOff') {
    return <AlibabaOff />;
  }
  if (type === 'arrowLeft') {
    return <ArrowLeft />;
  }
  if (type === 'arrowRightLong') {
    return <ArrowRightLong />;
  }
  if (type === 'arrowIncome') {
    return <ArrowIncome />;
  }
  if (type === 'arrowOutcome') {
    return <ArrowOutcome />;
  }
  if (type === 'lock') {
    return <Lock />;
  }
  if (type === 'stripe') {
    return <Stripe />;
  }
  if (type === 'downArrow') {
    return <DownArrow />;
  }
  if (type === 'downArrowActive') {
    return <DownArrowActive />;
  }
  if (type === 'visa') {
    return <Visa />;
  }
  if (type === 'amex') {
    return <AmericanExpress />;
  }
  if (type === 'mastercard') {
    return <MasterCard />;
  }
  if (type === 'logo') {
    return <Logo />;
  }
  if (type === 'copy') {
    return <CopyIcon />;
  }
  if (type === 'arrowLeft2') {
    return <ArrowLeftTwo />;
  }
  if (type === 'arrowRight') {
    return <ArrowRight />;
  }
  if (type === 'pending') {
    return <Pending />;
  }
  if (type === 'done') {
    return <Done />;
  }
  if (type === 'search') {
    return <Search />;
  }
  if (type === 'sandwich') {
    return <Sandwich />;
  }
  if (type === 'bigPlus') {
    return <BigPlus />;
  }
  if (type === 'bigMinus') {
    return <BigMinus />;
  }
  return null;
};
