import styled, {css} from 'styled-components';
interface WrapperType {
  isRightSideBar: boolean;
}
const flexDirection = ({isRightSideBar}: WrapperType) => {
  if (isRightSideBar) {
    return 'row';
  }
  return 'column';
};

const alignItems = ({isRightSideBar}: WrapperType) => {
  if (isRightSideBar) {
    return 'flex-start';
  }
  return 'center';
};

const padding = () => {
  return '0 160px';
};

const styles = css`
  display: flex;
  flex-direction: ${flexDirection};
  align-items: ${alignItems};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  background-color: #16171c;
  padding: ${padding};
  @media (max-width: 1550px) {
    padding: 0 60px;
  }
  @media (max-width: 1100px) {
    overflow-x: scroll;
  }
  @media (max-width: 1300px) {
    padding: 0 30px 0 30px;
  }
  & > div {
    padding: 0;
  }
  z-index: 1;
`;

export const Wrapper = styled.div<WrapperType>`
  ${styles}
`;

export const ColumnWrapper = styled.div`
  min-width: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: 25px;
  margin-bottom: 25px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  box-sizing: border-box;
  background-color: #16171c;
  z-index: 1;
`;
