import styled from 'styled-components';
import {theme} from '../../../ui-kit/theme';
import {Text, Title} from '../../../ui-kit/Typography/Typography';

export const HistoryWrapperTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  position: relative;
  top: -5px;
  z-index: 1;
`;

export const TokenWrapper = styled.div`
  min-width: 920px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: ${theme.pallet.common.grey5};
  height: 90px;
  position: relative;
  z-index: 2;
`;

export const TokenName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 60px;
  align-items: center;

  .avatar {
    margin-right: 36px;
    span {
      color: transparent;
    }
  }
`;

export const TokenBalance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
`;

export const SendWrapper = styled.div`
  min-width: 160px;
  padding-left: 160px;
`;

export const TotalText = styled(Title).attrs({
  level: 2,
  color: 'gray',
})`
  padding-top: 1px;
`;

export const BalanceText = styled(Title).attrs({
  level: 2,
  strong: true,
})``;

export const TokenText = styled(Text).attrs({
  strong: true,
})``;

export const TitleTable = styled(Text).attrs({
  strong: true,
  level: 2,
})`
  margin-top: 64px;
  width: 100%;
`;
