import styled from 'styled-components';
import {theme} from '../../theme';

export const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const Icon = styled.svg`
  stroke-width: 1px;
`;

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  width: 20px;
  height: 20px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div<{$isChecked: boolean; size?: number}>`
  width: 22px;
  height: 22px;
  background-color: ${(props) => (props.$isChecked ? theme.pallet.common.blue : 'none')};
  border: 1px solid ${theme.pallet.common.blue};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
