import {gql} from '@apollo/client';

import {Session} from '../types/auth';
import {User} from '../types/user';
import {Session as SessionSign} from './fragments/session';
import {User as UserState} from './fragments/user';

export interface AuthWithResponseType {
  logInWith: {
    viewer: {
      sessionToken: string;
      user: User;
    };
  };
}

export interface AuthWithRequestType {
  authData: {
    metamask: {
      sign: string;
      id: string;
    };
  };
}
export interface createAuthMMResponseType {
  data: {
    getAuthId: {
      token: string;
    };
  };
}

export interface createAuthMMRequestType {
  variables: {
    address: string;
  };
}

export interface SignOutResponseType {
  logOut: {
    ok: boolean;
  };
}

export const createAuthMetamask = gql`
  mutation createAuthMetamask($address: String) {
    getAuthId(input: {address: $address}) {
      token
    }
  }
`;

export const loginWithMetaMask = gql`
  mutation loginInWith($authData: Object!) {
    logInWith(input: {authData: $authData}) {
      viewer {
        ...SessionFragment
      }
    }
  }
  ${SessionSign}
`;

export interface ViewerResponseType {
  viewer: Session;
}

export interface UserResponseType {
  user: User;
}

export const Viewer = gql`
  query viewer {
    viewer {
      ...SessionFragment
    }
  }
  ${SessionSign}
`;

export const SignOut = gql`
  mutation logOut {
    logOut(input: {clientMutationId: "logOut"}) {
      ok
    }
  }
`;

export const GetUser = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserState}
`;
