import React from 'react';
import {useAddToken, useCreateToken, useGetBalanceTokens, useHistoryByTokenType} from '../../hooks/tokens';
import {Assets} from '../../components/Assets';
import {useParams} from 'react-router-dom';
import {TokenTypes} from '../../types/tokens';
import {useHistory} from 'react-router';

export const AssetsContainer = () => {
  const {type} = useParams<{type: TokenTypes}>();
  const {
    tokensList,
    loading,
    setPage: setPageBalance,
    page,
    total,
    pageSizeBalance,
  } = useGetBalanceTokens(type || TokenTypes.FT);
  const {
    historyList,
    setPageHistory,
    pageHistory,
    pageSize,
    totalCount,
    loading: loadingHistory,
  } = useHistoryByTokenType(type || TokenTypes.FT);
  const {onCreate, loading: statusCreate, error: errorCreate} = useCreateToken();
  const {onAdd, loading: statusAdd, error: errorAdd} = useAddToken();
  const {push} = useHistory();

  const detailPageForward = (index: number) => {
    push(`/assets/${type || TokenTypes.FT}/${tokensList[index - (page - 1) * pageSizeBalance - 1]?.actions}`);
  };

  return (
    <Assets
      detailPageForward={detailPageForward}
      statusAdd={statusAdd}
      onAdd={onAdd}
      errorAdd={errorAdd}
      onCreate={onCreate}
      statusCreate={statusCreate}
      errorCreate={errorCreate}
      type={type ? type : TokenTypes.FT}
      loadingHistory={loadingHistory}
      pageSizeBalance={pageSizeBalance}
      balanceList={tokensList}
      totalCountBalance={total}
      loadingBalance={loading}
      setPageBalance={setPageBalance}
      pageBalance={page}
      historyList={historyList}
      setPageHistory={setPageHistory}
      pageHistory={pageHistory}
      pageSizeHistory={pageSize}
      totalCountHistory={totalCount}
    />
  );
};
