import styled from 'styled-components';
import {theme} from '../../ui-kit/theme';

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background: ${theme.pallet.background.denary};
  border-radius: 10px;
  width: 639px;
  height: 409px;
  padding: 81px 149px 93px 149px !important;
`;

export const Logo = styled.img`
  width: 207px;
  height: 41px;
  margin-bottom: 90px;
  margin-top: 99px;
`;

export const Metamask = styled.img`
  margin-bottom: 30px;
`;
