import React, {forwardRef} from 'react';
import DateBase from 'react-datepicker';
import {Input, StyledCalendar} from './styles';
import {Subtitle} from '../Typography/Typography';

interface IProps {
  onChange: (date: Date | null) => void;
  date: Date | undefined | null;
}

export const DatePicker: React.FC<IProps> = ({onChange, date}) => {
  const CustomInput = forwardRef<any, any>(({value, onClick}, ref) => (
    <Input className="example-custom-input" onClick={onClick} ref={ref}>
      <Subtitle color={'white'} level={1}>
        {value}
      </Subtitle>
    </Input>
  ));
  CustomInput.displayName = 'CustomInput';
  return (
    <StyledCalendar>
      <DateBase onChange={onChange} selected={date} enableTabLoop={false} customInput={<CustomInput />} />
    </StyledCalendar>
  );
};
