import {atom, selectorFamily} from 'recoil';
import {ContactType} from '../types/contacts';

export const contactsState = atom<ContactType[]>({
  key: 'contactsState',
  default: [],
});

export const getFilteredContacts = selectorFamily<ContactType[], string>({
  key: 'getFilteredContacts',
  get:
    (filter) =>
    ({get}) => {
      const contactsList = get(contactsState);
      if (!filter) {
        return contactsList;
      }
      return contactsList.filter(
        (f) =>
          f.name.toLowerCase().includes(filter.toLowerCase()) || f.address.toLowerCase().includes(filter.toLowerCase()),
      );
    },
});

export const getContactName = selectorFamily<string | undefined, string>({
  key: 'getContactName',
  get:
    (address) =>
    ({get}) => {
      const contactsList = get(contactsState);
      address = address.toLowerCase();
      return contactsList.find((f) => f.address === address)?.name;
    },
});
