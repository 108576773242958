import {gql} from '@apollo/client';

export interface GetKycTokenResponse {
  geKYCToken: {
    token: string;
  };
}

export const getKYCToken = gql`
  mutation geKYCToken {
    geKYCToken {
      token
    }
  }
`;
