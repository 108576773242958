import styled from 'styled-components';
import {theme} from '../theme';

export const SwitchWrapper = styled.div`
  width: 120px;
  height: 35px;
  background-color: ${theme.pallet.background.primary};
  display: flex;
  position: relative;
  border-radius: 30px;
`;

export const SwitchBall = styled.div<{$checked: boolean}>`
  position: absolute;
  height: 35px;
  width: 35px;
  display: block;
  border-radius: 30px;
  background-color: ${({$checked}) =>
    $checked ? `${theme.pallet.common.blueLight2}` : `${theme.pallet.common.grey7}`};
  transition: all 0.3s ease;
  transform: ${({$checked}) => ($checked ? 'translateX(calc(65px - 14px))' : 'none')};
`;
