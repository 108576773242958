import React from 'react';
import {ButtonModal, CardLine, EnterCardWrapper, SecondaryDataWrap} from './styles';
import {Input} from '../../ui-kit/Form/Input';
import {CardFormType} from '../../types/card';
import {IconSvg} from '../../ui-kit/Icons/Svg';

interface AddCardProps {
  setCardData: React.Dispatch<React.SetStateAction<CardFormType>>;
  cardData: CardFormType;
  submit: () => void;
}

const CardAssets: {[name: string]: React.ReactElement<any, any> | null} = {
  visa: IconSvg({type: 'visa', width: '38', height: '38', viewBox: '0 0 48 48'}),
  mastercard: IconSvg({type: 'mastercard', width: '38', height: '38', viewBox: '0 0 48 48'}),
  amex: IconSvg({type: 'amex', width: '38', height: '38', viewBox: '0 0 48 48'}),
};
const AddCard: React.FC<AddCardProps> = ({cardData, setCardData, submit}) => {
  const setValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardData((prevState) => {
      return {
        ...prevState,
        [e?.target?.name]: {value: e?.target?.value, error: ''},
      };
    });
  };
  const creditTypeChanged = (type: string) => {
    setCardData((prevState) => {
      return {
        ...prevState,
        cardBrand: type,
      };
    });
  };
  return (
    <>
      <EnterCardWrapper>
        <Input
          name={'cardName'}
          sizetype={'middle'}
          placeholdertype={'primary'}
          label={'Card Holder'}
          onChange={setValues}
          value={cardData.cardName.value}
          errorMessage={cardData.cardName.error}
          status={cardData.cardName.error?.length > 0 ? 'error' : undefined}
          placeholder={'John Jackson'}
        />
      </EnterCardWrapper>
      <EnterCardWrapper>
        <Input
          name={'cardNumber'}
          sizetype={'middle'}
          placeholdertype={'primary'}
          label={'Card Number'}
          onChange={setValues}
          value={cardData.cardNumber.value}
          placeholder={'card'}
          options={{
            creditCard: true,
            onCreditCardTypeChanged: creditTypeChanged,
            delimiter: '     ',
          }}
          errorMessage={cardData.cardNumber.error}
          status={cardData.cardNumber.error?.length > 0 ? 'error' : undefined}
          addonBefore={<CardLine />}
          addonAfter={cardData?.cardBrand !== 'unknown' ? CardAssets[cardData?.cardBrand] : null}
        />
      </EnterCardWrapper>
      <SecondaryDataWrap>
        <Input
          name={'date'}
          sizetype={'middle'}
          placeholdertype={'primary'}
          aligntext={'center'}
          label={'Expire Date'}
          onChange={setValues}
          value={cardData.date.value}
          placeholder={'mm/yy'}
          errorMessage={cardData.date.error}
          status={cardData.date.error?.length > 0 ? 'error' : undefined}
          options={{
            date: true,
            datePattern: ['m', 'y'],
          }}
        />
        <Input
          type={'password'}
          name={'cvcCode'}
          sizetype={'middle'}
          placeholdertype={'primary'}
          aligntext={'center'}
          label={'CVC'}
          errorMessage={cardData.cvcCode.error}
          status={cardData.cvcCode.error?.length > 0 ? 'error' : undefined}
          onChange={setValues}
          value={cardData.cvcCode.value}
          placeholder={'....'}
        />
      </SecondaryDataWrap>
      <ButtonModal type={'primary'} onClick={submit}>
        Continue
      </ButtonModal>
    </>
  );
};

export default AddCard;
