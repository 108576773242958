import React, {Dispatch, SetStateAction} from 'react';
import {AsideWrapper, BalanceWrapper, BalanceWrapperTable, HistoryTitle, HistoryWrapperTable} from './styles';
import {FTList} from './FTList';
import {IBalance, IOptions, TokenTypes, TransactionTable} from '../../types/tokens';
import {TransactionFTList} from './TransactionsList/TransactionFTList';
import {Layout} from '../../ui-kit/Layout';
import FTCreateForm from './FTCreateForm';
import {NFTCreateForm} from './NFTCreateForm';
import {AddForm} from './AddForm';
import {TabPane, Tabs} from '../../ui-kit/Tabs';
import {route} from '../../constants/route';
import {NFTList} from './NFTList';
import {TransactionNFTList} from './TransactionsList/TransactionNFTList';

interface ITokenBalance {
  pageSizeBalance: number;
  balanceList: IBalance[];
  loadingBalance: boolean;
  pageBalance: number;
  setPageBalance: Dispatch<SetStateAction<number>>;
  totalCountBalance: number;
  detailPageForward: (index: number) => void;

  historyList: TransactionTable[];
  setPageHistory: Dispatch<SetStateAction<number>>;
  loadingHistory: boolean;
  pageHistory: number;
  pageSizeHistory: number;
  totalCountHistory: number;
  type: TokenTypes;

  onCreate: (fields: IOptions) => Promise<boolean>;
  statusCreate: boolean;
  errorCreate: string;

  onAdd: (tokenID: string) => Promise<boolean>;
  statusAdd: boolean;
  errorAdd: string;
}

export const Assets: React.FC<ITokenBalance> = ({
  balanceList,
  loadingBalance,
  pageBalance,
  pageSizeBalance,
  pageSizeHistory,
  pageHistory,
  setPageHistory,
  setPageBalance,
  totalCountBalance,
  totalCountHistory,
  historyList,
  loadingHistory,
  type,
  onCreate,
  errorCreate,
  statusCreate,
  errorAdd,
  statusAdd,
  onAdd,
  detailPageForward,
}) => {
  const activeTab = type.toUpperCase();

  return (
    <Layout
      RightSideBar={
        <AsideWrapper type={type}>
          {type === TokenTypes.FT ? (
            <FTCreateForm onCreate={onCreate} status={statusCreate} error={errorCreate} />
          ) : (
            <NFTCreateForm onCreate={onCreate} status={statusCreate} error={errorCreate} />
          )}
          <AddForm onAdd={onAdd} transactionStatus={statusAdd} error={errorAdd} />
        </AsideWrapper>
      }>
      <BalanceWrapper>
        <Tabs defaultActiveKey={activeTab}>
          <TabPane to={route.assets + `/${TokenTypes.FT}`} tab={'FT'}>
            <BalanceWrapperTable>
              <FTList
                detailPageForward={detailPageForward}
                loadingBalance={loadingBalance}
                balanceList={balanceList}
                pageBalance={pageBalance}
                setPageBalance={setPageBalance}
                pageSizeBalance={pageSizeBalance}
                totalCountBalance={totalCountBalance}
              />
            </BalanceWrapperTable>
            <HistoryWrapperTable>
              <HistoryTitle>FT History</HistoryTitle>
              <TransactionFTList
                type={type}
                loadingHistory={loadingHistory}
                historyList={historyList}
                pageHistory={pageHistory}
                setPageHistory={setPageHistory}
                pageSize={pageSizeHistory}
                totalCount={totalCountHistory}
              />
            </HistoryWrapperTable>
          </TabPane>
          <TabPane to={route.assets + `/${TokenTypes.NFT}`} tab={'NFT'}>
            <BalanceWrapperTable>
              <NFTList
                detailPageForward={detailPageForward}
                loadingBalance={loadingBalance}
                balanceList={balanceList}
                pageBalance={pageBalance}
                setPageBalance={setPageBalance}
                pageSizeBalance={pageSizeBalance}
                totalCountBalance={totalCountBalance}
              />
            </BalanceWrapperTable>
            <HistoryWrapperTable>
              <HistoryTitle>NFT History</HistoryTitle>
              <TransactionNFTList
                type={type}
                loadingHistory={loadingHistory}
                historyList={historyList}
                pageHistory={pageHistory}
                setPageHistory={setPageHistory}
                pageSize={pageSizeHistory}
                totalCount={totalCountHistory}
              />
            </HistoryWrapperTable>
          </TabPane>
        </Tabs>
      </BalanceWrapper>
    </Layout>
  );
};
