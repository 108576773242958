import React, {useState} from 'react';
import {Table} from '../../../ui-kit/Table';
import {HiddenContentType, Columns} from '../../../ui-kit/Table/types';
import {InstanceType, ValidatorType} from '../../../types/validator';

interface Props {
  data: InstanceType<ValidatorType[]>[];
  columns: Columns<InstanceType<ValidatorType[]>>;
  hiddenContent: HiddenContentType[];
  loading: boolean;
  paginator?: {
    current: number;
    pageSize: number;
    total: number;
    handlePage: React.Dispatch<React.SetStateAction<number>>;
  };
  emptyMessage?: string;
}

const List: React.FC<Props> = ({data, columns, loading, hiddenContent, paginator, emptyMessage}) => {
  const [selectItem, setSelectItem] = useState<null | number>(null);
  const handlerChange = (index: number) => () => {
    if (selectItem === index) {
      setSelectItem(null);
      return;
    }
    setSelectItem(index);
  };
  return (
    <>
      <Table<InstanceType<ValidatorType[]>>
        type={'custom'}
        dataSource={data}
        columns={columns}
        loading={loading}
        emptyMessage={emptyMessage ? emptyMessage : 'Your validators will show up here'}
        pagination={paginator}
        rowSelection={{setItem: handlerChange, selectItemIndex: selectItem, hiddenContent}}
      />
    </>
  );
};

export default List;
