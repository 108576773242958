import React, {HTMLAttributeAnchorTarget, PropsWithChildren} from 'react';

import {ButtonColor, ButtonProps, ButtonSize, ButtonVariant} from './types';
import {Link, LinkRoute} from './styles';
import {Button as ButtonBase} from './styles';

// eslint-disable-next-line @typescript-eslint/ban-types
type $object = object;

// export type Diff<U1 extends any, U2 extends any> = Exclude<U1, U2> | Exclude<U2, U1>;

type Override<T extends $object, O extends $object> = {[K in Exclude<keyof O, keyof T>]?: O[K]} & {
  [K in Extract<keyof T, keyof O>]?: O[K];
};

type ButtonBaseProps<T extends $object> = {
  type?: ButtonVariant;
  size?: ButtonSize;
  color?: ButtonColor;
  href?: string;
  to?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
} & Override<ButtonProps, T>;

export const LinkButton = <T extends $object>({
  children,
  type,
  size,
  color,
  href,
  to,
  target,
  ...props
}: PropsWithChildren<ButtonBaseProps<T>>) => {
  return (
    <ButtonBase variant={type} size={size} color={color} {...props}>
      {href && <Link href={href} target={target} />}
      {to && <LinkRoute to={to} />}
      {children}
    </ButtonBase>
  );
};
