import {gql} from '@apollo/client';

export const User = gql`
  fragment UserFragment on User {
    id
    username
    address
    email
    emailVerified
    objectId
    status
    createdAt
    updatedAt
    sessionId
    isVerified
    verificationDate
    kycData
  }
`;
