import React, {useEffect, useState} from 'react';
import {DataCenterType, InstanceType, UserCardType, ValidatorType, ZoneType} from '../types/validator';
import {
  getInstanceStatus,
  getValidatorStatus,
  validatesName,
  validateZipPostalCode,
  validationCardNumber,
  validationExpDate,
  ZIP_CODE_REGEX,
} from '../helpers/validations';
import {useMutation, useQuery} from '@apollo/client';
import {
  AddValidationRequest,
  AddValidationResponse,
  getInstance,
  GetInstanceRequest,
  GetInstanceResponse,
  getInstances,
  GetInstancesRequest,
  GetInstancesResponse,
  getPayments,
  GetPaymentsRequest,
  GetPaymentsResponse,
  getRates,
  GetRatesResponse,
  getValidatorPayments,
  GetValidatorPaymentsRequest,
  GetValidatorPaymentsResponse,
  PaymentsData,
  repaySubscription,
  RepaySubscriptionRequest,
  RepaySubscriptionResponse,
  validator,
  ValidatorTypeQuery,
} from '../queries/validator';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {user} from '../states/session';
import {useHistory} from 'react-router';
import {useMetaMask} from 'metamask-react';
import {RPC_URL} from '../constants/env';
import {route} from '../constants/route';
import {getConfirmation, transfer, web3} from './metamask';
import {CardFormType} from '../types/card';
import {GQLInstanceOrder} from '../graphql.schema';
import {END_DATES_VALIDATOR, PAGE_SIZE} from '../constants/data';
import {addMonthToDate} from '../helpers/date';
import {Viewer as ViewerQuery, ViewerResponseType} from '../queries/auth';
import {client} from '../ApolloProvider';
import ABI from '../constants/usdtabi.json';
import Web3 from 'web3';
import {formatUserBalance} from '../helpers/format';
import {useUserCards} from './user';

export interface AddValidatorState {
  dataCenter: string;
  region: string;
  zone: string;
  cardData: CardFormType;
  cardType: string;
  checkTerms: boolean;
  paymentMethod: string;
}

export type ActionsType =
  | 'chooseDataCenter'
  | 'setCardData'
  | 'chooseRegionAndZone'
  | 'checkTerms'
  | 'selectCard'
  | 'setTypeCard'
  | 'setPaymentMethod';
export interface Action {
  (type: 'chooseRegionAndZone' | 'selectCard' | 'setPaymentMethod'): (arg1: string, arg2: string) => void;
  (type: 'checkTerms' | 'setCardData'): (e: React.ChangeEvent<HTMLInputElement>) => void;
  (type: 'setTypeCard' | 'chooseDataCenter'): (arg1: string) => void;
}

export const useAddValidation = () => {
  const userState = useRecoilValue(user);
  const setUserState = useSetRecoilState(user);
  const history = useHistory();
  const {connect, switchChain, chainId, addChain, status} = useMetaMask();
  const [values, setValues] = useState<AddValidatorState>({
    dataCenter: 'aws',
    region: '',
    zone: '',
    cardData: {
      id: '',
      cardName: {value: '', error: ''},
      cardNumber: {value: '', error: ''},
      cardBrand: '',
      cvcCode: {value: '', error: ''},
      date: {value: '', error: ''},
    },
    checkTerms: false,
    cardType: 'unknown',
    paymentMethod: '',
  });
  const [step, setStep] = useState<number>(1);
  const [cardList, setCardList] = useState<UserCardType[] | null>(null);
  const [zoneList, setZoneList] = useState<ZoneType[] | null>(null);
  const [paymentList, setPaymentList] = useState<PaymentsData[] | null>(null);
  const [dataCenters, setDataCenters] = useState<DataCenterType[] | null>(null);
  const [stepValidation, setStepValidation] = useState<boolean>(false);
  const [getDataCenters] = useMutation<GetRatesResponse>(getRates);
  const {getCards} = useUserCards();
  const [addValidationRequest] = useMutation<AddValidationResponse, AddValidationRequest>(validator);
  const [requestTrigger, setRequestTrigger] = useState<boolean>(false);
  const [transactionSendStatus, setTransactionStatus] = useState<boolean>(false);
  const [getPaymentsData] = useMutation<GetPaymentsResponse, GetPaymentsRequest>(getPayments);

  const validationList: {[name: number]: () => boolean} = {
    1: () => {
      return Boolean(values.dataCenter);
    },
    2: () => {
      return values.region.length > 0 && values.zone.length > 0;
    },
    3: () => {
      return (
        values.cardData.id.length > 0 ||
        (validationCardNumber(values.cardData.cardNumber.value, values.cardType).length === 0 &&
          validatesName(values.cardData.cardName.value).length === 0 &&
          validationExpDate(values.cardData.date.value).length === 0 &&
          validateZipPostalCode(values.cardData.cvcCode.value).length === 0)
      );
    },
    4: () => {
      return values.paymentMethod.length > 0;
    },
    5: () => {
      return true;
    },
  };
  const requestRates = async () => {
    try {
      const response = await getDataCenters();
      if (response.data?.getRates.rates) {
        setDataCenters(response.data?.getRates.rates || null);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const requestPaymentsData = async () => {
    try {
      const result = await getPaymentsData({
        variables: {
          datacenter: values?.dataCenter,
          zoneName: values?.zone,
          regionId: dataCenters
            ? dataCenters
                .find((el) => el.datacenter === values?.dataCenter?.toLocaleLowerCase())
                ?.zones?.find((zoneObj) => zoneObj.name === values?.zone)
                ?.regions.find((regionObj) => regionObj.name === values?.region)?.id || ''
            : '',
        },
      });
      if (result?.data?.getPaymentConfig?.config) {
        setPaymentList(result?.data?.getPaymentConfig?.config || null);
        setValues((prevState) => ({
          ...prevState,
          paymentMethod: result?.data?.getPaymentConfig?.config[0]?.value || '',
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const requestUserCards = async () => {
    try {
      const response = await getCards();
      if (response?.cards?.length) {
        setCardList(response?.cards || null);
        if (response.defaultCardId) {
          const defaultCard = response.cards.find((el) => el.id === response.defaultCardId);
          if (defaultCard) {
            setValues((prevState) => ({
              ...prevState,
              cardData: {
                id: defaultCard.id,
                cardBrand: defaultCard.brand,
                cardName: {value: defaultCard.name, error: ''},
                cardNumber: {value: '**** **** **** ' + defaultCard.last4, error: ''},
                cvcCode: {value: '***', error: ''},
                date: {value: defaultCard.exp_month + '/' + defaultCard.exp_year, error: ''},
              },
            }));
          }
        }
      }
    } catch (e) {
      console.log(e);
      setValues((prevState) => ({
        ...prevState,
        cardList: null,
      }));
    }
  };

  const sendRequestAddValidator = async () => {
    if (!transactionSendStatus) {
      setTransactionStatus(true);
      if (values.checkTerms && userState?.address) {
        if (!connect) {
          alert('Please install metamask');
          setTransactionStatus(false);
          return;
        }
        if (status === 'notConnected') {
          await connect();
        }
        const paymentMethod = paymentList?.filter((item) => item.value === values.paymentMethod)?.[0];
        if (paymentMethod?.chainId && chainId !== String(paymentMethod?.chainId || '')) {
          try {
            await switchChain(web3.utils.toHex(String(paymentMethod?.chainId || '')));
          } catch {
            try {
              await addChain({
                chainId: web3.utils.toHex(String(paymentMethod?.chainId || '')),
                chainName: paymentMethod?.rpc,
                nativeCurrency: {symbol: paymentMethod?.symbol, name: paymentMethod?.label, decimals: 18},
                rpcUrls: [paymentMethod?.rpc],
              });
              await switchChain(web3.utils.toHex(String(paymentMethod?.chainId || '')));
            } catch (error) {
              console.log(error);
              setTransactionStatus(false);
              return;
            }
          }
        }
        if (!paymentMethod) {
          setTransactionStatus(false);
          return;
        }
        let txHash = null;
        if (!paymentMethod?.contract) {
          try {
            txHash = await transfer(
              userState?.address,
              paymentMethod?.address,
              web3.utils.fromWei(paymentMethod.amount || '0', 'ether'),
            );
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            web3.setProvider(window.ethereum);
            const mainContract = JSON.parse(JSON.stringify(ABI));
            const contract = new web3.eth.Contract(mainContract, paymentMethod.contract);
            const result = new Promise((resolve, reject) => {
              contract.methods.transfer(paymentMethod.address, paymentMethod.amount).send(
                {
                  from: userState?.address,
                },
                async function (err: any, res: string) {
                  if (err) {
                    reject(err);
                  }
                  if (res) {
                    resolve(res);
                  }
                },
              );
            });
            txHash = await result;
          } catch (e) {
            console.log(e);
          } finally {
            web3.setProvider(new Web3.providers.HttpProvider(RPC_URL));
          }
        }
        if (!txHash) {
          setTransactionStatus(false);
          return;
        }
        try {
          const {dataCenter, zone, region, cardData} = values;
          const response = await addValidationRequest({
            variables: {
              datacenter: dataCenter.toLocaleLowerCase(),
              zoneName: zone,
              regionId: dataCenters
                ? dataCenters
                    .find((el) => el.datacenter === dataCenter.toLocaleLowerCase())
                    ?.zones?.find((zoneObj) => zoneObj.name === zone)
                    ?.regions.find((regionObj) => regionObj.name === region)?.id || ''
                : '',
              cardId: cardData.id.length > 0 ? cardData.id : undefined,
              outerId: txHash as string,
              card:
                cardData.id.length > 0
                  ? undefined
                  : {
                      cardNumber: cardData.cardNumber.value,
                      cvc: cardData.cvcCode.value,
                      nameOnCard: cardData.cardName.value,
                      expiryDate: cardData.date.value,
                    },
              paymentMethod: paymentMethod?.value,
            },
          });
          if (response.data?.addValidator) {
            open(response.data?.addValidator.url, '_blank');
            setTransactionStatus(false);
            history.push(route.invoices);
          } else {
            setTransactionStatus(false);
            alert('Something went wrong');
          }
        } catch (e) {
          setTransactionStatus(false);
          console.log(e);
        }
      } else {
        setTransactionStatus(false);
        alert('Please read terms and confirm this');
      }
    }
  };
  const handlerChangeStep = (operation: 'plus' | 'minus' | 'setCurrent') => (currentStep?: number) => {
    if (
      operation === 'setCurrent' &&
      currentStep &&
      validationList[2] &&
      validationList[3] &&
      validationList[4] &&
      validationList[1]
    ) {
      if (currentStep === 1) {
        setStep(currentStep);
      }
      if (currentStep === 2 && validationList[2]() && validationList[1]()) {
        setStep(currentStep);
      }
      if (currentStep === 3 && validationList[2]() && validationList[1]()) {
        setStep(currentStep);
      }
      if (currentStep === 4 && validationList[3]() && validationList[2]() && validationList[1]()) {
        setStep(currentStep);
      }
      if (
        currentStep === 5 &&
        validationList[4]() &&
        validationList[3]() &&
        validationList[2]() &&
        validationList[1]()
      ) {
        setStep(currentStep);
      }
      return;
    }
    setStep(operation === 'plus' ? step + 1 : step - 1);
  };
  function actionsOfAddValidatorState(
    type: 'chooseRegionAndZone' | 'selectCard' | 'setPaymentMethod',
  ): (arg1: string, arg2: string) => void;

  function actionsOfAddValidatorState(
    type: 'checkTerms' | 'setCardData',
  ): (e: React.ChangeEvent<HTMLInputElement>) => void;

  function actionsOfAddValidatorState(type: 'setTypeCard' | 'chooseDataCenter'): (arg1: string) => void;

  function actionsOfAddValidatorState(actionType: ActionsType) {
    switch (actionType) {
      case 'chooseDataCenter':
        return function handlerChooseDataCenter(datacenter: string) {
          setValues((prevState) => ({
            ...prevState,
            dataCenter: datacenter,
          }));
        };
      case 'setCardData':
        return function handlerSetCardData(e: React.ChangeEvent<HTMLInputElement>) {
          switch (e.target.name) {
            case 'cardName':
              setValues((prevState) => ({
                ...prevState,
                cardData: {
                  ...prevState.cardData,
                  cardName: {value: e.target.value, error: validatesName(e.target.value)},
                },
              }));
              return;
            case 'cvcCode':
              if ((ZIP_CODE_REGEX.test(e.target.value) || e.target.value.length === 0) && e.target.value.length <= 4) {
                setValues((prevState) => ({
                  ...prevState,
                  cardData: {
                    ...prevState.cardData,
                    cvcCode: {value: e.target.value, error: validateZipPostalCode(e.target.value)},
                  },
                }));
              }
              return;
            case 'date':
              setValues((prevState) => ({
                ...prevState,
                cardData: {
                  ...prevState.cardData,
                  date: {value: e.target.value, error: validationExpDate(e.target.value)},
                },
              }));
              return;
            case 'cardNumber':
              setValues((prevState) => ({
                ...prevState,
                cardData: {
                  ...prevState.cardData,
                  cardNumber: {
                    value: e.target.value.replaceAll(' ', ''),
                    error: validationCardNumber(e.target.value.replaceAll(' ', ''), values.cardType),
                  },
                },
              }));
              return;
            default:
              setValues((prevState) => ({
                ...prevState,
                cardData: {
                  ...prevState.cardData,
                  [`${e.target.name}`]: {value: e.target.value.replaceAll(' ', ''), error: ''},
                },
              }));
          }
        };
      case 'setPaymentMethod':
        return function handlerChoosePaymentsMethod(_symbol: string, value: string) {
          setValues((prevState) => ({
            ...prevState,
            paymentMethod: value,
          }));
        };
      case 'chooseRegionAndZone':
        return function handlerChooseRegionAndZone(region: string, zone: string) {
          setValues((prevState) => ({...prevState, region: region, zone: zone}));
        };
      case 'checkTerms':
        return function handlerCheckTerms(e: React.ChangeEvent<HTMLInputElement>) {
          setValues((prevState) => ({...prevState, checkTerms: e.target.checked}));
        };
      case 'selectCard':
        return function handlerSelectCard(label: string, cardId: string) {
          if (label === 'Add New card') {
            setValues((prevState) => ({
              ...prevState,
              cardData: {
                id: '',
                cardName: {value: '', error: ''},
                cardBrand: '',
                cardNumber: {value: '', error: ''},
                cvcCode: {value: '', error: ''},
                date: {value: '', error: ''},
              },
            }));
            setValues((prevState) => ({...prevState, stripeMode: false}));
            return;
          }
          setValues((prevState) => ({...prevState, stripeMode: true}));
          const card = cardList?.find((el) => el.id === cardId);
          if (card) {
            setValues((prevState) => ({
              ...prevState,
              cardData: {
                id: card.id,
                cardBrand: card.brand,
                cardName: {
                  value: card.name,
                  error: '',
                },
                cardNumber: {
                  value: '**** **** **** ' + card.last4,
                  error: '',
                },
                cvcCode: {
                  value: '***',
                  error: '',
                },
                date: {
                  value: card.exp_month + '/' + card.exp_year,
                  error: '',
                },
              },
            }));
          }
        };
      case 'setTypeCard':
        return function handlerSetTypeCard(type: string) {
          setValues((prevState) => ({...prevState, cardType: type}));
        };
    }
  }
  useEffect(() => {
    if (!zoneList && step === 1) {
      requestRates();
    }
    if (!cardList && step === 2) {
      requestUserCards();
    }
    if (!paymentList && step === 4) {
      requestPaymentsData();
    }
  }, [step]);
  useEffect(() => {
    if (values.dataCenter) {
      setZoneList(
        dataCenters?.find(
          (el) => el.datacenter.toLocaleLowerCase() === values.dataCenter.replace(' ', '').toLocaleLowerCase(),
        )?.zones || null,
      );
    }
  }, [values.dataCenter, dataCenters]);
  useEffect(() => {
    const statusFunction =
      validationList[step as number] ||
      (() => {
        return false;
      });
    setStepValidation(statusFunction());
  }, [values.dataCenter, values.region, values.zone, step, values.paymentMethod, values.cardData]);
  useEffect(() => {
    let kycTimeout: undefined | NodeJS.Timeout;
    async function checkKys() {
      const result = await client.query<ViewerResponseType>({
        query: ViewerQuery,
        fetchPolicy: 'no-cache',
      });
      const userData = result?.data?.viewer.user;
      if (userData?.isVerified) {
        setUserState(userData);
      }
      setRequestTrigger(!requestTrigger);
    }
    if (!userState?.isVerified) {
      kycTimeout = setTimeout(checkKys, 10000);
    }
    return () => {
      return clearTimeout(kycTimeout);
    };
  }, [requestTrigger]);
  return {
    values,
    sendRequestAddValidator,
    actionsOfAddValidatorState,
    step,
    stepValidation,
    handlerChangeStep,
    userState,
    cardList: cardList
      ? [
          ...cardList.map((el) => ({value: el.id, label: el.brand + ' **** **** **** ' + el.last4})),
          {value: '', label: 'Add New card'},
        ]
      : null,
    zoneData: zoneList
      ? zoneList?.map((el) => ({
          name: el.name,
          options: el.regions.map((region) => ({
            value: region.priceAmount + '$/month',
            label: region.name,
          })),
        }))
      : null,
    paymentList: paymentList,
  };
};

export const useRepaySubscription = () => {
  const [repay] = useMutation<RepaySubscriptionResponse, RepaySubscriptionRequest>(repaySubscription);
  const [loading, setLoading] = useState<boolean>(false);

  const repayUserSubscription = async (subId: string) => {
    if (loading) return;
    setLoading(true);
    const result = await repay({variables: {subId: subId}});
    setLoading(false);
    if (!result?.data?.repaySubscription?.length) return;
    open(result.data?.repaySubscription, '_blank');
  };

  return {
    repayUserSubscription,
  };
};

export const useGetInstances = (limit?: number, location?: string) => {
  const [page, setPage] = useState<number>(1);
  const [instances, setInstances] = useState<InstanceType<ValidatorType[]>[] | null>(null);
  const [loadingRefData, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const {data, fetchMore} = useQuery<GetInstancesResponse, GetInstancesRequest>(getInstances, {
    variables: {
      first: limit || PAGE_SIZE,
      skip: 0,
      order: GQLInstanceOrder.updatedAt_ASC,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const {fetchMore: fetchMorePayments} = useQuery<GetValidatorPaymentsResponse, GetValidatorPaymentsRequest>(
    getValidatorPayments,
    {
      skip: true,
      fetchPolicy: 'network-only',
    },
  );

  const [getPaymentsData] = useMutation<GetPaymentsResponse, GetPaymentsRequest>(getPayments);

  const fetchPaymentsValidator = async (ids?: string[]) => {
    const {data: paymentsValidator} = await fetchMorePayments({
      variables: {
        where: {
          Validators: {have: {id: {in: ids}}},
        },
      },
    });
    return paymentsValidator;
  };

  const getValidatorData = async (
    validator: {node: ValidatorType},
    instanceStatus: string,
    paymentsValidator: GetValidatorPaymentsResponse,
  ) => {
    let paymentMethods: PaymentsData[] = [];
    try {
      const result = await getPaymentsData();
      paymentMethods = result.data?.getPaymentConfig?.config || [];
    } catch (e) {
      console.log(e);
    }
    const payment = paymentsValidator?.validatorPayments?.edges?.find((item) =>
      item?.node?.Validator?.edges?.find((v) => v?.node?.id === validator?.node.id),
    );
    let confirmation = 0;

    if (validator?.node.status === 'new') {
      try {
        const rpc = paymentMethods?.filter((item) => item.value === payment?.node?.network)?.[0]?.rpc || '';
        confirmation = await getConfirmation(payment?.node?.outerId, rpc);
      } catch (e) {
        console.log(e);
      }
    }
    return {
      id: validator?.node?.id,
      type: validator?.node?.type,
      validatorBalance: formatUserBalance(validator?.node?.validatorBalance, false),
      coordinatorBalance: formatUserBalance(validator?.node?.coordinatorBalance, false),
      coordinatorPublicKey: validator?.node?.coordinatorPublicKey,
      validatorPublicKey: validator?.node?.validatorPublicKey,
      confirmations: confirmation,
      status: getValidatorStatus(validator?.node.status, instanceStatus),
      activationDate: validator?.node?.activationDate,
      endStakeDate: validator?.node
        ? addMonthToDate(validator?.node.activationDate, END_DATES_VALIDATOR[validator?.node.type as string])
        : undefined,
      confirmationCount: paymentMethods?.filter((item) => item.value === payment?.node?.network)?.[0]
        ?.countConfirmation,
    };
  };

  const getInstanceData = (instances?: InstanceType<ValidatorTypeQuery>, validators?: ValidatorType[]) => {
    return {
      objectId: instances?.objectId,
      id: instances?.id,
      coordinatorPublicKeys: instances?.Validators?.edges?.some((item) => item?.node?.coordinatorPublicKey)
        ? instances?.Validators?.edges?.map((item) => item.node?.coordinatorPublicKey)?.join(', ')
        : '',
      validatorPublicKeys: instances?.Validators?.edges?.some((item) => item?.node?.validatorPublicKey)
        ? instances?.Validators?.edges?.map((item) => item.node?.validatorPublicKey)?.join(', ')
        : '',
      status: getInstanceStatus(instances?.status, instances?.isSyncing, instances?.Validators?.edges?.length),
      activationDate: validators?.[0]?.activationDate,
      endStakeDate: validators?.[0]?.endStakeDate,
      coordinatorBalanceTotal: String(
        instances?.Validators?.edges?.reduce(
          (acc, item) => acc + Number(formatUserBalance(item?.node?.coordinatorBalance, false) || 0),
          0,
        ),
      ),
      validatorBalanceTotal: String(
        instances?.Validators?.edges?.reduce(
          (acc, item) => acc + Number(formatUserBalance(item?.node?.validatorBalance, false) || 0),
          0,
        ),
      ),
    };
  };

  const newData = async () => {
    const instances = data?.instances?.edges;
    const refactorData: InstanceType<ValidatorType[]>[] = [];
    if (instances?.length) {
      const idsValidator = instances
        ?.map((instance) => instance?.node?.Validators?.edges?.map((validator) => validator?.node?.id || ''))
        .flat() as string[];
      const paymentsValidator = await fetchPaymentsValidator(idsValidator);
      for (let i = 0; i < instances?.length; i++) {
        const validatorsPromise =
          instances?.[i]?.node?.Validators?.edges?.map(async (v) => {
            return await getValidatorData(v, instances?.[i]?.node?.status || '', paymentsValidator);
          }) || [];

        const validators = await Promise.all(validatorsPromise);
        refactorData.push({
          ...getInstanceData(instances?.[i]?.node, validators),
          Validators: validators,
        });
      }

      if (history.location.pathname === location) {
        setLoading(false);
        setInstances(refactorData);
      }
    } else {
      setLoading(false);
      setInstances([]);
    }
  };
  const updateValidatorStatus = async (id: string) => {
    const result = await client.query<GetInstanceResponse, GetInstanceRequest>({
      query: getInstance,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
    if (result.data.instance?.id) {
      const idsValidator = result.data.instance?.Validators?.edges?.map((validator) => validator?.node?.id);
      const paymentsValidator = await fetchPaymentsValidator(idsValidator);
      const updateValidations = instances?.map(async (el) => {
        if (el?.id === id) {
          const validatorsPromise =
            result?.data?.instance?.Validators?.edges?.map(async (validator) => {
              return await getValidatorData(validator, result?.data?.instance?.status || '', paymentsValidator);
            }) || [];
          const validators = await Promise.all(validatorsPromise);
          return {
            ...getInstanceData(result?.data?.instance, validators),
            Validators: validators,
          };
        } else {
          return el;
        }
      }) as InstanceType<ValidatorType[]>[];
      if (updateValidations && history.location.pathname === location) {
        setInstances(await Promise.all(updateValidations));
        const Button = document.getElementById(id);
        if (Button) {
          Button.textContent = 'Update';
        }
      }
    }
  };
  const fetchMoreValidator = async (page: number) => {
    try {
      await fetchMore({
        variables: {
          first: limit || PAGE_SIZE,
          skip: PAGE_SIZE * (page > 0 ? page - 1 : 0),
          order: GQLInstanceOrder.updatedAt_ASC,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          return fetchMoreResult;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMoreValidator(page);
  }, [page]);

  useEffect(() => {
    if (data?.instances.edges?.length) {
      setLoading(true);
      newData();
    } else setLoading(false);
  }, [data?.instances.edges.length]);

  const count = data?.instances.count || 0;
  return {
    data: instances || [],
    count,
    loading: loadingRefData,
    updateValidatorStatus,
    page,
    setPage,
  };
};
