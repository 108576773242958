import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {AuthPageContainer} from './containers/AuthPage';
import {route} from './constants/route';
import {PrivateRoute} from './containers/PrivateRoute';
import {useIsAuthenticated} from './hooks/auth';
import {ValidatorsContainer} from './containers/Validators';
import {useHistory} from 'react-router';
import {AddValidationFormContainer} from './containers/AddValidationForm';
import {HandoverContainer} from './containers/KYC/Handover';
import {ContactsContainer} from './containers/Contacts';
import {AssetsContainer} from './containers/Assets';
import {ViewPageContainer} from './containers/Assets/ViewPage';
import {HomeContainer} from './containers/Home';
import {InvoicesContainer} from './containers/Invoices';
import {TokenMintContainer} from './containers/Assets/TokenMint';

declare global {
  interface Window {
    ethereum: any;
  }
}

const PrivateRouteArr = [
  <PrivateRoute key="validator" path={route.addValidator} component={AddValidationFormContainer} />,
  <PrivateRoute key="earn" path={route.earn} component={ValidatorsContainer} />,
  <PrivateRoute key="contacts" path={route.contacts} component={ContactsContainer} />,
  <PrivateRoute key="assets" exact path={route.assets} component={AssetsContainer} />,
  <PrivateRoute key="tokenList" exact path={route.tokenList} component={AssetsContainer} />,
  <PrivateRoute key="viewPage" exact path={route.viewPage} component={ViewPageContainer} />,
  <PrivateRoute key="invoices" path={route.invoices} component={InvoicesContainer} />,
  <PrivateRoute key="mint" exact path={route.mint} component={TokenMintContainer} />,
  <PrivateRoute key="/" exact path={route.homePage} component={HomeContainer} />,
];

const PublicRoutes = [
  <Route key="login" path="/login" exact component={AuthPageContainer} />,
  <Route key="kycVerification" path={route.kycVerification} exact component={HandoverContainer} />,
];

const Routes: React.FC = () => {
  const isAuth = useIsAuthenticated();
  const history = useHistory();
  useEffect(() => {
    if (isAuth && history.location.pathname === route.loginPage) {
      history.push(route.homePage);
    }
  }, [isAuth]);
  return (
    <Switch>
      {PublicRoutes}
      {PrivateRouteArr}
    </Switch>
  );
};

export default Routes;
