import React from 'react';
import {StepTwoWrapper, FormLine, FormTitle, SelectZoneWrapper, BackButtonWrapper, BackButtonText} from './styles';
import {DropSelectMultiple} from '../../ui-kit/Form/Select/DropDownSelectMultiple';
import {IconSvg} from '../../ui-kit/Icons/Svg';
interface Props {
  dataCenter: string;
  region: string;
  backStep: () => void;
  zoneData: {name: string; options: {value: string; label: string}[]}[] | null;
  chooseRegionAndZone: (arg1: string, arg2: string) => void;
}

export const RegionStep: React.FC<Props> = ({zoneData, chooseRegionAndZone, backStep, region, dataCenter}) => {
  return (
    <StepTwoWrapper>
      <FormTitle>
        Select {dataCenter} zone:
        <BackButtonWrapper onClick={backStep}>
          <IconSvg type="arrowLeft" width="9" height="16" viewBox="0 0 9 16" />
          <BackButtonText>Back</BackButtonText>
        </BackButtonWrapper>
      </FormTitle>
      <FormLine />
      <SelectZoneWrapper>
        {zoneData && (
          <DropSelectMultiple onChange={chooseRegionAndZone} selectOption={region} multipleOption={zoneData} />
        )}
      </SelectZoneWrapper>
    </StepTwoWrapper>
  );
};
