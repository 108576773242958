import styled from 'styled-components';
import {Text} from '../../Typography/Typography';
import {theme} from '../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 212px;
  width: 205px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 135px;
  min-height: 100px;
  margin-bottom: 50px;
`;

export const DropSelectWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 11px;
  height: 70px;
`;

export const DropSelectContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 87px);
  justify-content: space-between;
`;

export const DropValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-transform: capitalize;
`;

export const DropSelectValue = styled(Text).attrs({
  level: 1,
  color: 'gray2',
})`
  line-height: 18px;
`;

export const DropArrowWrapper = styled.div`
  width: 27px;
  height: 18px;
`;

export const DropMenu = styled.div`
  display: flex;
  background: ${theme.pallet.background.octonary};
  border-radius: 0px 0px 10px 10px;
  flex-direction: column;
  width: calc(100% - 98px);
  z-index: 2;
  position: absolute;
  top: 67px;
  left: 0;
  padding: 35px 49px 13px 49px;
`;

export const DropMenuItem = styled(Text).attrs((props) => ({
  level: 1,
  color: props.color,
}))`
  z-index: 2;
  margin-bottom: 15px;
  line-height: 26px;
  cursor: pointer;
  text-transform: capitalize;
`;
