export type RegionType = {
  id: string;
  name: string;
  productId: string;
  priceId: string;
  priceAmount: number;
};

export interface ZoneType {
  name: string;
  regions: RegionType[];
}

export interface DataCenterType {
  datacenter: string;
  zones?: ZoneType[];
}

export interface UserCardType {
  id: string;
  last4: string;
  cvc_check: string;
  exp_month: number;
  brand: string;
  exp_year: number;
  name: string;
}

export interface ValidatorType {
  id: string;
  validatorBalance?: string;
  coordinatorBalance?: string;
  activationDate?: Date;
  endStakeDate?: Date;
  type?: string;
  confirmations?: number;
  confirmationCount?: number;
  validatorPublicKey?: string;
  coordinatorPublicKey?: string;
  status: string;
  action?: boolean;
}

export interface InstanceType<T> {
  id?: string;
  objectId?: string;
  validatorPublicKeys?: string;
  coordinatorPublicKeys?: string;
  validatorBalanceTotal?: string;
  coordinatorBalanceTotal?: string;
  activationDate?: Date;
  endStakeDate?: Date;
  status?: string;
  action?: boolean;
  isSyncing?: boolean;
  Validators?: T;
}

export interface ValidatorPaymentType {
  id: string;
  network: string;
  outerId: string;
  amount: string | number;
  Validator: {
    edges: [
      {
        node: {
          id: string;
        };
      },
    ];
  };
}

export interface InitializationValidatorType {
  hash: string;
}

export enum PaymentStatus {
  uncollectible = 'uncollectible',
  cancelled = 'cancelled',
  paid = 'paid',
  open = 'open',
}
