import React, {useState} from 'react';
import {User} from '../../types/user';
import {checkVerificationTimeout} from '../../helpers/date';
import {FormWrapper, KycContentWrapper, ValidationButtonWrapper} from './styles';
import {Text} from '../../ui-kit/Typography/Typography';
import {Button} from '../../ui-kit/Buttons/Button';
import {LinkButton} from '../../ui-kit/Buttons/LinkButton';
import {route} from '../../constants/route';
import {KYCContainer} from '../../containers/KYC';

interface Props {
  userState: User;
}

export const KysNotification: React.FC<Props> = ({userState}) => {
  const [validationModal, setValidationModal] = useState<boolean>(false);
  const handlerChangeModalStatus = async () => {
    setValidationModal(!validationModal);
  };
  return (
    <>
      {(validationModal && (
        <KYCContainer handlerChangeModalStatus={handlerChangeModalStatus} status={validationModal} />
      )) || (
        <FormWrapper>
          {userState.sessionId && !userState.verificationDate ? (
            <KycContentWrapper>
              <Text>Wait for the end of the document verification process</Text>
              <ValidationButtonWrapper>
                <LinkButton to={route.homePage} type="primary">
                  Back to General Page
                </LinkButton>
              </ValidationButtonWrapper>
            </KycContentWrapper>
          ) : !userState.verificationDate ||
            (userState.verificationDate && checkVerificationTimeout(userState.verificationDate)) ? (
            <KycContentWrapper>
              <Text>To add your own validators, please go through the verification procedure</Text>
              <ValidationButtonWrapper>
                <Button type="primary" onClick={handlerChangeModalStatus}>
                  Start the validation process
                </Button>
              </ValidationButtonWrapper>
            </KycContentWrapper>
          ) : (
            <KycContentWrapper>
              <Text>Sorry, requested verification failed, please try again in 24 hours</Text>
              <ValidationButtonWrapper>
                <LinkButton to={route.homePage} type="primary">
                  Back to General Page
                </LinkButton>
              </ValidationButtonWrapper>
            </KycContentWrapper>
          )}
        </FormWrapper>
      )}
    </>
  );
};
