import {gql} from '@apollo/client';

export const Card = gql`
  fragment CardFragment on CardInfoPayload {
    id
    last4
    cvc_check
    exp_month
    exp_year
    brand
    name
  }
`;
