import styled from 'styled-components';
import {theme} from '../theme';

export const SeparateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 36px;
  & > div {
    background-color: ${(props) => (props.theme.select ? '#1374E3' : '#859ab2')};
  }
`;

export const Circle = styled.div`
  width: ${(props) => (props.theme.type === 'primary' ? '6px' : '5px')};
  height: ${(props) => (props.theme.type === 'primary' ? '6px' : '5px')};
  border-radius: 50%;
`;

export const CopyIconWrapper = styled.div<{color?: 'primary' | 'secondary'}>`
  cursor: pointer;
  height: 18px;
  width: 18px;
  &:active {
    transform: translateY(4px);
  }
  svg {
    fill: ${({color}) => (color === 'secondary' ? theme.pallet.common.grey7 : theme.pallet.common.white)};
  }
`;

export const ContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;
