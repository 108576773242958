import React, {Dispatch, SetStateAction} from 'react';
import {
  ButtonsWrapper,
  DescrText,
  DetailedWrapper,
  ImageDetailed,
  ModalCollectionWrapper,
  TokenDescription,
} from './styles';
import {NFTSendButton} from '../NFTTokenSend';
import {NFTBurnButton} from '../../../containers/Assets/TokenBurn';
import {TransactionNFTList} from '../TransactionsList/TransactionNFTList';
import {ModalWindow} from '../../../ui-kit/Modal';
import {CollectionItemType} from '../../../types/transaction';
import {TransactionTable} from '../../../types/tokens';
import noImage from './images/noImage.jpg';
interface IProps {
  data: CollectionItemType;
  tokenAddress: string;
  closeModalWindow: () => void;

  historyList: TransactionTable[];
  setPageHistory: Dispatch<SetStateAction<number>>;
  pageHistory: number;
  pageSize: number;
  totalCount: number;
  loadingHistory: boolean;
}

const CollectionItemDetails: React.FC<IProps> = ({
  data,
  closeModalWindow,
  tokenAddress,
  loadingHistory,
  pageHistory,
  setPageHistory,
  historyList,
  totalCount,
  pageSize,
}) => {
  return (
    <ModalWindow maxWidth={1056} visible={true} title={data.name || 'No Name'} onClose={closeModalWindow}>
      <ModalCollectionWrapper>
        <DetailedWrapper>
          <ImageDetailed src={data.image || noImage} />
        </DetailedWrapper>
        <TokenDescription>
          {data?.attributes?.map((item: any, index) => {
            return <DescrText key={index}>{`${item.trait_type.replace('_', ' ')}: ${item.value}`}</DescrText>;
          })}
        </TokenDescription>
        <ButtonsWrapper>
          <NFTSendButton tokenID={data.tokenID} tokenAddress={tokenAddress} name={data.name} />
          <NFTBurnButton tokenAddress={tokenAddress} tokenID={data.tokenID} name={data.name} />
        </ButtonsWrapper>
        <TransactionNFTList
          loadingHistory={loadingHistory}
          historyList={historyList}
          pageHistory={pageHistory}
          setPageHistory={setPageHistory}
          pageSize={pageSize}
          totalCount={totalCount}
        />
      </ModalCollectionWrapper>
    </ModalWindow>
  );
};

export default CollectionItemDetails;
