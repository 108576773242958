import {format, isBefore} from 'date-fns';

export const dateToDDMMYY = (date: Date) => {
  return format(new Date(date), 'dd.MM.yy');
};

export const dateToDDMMYYHHMM = (date: Date) => {
  return format(new Date(date), 'dd.MM.yy:HH:mm');
};

export const addMonthToDate = (startDate: Date | undefined, month: number | undefined) => {
  if (!month || !startDate) {
    return undefined;
  }
  const prevMonth = new Date(startDate).getMonth();
  return new Date(new Date(startDate).setMonth(prevMonth + month));
};

export const compareDataWithCurrentDate = (date?: Date) => {
  if (!date) {
    return false;
  }
  return date.getTime() < new Date().getTime();
};

export const checkVerificationTimeout = (date: Date | undefined) => {
  if (!date) {
    return true;
  }
  return isBefore(new Date(new Date(date).setDate(new Date(date).getDate() + 1)), new Date());
};
