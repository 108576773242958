import React, {Dispatch, SetStateAction} from 'react';
import {HistoryWrapperTable, MainTitle, BottomWrapper, MintWrapper, ButtonSecondarySelect} from './styles';
import {TransactionTable} from '../../../types/tokens';
import {TransactionNFTList} from '../TransactionsList/TransactionNFTList';
import CollectionList from './CollectionList';
import {CollectionItemType} from '../../../types/transaction';
import {Layout} from '../../../ui-kit/Layout';
import {Text} from '../../../ui-kit/Typography/Typography';
interface IDerailPageNFT {
  tokenAddress: string;

  historyList: TransactionTable[];
  setPageHistory: Dispatch<SetStateAction<number>>;
  loadingHistory: boolean;
  pageHistory: number;
  pageSizeHistory: number;
  totalCountHistory: number;

  collection: CollectionItemType[];
  isOwner: boolean;

  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalCount: number;

  forwardMint: () => void;
}

export const NFTViewPage: React.FC<IDerailPageNFT> = ({
  collection,
  pageHistory,
  setPageHistory,
  loadingHistory,
  pageSizeHistory,
  totalCountHistory,
  historyList,
  tokenAddress,
  setPage,
  page,
  totalCount,
  isOwner,
  forwardMint,
}) => {
  return (
    <Layout>
      <MainTitle>NFT Pictures</MainTitle>
      <CollectionList
        tokenAddress={tokenAddress}
        data={collection}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
      />
      <BottomWrapper>
        <HistoryWrapperTable>
          <MainTitle>NFT History</MainTitle>
          <TransactionNFTList
            loadingHistory={loadingHistory}
            historyList={historyList}
            pageHistory={pageHistory}
            setPageHistory={setPageHistory}
            pageSize={pageSizeHistory}
            totalCount={totalCountHistory}
          />
        </HistoryWrapperTable>
        {isOwner && (
          <MintWrapper>
            <Text level={3} strong={true}>
              Mint New Item
            </Text>
            <ButtonSecondarySelect onClick={forwardMint}>Mint</ButtonSecondarySelect>
          </MintWrapper>
        )}
      </BottomWrapper>
    </Layout>
  );
};
