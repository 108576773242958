import styled from 'styled-components';
import {Subtitle, Text, Title} from '../../ui-kit/Typography/Typography';
import {Button} from '../../ui-kit/Buttons/Button';
import {theme} from '../../ui-kit/theme';

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const TitleText = styled(Title).attrs({
  level: 2,
  color: 'white',
})``;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 200px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 51px;
  margin-bottom: 63px;
`;

export const AsideWrapper = styled.div`
  width: 340px;
  margin-top: 55px;
`;

export const IdentifyWrapper = styled(Text).attrs({
  level: 2,
})`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

export const CardFormContainer = styled.div`
  margin-top: 40px;
  border-radius: 10px;
  max-width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${theme.pallet.common.darkGray};
  box-sizing: border-box;

  & > span:first-child {
    align-self: flex-start;
    margin-bottom: 0;
  }

  & > button {
    margin-top: 12px;
  }
`;

export const RemoveButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;

  span {
    text-decoration: underline;
  }
`;
styled(Button).attrs({
  variant: 'primary',
  selected: true,
})`
  height: 61px;
`;
export const RepayButton = styled(Button).attrs({
  type: 'secondary',
  color: 'error',
})<{$isDisable: boolean}>`
  ${({$isDisable}) =>
    $isDisable ? `border-color: ${theme.pallet.common.grey7};` : `border-color: ${theme.pallet.common.red};`}
`;

export const PayButton = styled(Button).attrs({
  type: 'secondary',
  color: 'info',
})<{$isDisable: boolean}>`
  ${({$isDisable}) =>
    $isDisable ? `border-color: ${theme.pallet.common.grey7};` : `border-color: ${theme.pallet.common.white};`}
`;

export const RepaySubtitle = styled(Subtitle)<{$isDisable: boolean}>`
  ${({$isDisable}) => ($isDisable ? `color: ${theme.pallet.common.grey7};` : `color: ${theme.pallet.common.red};`)}
`;

export const PaySubtitle = styled(Subtitle)<{$isDisable: boolean}>`
  ${({$isDisable}) => ($isDisable ? `color: ${theme.pallet.common.grey7};` : `color: ${theme.pallet.common.white};`)}
`;
export const ButtonModal = styled(Button)`
  height: 50px;
`;

export const EnterCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 445px;
  & > label > div > input {
    padding-left: 10px;
  }
`;

export const SecondaryDataWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 22px;
  margin-bottom: 25px;
  align-items: center;
  width: 445px;
  & > label {
    width: 206px;
  }
`;

export const CardLine = styled.div`
  position: absolute;
  top: 12px;
  left: 81px;
  height: 35px;
  width: 1px;
  background-color: ${theme.pallet.background.quaternary};
`;
