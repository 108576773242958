import styled from 'styled-components';
import {theme} from '../../ui-kit/theme';
import {Title} from '../../ui-kit/Typography/Typography';
import {Link} from 'react-router-dom';

export const PageWrapper = styled.div`
  padding-top: 60px;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: ${theme.pallet.background.primary};
  z-index: 1;
`;

export const LinkText = styled(Title).attrs({
  color: 'white',
  level: 2,
})`
  width: 100%;
`;

export const HomeTitle = styled(Title).attrs({
  color: 'white',
  level: 2,
})`
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledLink = styled(Link)`
  margin-bottom: 10px;
  width: 100%;
  text-decoration: none;
`;

export const BalanceWrapperTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: space-between;

  & > form:last-child {
    width: 300px;
  }
`;

export const BalanceWrapper = styled.div`
  margin-top: 60px;
  width: 100%;
  margin-bottom: 40px;
`;
