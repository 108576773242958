import React from 'react';
import {CardFormContainer, RemoveButton} from './styles';
import {UserCardType} from '../../types/validator';
import {Options} from '../../ui-kit/Form/Select/types';
import {DropDownSelect} from '../../ui-kit/Form/Select/DropDownSelect';
import {Subtitle} from '../../ui-kit/Typography/Typography';
import {ActionButton} from './ActionButton';
import {CardFormType} from '../../types/card';
interface CardProps {
  cardsData: {cards: UserCardType[]; defaultCardId?: string};
  setDefaultCard: (cardId: string) => void;
  deleteCard: (cardId: string) => void;
  submitAddCard: () => Promise<boolean>;
  setNewCardData: React.Dispatch<React.SetStateAction<CardFormType>>;
  newCardData: CardFormType;
}
const CardForm: React.FC<CardProps> = ({
  cardsData,
  deleteCard,
  setNewCardData,
  newCardData,
  submitAddCard,
  setDefaultCard,
}) => {
  const options: Options[] = cardsData?.cards?.map((item) => {
    return {
      value: item?.id,
      label: `**** **** **** ${item?.last4}`,
    };
  });

  const onChangeCard = (_label: string, value: string) => {
    setDefaultCard?.(value);
  };
  const handleRemove = () => deleteCard(cardsData?.defaultCardId || '');

  const defaultCard = cardsData?.cards?.find((item) => item?.id === cardsData?.defaultCardId);
  const selected = defaultCard ? `**** **** **** ${defaultCard?.last4}` : '';
  return (
    <>
      <CardFormContainer>
        {Boolean(cardsData?.cards?.length) && (
          <>
            <DropDownSelect
              label={'Default Card'}
              name={'card'}
              options={options}
              onChange={onChangeCard}
              selectOption={selected}
              showValue={true}
            />
            <RemoveButton onClick={handleRemove}>
              <Subtitle color={'gray'} level={3}>
                Remove card
              </Subtitle>
            </RemoveButton>
          </>
        )}
        <ActionButton setCardData={setNewCardData} cardData={newCardData} submit={submitAddCard} />
      </CardFormContainer>
    </>
  );
};

export default CardForm;
