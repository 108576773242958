import React from 'react';
import {
  TransactionStepWrapper,
  Line,
  Circle,
  Image,
  Wrapper,
  LinePreReady,
  LineReady,
  CircleReady,
  CircleDisable,
} from './styles';
import greenDaw from './assets/greenDaw.svg';
import greyDaw from './assets/greyDaw.svg';
import whiteDaw from './assets/whiteDaw.svg';

interface Props {
  active: number;
  count: number;
  setStep: (step: number) => void;
}

export const Stepper: React.FC<Props> = ({active, count, setStep}) => {
  const handlerSetStep = (step: number) => () => {
    setStep(step);
  };
  return (
    <TransactionStepWrapper>
      {Array.from(Array(count).keys()).map((el, index) => (
        <Wrapper key={index}>
          {index + 1 === active && index + 1 === count ? (
            <Circle>
              <Image src={greenDaw} />
            </Circle>
          ) : index + 1 === count ? (
            <CircleDisable onClick={handlerSetStep(index + 1)}>
              <Image src={greyDaw} />
            </CircleDisable>
          ) : index + 1 === active ? (
            <>
              <Circle>
                <Image src={greenDaw} />
              </Circle>
              <LinePreReady />
            </>
          ) : index + 1 > active ? (
            <>
              <CircleDisable onClick={handlerSetStep(index + 1)}>
                <Image src={greyDaw} />
              </CircleDisable>
              <Line />
            </>
          ) : (
            <>
              <CircleReady onClick={handlerSetStep(index + 1)}>
                <Image src={whiteDaw} />
              </CircleReady>
              <LineReady />
            </>
          )}
        </Wrapper>
      ))}
    </TransactionStepWrapper>
  );
};
