import React, {useState} from 'react';
import {Address, ButtonContentWrapper, ModalWrapper} from './styles';
import {Text} from '../../ui-kit/Typography/Typography';
import {IconSvg} from '../../ui-kit/Icons/Svg';
import {CopyIcon} from '../../ui-kit/Icons/CopyIcon';
import {Button} from '../../ui-kit/Buttons/Button';
import {Menu} from './Menu';
import {ModalWindow} from '../../ui-kit/Modal';

interface IMenuButton {
  loading: boolean;
  address: string;
  signOut: () => void;
  clearTransactions: () => void;
  transactions: any[];
}

export const MenuButton: React.FC<IMenuButton> = ({address, loading, signOut, transactions, clearTransactions}) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Button onClick={toggleMenu} type="primary">
        <ButtonContentWrapper>
          {loading ? (
            <>
              <Text level={1} strong={true} color={'whiteGray'}>
                Pending....
              </Text>
              <IconSvg className={'loading'} type="pending" width="34" height="34" viewBox="-3.5 -4.5 24 24" />
            </>
          ) : (
            <>
              <Address>{address}</Address>
              <CopyIcon dataToCopy={address} />
            </>
          )}
        </ButtonContentWrapper>
      </Button>
      <ModalWrapper>
        <ModalWindow maxWidth={845} title={'Account'} visible={open} onClose={toggleMenu}>
          <Menu clearTransactions={clearTransactions} transactions={transactions} address={address} signOut={signOut} />
        </ModalWindow>
      </ModalWrapper>
    </div>
  );
};

export default MenuButton;
