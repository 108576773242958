import {SetterOrUpdater, useRecoilValue, useSetRecoilState} from 'recoil';
import {contactsState, getFilteredContacts} from '../states/contacts';
import {ContactType} from '../types/contacts';
import {useCallback, useEffect, useState} from 'react';
import {PAGE_SIZE} from '../constants/data';
import {useViewer} from './user';
import {web3} from './metamask';

export const useContacts = (filter: string): ContactType[] => {
  return useRecoilValue(getFilteredContacts(filter));
};

export const useSetContacts = (): SetterOrUpdater<ContactType[]> => {
  return useSetRecoilState(contactsState);
};

export const useGetContacts = () => {
  const viewer = useViewer();
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const contacts = useContacts(searchValue);
  const count = contacts.length;
  const setContacts = useSetContacts();
  const [error, setError] = useState<string>('');
  useEffect(() => {
    const data = localStorage.getItem(`contacts[${viewer?.address}]`);
    if (data) {
      setContacts(JSON.parse(data));
    }
  }, []);
  const addContact = useCallback(
    (newContact: ContactType) => {
      const jsonData = localStorage.getItem(`contacts[${viewer?.address}]`) || '';
      const data = jsonData ? JSON.parse(jsonData) : [];
      const validAdd = web3.utils.isAddress(newContact.address) && newContact.name.length <= 20;
      if (!validAdd) {
        setError(
          `Invalid data entered. The name must be no more than 20 characters. The address must match the contact's wallet address`,
        );
        return;
      }
      if (
        data.length !== 0 &&
        (data as ContactType[]).filter(
          (item) => web3.utils.toChecksumAddress(item.address) === web3.utils.toChecksumAddress(newContact.address),
        ).length > 0
      ) {
        setError(`User with this address is already in contacts`);
        return;
      }

      newContact = {...newContact, address: web3.utils.toChecksumAddress(newContact.address)};
      setContacts([...data, newContact]);
      localStorage.setItem(`contacts[${viewer?.address}]`, JSON.stringify([...data, newContact]));
    },
    [contacts],
  );
  const deleteContact = useCallback(
    (address: string) => {
      const jsonData = localStorage.getItem(`contacts[${viewer?.address}]`) || '';
      const data = jsonData ? JSON.parse(jsonData) : [];
      if (data.length > 0) {
        const newContactList = (data as ContactType[]).filter((el) => el.address !== address);
        setContacts([...newContactList]);
        localStorage.setItem(`contacts[${viewer?.address}]`, JSON.stringify([...newContactList]));
      }
      setPage(1);
    },
    [contacts],
  );
  const editContact = useCallback(
    (newContactData: ContactType, oldContactData: ContactType) => {
      const jsonData = localStorage.getItem(`contacts[${viewer?.address}]`) || '';
      const data = jsonData ? JSON.parse(jsonData) : [];
      const validEdit =
        newContactData.name !== oldContactData.name || oldContactData.address !== newContactData.address;
      const validData = web3.utils.isAddress(newContactData.address) && newContactData.name.length <= 20;
      if (
        validData &&
        validEdit &&
        (data as ContactType[])
          .filter((item) => item.address !== oldContactData.address)
          .filter(
            (item) =>
              web3.utils.toChecksumAddress(item.address) === web3.utils.toChecksumAddress(newContactData.address),
          ).length === 0
      ) {
        const newContactList = (data as ContactType[]).map((el) =>
          el.address === oldContactData.address
            ? {...newContactData, address: web3.utils.toChecksumAddress(newContactData.address)}
            : el,
        );
        setContacts([...newContactList]);
        localStorage.setItem(`contacts[${viewer?.address}]`, JSON.stringify([...newContactList]));
      }
    },
    [contacts],
  );
  return {
    searchValue,
    setSearchValue,
    contacts: contacts.slice((page - 1) * PAGE_SIZE, PAGE_SIZE * page),
    setContacts,
    page,
    setPage,
    count,
    pageSize: PAGE_SIZE,
    addContact,
    deleteContact,
    editContact,
    error,
  };
};
