import React from 'react';
import {
  Wrapper,
  Menu,
  MenuItem,
  ButtonsWrapper,
  Line,
  Balance,
  MenuText,
  Logo,
  BalanceWrapper,
  BalanceTooltip,
} from './styles';
import {IconSvg} from '../../ui-kit/Icons/Svg';
import {route} from '../../constants/route';
import {LinkButton} from '../../ui-kit/Buttons/LinkButton';
import {formatUserBalance, formatEthValue} from '../../helpers/format';
import {AccountContainer} from '../../containers/Account/Account';

interface Props {
  balance: string;
}

export const TopAppBar: React.FC<Props> = ({balance}) => {
  return (
    <Wrapper>
      <Menu>
        <Logo to={route.homePage}>
          <IconSvg type="logo" width="167" height="33" viewBox="0 0 167 33" />
        </Logo>
        <MenuItem to={route.assets}>
          <MenuText>Assets</MenuText>
        </MenuItem>
        <MenuItem to={route.contacts}>
          <MenuText>Contacts</MenuText>
        </MenuItem>
        <MenuItem to={route.invoices}>
          <MenuText>Invoices</MenuText>
        </MenuItem>
        <LinkButton to={route.earn} type="secondary">
          Earn
        </LinkButton>
      </Menu>
      <ButtonsWrapper>
        {balance && (
          <BalanceWrapper>
            <MenuText>Water Balance</MenuText>
            <Line />
            <Balance>{formatUserBalance(balance, true)}</Balance>
            {formatEthValue(balance).length > 12 && (
              <BalanceTooltip>{formatUserBalance(balance, false)}</BalanceTooltip>
            )}
          </BalanceWrapper>
        )}
        <AccountContainer />
      </ButtonsWrapper>
    </Wrapper>
  );
};
