import React from 'react';
import {InputBaseProps} from './types';
import {InnerInput, InnerLabel, Title, InputWrapper, ErrorMessageWrapper, CleaveInput} from './styles';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';

export const Input: React.FC<InputBaseProps> = ({
  type = 'text',
  onChange,
  placeholder,
  status,
  sizetype,
  value,
  label,
  placeholdertype,
  disabled,
  name,
  color,
  errorMessage,
  id,
  addonBefore,
  addonAfter,
  aligntext,
  defaultValue,
  options,
}) => {
  return (
    <>
      <InnerLabel>
        {label && <Title>{label}</Title>}
        <InputWrapper sizetype={sizetype} status={status} disabled={disabled}>
          {addonBefore}
          {(options && (
            <CleaveInput
              onChange={onChange}
              options={options}
              type={type}
              status={status}
              placeholder={placeholder}
              sizetype={sizetype}
              aligntext={aligntext}
              defaultValue={defaultValue}
              value={value}
              name={name}
              placeholdertype={placeholdertype}
              color={color}
              disabled={disabled}
            />
          )) || (
            <InnerInput
              type={type}
              status={status}
              onChange={onChange}
              placeholder={placeholder}
              sizetype={sizetype}
              name={name}
              id={id}
              value={value}
              aligntext={aligntext}
              defaultValue={defaultValue}
              placeholdertype={placeholdertype}
              color={color}
              disabled={disabled}
            />
          )}
          {errorMessage && (
            <>
              <ErrorMessageWrapper>
                <ErrorMessage errorMessage={errorMessage} />
              </ErrorMessageWrapper>
            </>
          )}
          {addonAfter}
        </InputWrapper>
      </InnerLabel>
    </>
  );
};
