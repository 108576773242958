import styled from 'styled-components';
import {Title} from '../../ui-kit/Typography/Typography';

export const BalanceWrapper = styled.div`
  width: 100%;
  margin-top: 64px;
  margin-bottom: 75px;
`;

export const HistoryTitle = styled(Title).attrs({
  color: 'white',
  level: 2,
})`
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  padding: 0 160px 0 160px;
  width: calc(100% - 320px);
  @media (max-width: 1400px) {
    padding: 0 60px 0 60px;
    width: calc(100% - 120px);
  }
  @media (max-width: 1200px) {
    padding: 0 30px 0 30px;
    width: calc(100% - 60px);
  }
`;

export const AsideWrapper = styled.div<{type: 'ft' | 'nft'}>`
  margin-top: 152px;
  display: flex;
  flex-direction: column;
  gap: ${({type}) => (type === 'ft' ? '220px' : '300px')}};
`;

export const BalanceWrapperTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 725px;
`;

export const HistoryWrapperTable = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
