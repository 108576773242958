import React, {useEffect, useRef, useState} from 'react';
import {
  DropSelectWrapper,
  DropArrowWrapper,
  DropValueWrapper,
  DropSelectContentWrapper,
  DropMenu,
  DropMenuItem,
} from './styles';
import {Button} from '../../Buttons/Button';
import {Text} from '../../Typography/Typography';
import {DropDownType} from './types';
import {IconSvg} from '../../Icons/Svg';
import {Title} from '../Input/styles';

export const DropDownSelect = ({selectOption, onChange, name, options, label}: DropDownType) => {
  const optionRef = useRef<HTMLHeadingElement>(null);
  const [menuStatus, setStatus] = useState<boolean>(false);

  const setMenuStatus = () => {
    setStatus(!menuStatus);
  };
  const handleChange = (label: string, value: string | undefined) => () => {
    onChange(label, value || '');
    setMenuStatus();
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!optionRef?.current?.contains(event.target)) {
        setStatus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionRef]);
  const selectOptionObject = options?.find((el) => el.label === selectOption);
  return (
    <>
      {label && <Title>{label}</Title>}
      <DropSelectWrapper ref={optionRef}>
        <Button selected={menuStatus} onClick={setMenuStatus}>
          <DropSelectContentWrapper>
            <DropValueWrapper>
              <Text>{selectOptionObject?.label ? selectOptionObject?.label : name}</Text>
            </DropValueWrapper>
            <DropArrowWrapper>
              {menuStatus ? (
                <IconSvg type="downArrowActive" width="19" height="13" viewBox="0 0 19 13" />
              ) : (
                <IconSvg type={'downArrow'} width="19" height="13" viewBox="0 0 19 13" />
              )}
            </DropArrowWrapper>{' '}
          </DropSelectContentWrapper>
        </Button>
        {menuStatus && options && (
          <DropMenu>
            {options?.map((el, index) => (
              <DropMenuItem
                key={index}
                onClick={handleChange(el.label, el.value)}
                color={el?.label === selectOption ? 'gray3' : 'white'}>
                {el.label}
              </DropMenuItem>
            ))}
          </DropMenu>
        )}
      </DropSelectWrapper>
    </>
  );
};

export const DropSelect: React.FC<DropDownType> = ({options, label, onChange, selectOption, showValue, name}) => {
  return (
    <DropDownSelect
      name={name}
      label={label}
      onChange={onChange}
      selectOption={selectOption}
      options={options}
      showValue={showValue}
    />
  );
};
