import styled from 'styled-components';
import {Button} from '../../../ui-kit/Buttons/Button';
import {theme} from '../../../ui-kit/theme';

export const InputWrapper = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
`;

export const TokenCreateForm = styled.form`
  display: flex;
  justify-content: center;
  min-width: 280px;
  max-width: 320px;
  border-radius: 10px;
  background-color: ${theme.pallet.background.tertiary};
`;

export const StyledInputImage = styled.input.attrs({type: 'file', accept: 'image/png, image/gif, image/jpeg'})`
  visibility: hidden;
  display: none;
  height: 0;
`;

export const CardImgWrapper = styled.div`
  div {
    background-color: ${theme.pallet.background.primary};
  }
  input {
    background-color: ${theme.pallet.background.primary};
  }
`;

export const ButtonSecondarySelect = styled(Button).attrs({
  variant: 'primary',
})`
  margin-top: 5px;
  height: 60px;
`;

export const CardImg = styled.img`
  width: 26px;
  height: 26px;
`;
