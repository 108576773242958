import styled from 'styled-components';
import {theme} from '../theme';

export const TransactionStepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  & > div:nth-last-child(-n + 1) {
    width: auto;
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${theme.pallet.common.blue};
`;

export const CircleDisable = styled(Circle)`
  background-color: ${theme.pallet.background.nonary};
  border: none;
`;

export const CircleReady = styled(Circle)`
  background: linear-gradient(270.32deg, rgba(0, 226, 255, 0.62) -4.89%, rgba(0, 142, 238, 0.62) 85.28%), #008eee;
  border: none;
`;

export const Line = styled.div`
  height: 5px;
  width: 100%;
  background: ${theme.pallet.background.nonary};
`;

export const LinePreReady = styled(Line)`
  background: ${theme.pallet.blueScale._5};
`;

export const LineReady = styled(Line)`
  background: ${theme.pallet.blueScale._6};
`;

export const Image = styled.img`
  width: 20px;
  height: 14px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
