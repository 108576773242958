import {gql} from '@apollo/client';
import {User as userFragment} from './fragments/user';
import {User} from '../types/user';

export interface UpdateSessionIdRequest {
  sessionId: string;
  id: string;
}
export interface UpdateSessionIdResponse {
  updateUser: {
    user: User;
  };
}

export const updateUserSessionId = gql`
  mutation updateSessionId($sessionId: String, $id: ID!) {
    updateUser(input: {fields: {sessionId: $sessionId}, id: $id}) {
      user {
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;
