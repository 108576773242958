import React from 'react';
import {ActionsWrapper, ContentWrapper, LoadingWrapper, Title} from './styles';
import {IconSvg} from '../../../ui-kit/Icons/Svg';
import {Transaction, TypeOfTransactions} from '../../../types/transaction';
import {CopyIcon} from '../../../ui-kit/Icons/CopyIcon';
import {EXPLORER_URL} from '../../../constants/env';
import {Action} from '../../Home/TransactionsList/TransactionList/styles';

interface IProps {
  item: Transaction;
}

export const TransactionItem: React.FC<IProps> = ({item}) => {
  let title = '';
  if (item.status) {
    switch (item.transactionType) {
      case TypeOfTransactions.sendWater:
        title = `Sent Water (${item.data?.amount}) to ${item.contactName || item.data?.recipient}`;
        break;
      case TypeOfTransactions.tokenCreate:
        title = `Token ${item.data?.name || item?.contractAddress} created`;
        break;
      case TypeOfTransactions.tokenSendFT:
        title = `Token ${item.data?.name || item?.to} (${item.data?.amount} ${item.data?.symbol}) Sent to ${
          item.contactName || item.data?.recipient
        }`;
        break;
      case TypeOfTransactions.tokenMint:
        title = `Token ${item.data?.name || item.to} With ID ${item.data?.tokenID} was Minted to ${
          item.data?.collectionName
        }`;
        break;
      case TypeOfTransactions.tokenBurn:
        title = `Token ${item.data?.name || item.to} was Burned from ${item.data?.collectionName}`;
        break;
      case TypeOfTransactions.tokenSendNFT:
        title = `Token ${item.data?.name || item.to} with ID ${item.data?.tokenID} Sent to ${
          item.contactName || item.data?.recipient
        }`;
        break;
    }
  } else {
    switch (item.transactionType) {
      case TypeOfTransactions.sendWater:
        title = `Sending Water (${item.data?.amount}) to ${item.contactName || item.data?.recipient}`;
        break;
      case TypeOfTransactions.tokenCreate:
        title = `Token ${item.data?.name || item.contractAddress} is Creating`;
        break;
      case TypeOfTransactions.tokenSendFT:
        title = `Token ${item.data?.name || item.to} (${item.data?.amount} ${item.data?.symbol}) is Sending to ${
          item.contactName || item.data?.recipient
        }`;
        break;
      case TypeOfTransactions.tokenMint:
        title = `Token ${item.data?.name || item.to} is Minting to ${item.data?.collectionName}`;
        break;
      case TypeOfTransactions.tokenBurn:
        title = `Token ${item.data?.name || item.to} is Burning from ${item.data?.collectionName}`;
        break;
      case TypeOfTransactions.tokenSendNFT:
        title = `Token ${item.data?.name || item.to} with ID ${item.data?.tokenID} is sending to ${
          item.contactName || item.data?.recipient
        }`;
        break;
    }
  }
  return (
    <ContentWrapper>
      <ActionsWrapper>
        <CopyIcon dataToCopy={item.transactionHash} />
        <Action target={'_blank'} href={`${EXPLORER_URL}/tx/${item.transactionHash}`}>
          <IconSvg type="arrowRightLong" width="26" height="18" />
        </Action>
      </ActionsWrapper>
      <Title>{title ? title : item.transactionHash}</Title>
      <LoadingWrapper>
        {item.status ? (
          <IconSvg type="done" width="24" height="24" viewBox="-3.5 -4.5 24 24" />
        ) : (
          <IconSvg className={'loading'} type="pending" width="24" height="24" viewBox="-3.5 -4.5 24 24" />
        )}
      </LoadingWrapper>
    </ContentWrapper>
  );
};
