import styled from 'styled-components';
import {Link as LinkRoute} from 'react-router-dom';
import {Subtitle, Text, Title} from '../../ui-kit/Typography/Typography';
import {theme} from '../../ui-kit/theme';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  width: 773px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 26px 0 0 0;
  margin-bottom: 143px;
  background: ${theme.pallet.background.tertiary};
  border-radius: 10px;
`;

export const StepOneWrapper = styled(FormWrapper)`
  padding: 26px 0 58px 0;
`;

export const StepTwoWrapper = styled(FormWrapper)`
  padding: 26px 0 25px 0;
`;

export const StepWrapper = styled(Title).attrs({
  level: 2,
})`
  margin-top: 65px;
  margin-bottom: 18px;
  width: 100%;
`;

export const FormTitle = styled(Text).attrs({
  color: 'gray',
  strong: true,
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 35px;
  padding-left: 39px;
  padding-right: 39px;
  width: calc(100% - 78px);
`;

export const FormLine = styled.div`
  height: 1px;
  background-color: ${theme.pallet.background.primary};
  width: 100%;
`;

export const SelectDataCentreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
  padding: 0 40px;
  width: calc(100% - 80px);
`;

export const SelectZoneWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  align-items: center;
  padding: 0 40px;
  width: calc(100% - 80px);
  & > div {
    width: 335px;
    margin-bottom: 26px;
  }
`;

export const SelectPaymentWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 80px);
  & > span {
    width: 380px;
    margin-bottom: 0;
  }
  & > div {
    width: 380px;
    margin-bottom: 26px;
  }
`;

export const EnterCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
  width: 445px;
  & > label > div > input {
    padding-left: 10px;
  }
`;

export const SecondaryDataWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 22px;
  margin-bottom: 65px;
  align-items: center;
  width: 445px;
  & > label {
    width: 206px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  width: 445px;
`;

export const CardLine = styled.div`
  position: absolute;
  top: 12px;
  left: 81px;
  height: 35px;
  width: 1px;
  background-color: ${theme.pallet.background.quaternary};
`;

export const CardImg = styled.img`
  position: absolute;
  width: 38px;
  height: 30px;
  right: 18px;
  top: 15px;
`;

export const NextButtonWrapper = styled.div`
  height: 61px;
  width: 205px;
  margin-bottom: 130px;
`;

export const ValidationButtonWrapper = styled.div`
  height: 61px;
  width: 300px;
  margin: 50px 0;
`;

export const BackButtonContainer = styled(LinkRoute)`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 82px;
  width: 100%;
`;

export const BackButtonBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${theme.pallet.background.tertiary};
  width: 123px;
  height: 49px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 60px;
`;

export const BackButtonText = styled(Text).attrs({
  strong: true,
  color: 'gray',
})``;

export const ConfirmStepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
  align-items: flex-start;
  padding: 0 40px;
  width: calc(100% - 80px);
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 205px;
  margin-right: 44px;
  & > button {
    height: 62px;
    margin-top: 37px;
    margin-bottom: 19px;
  }
`;

export const DataCenterLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 100px;
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 445px;
  & > label {
    margin-bottom: 13px;
  }
`;

export const TotalCostWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  margin-bottom: 71px;
  margin-top: 18px;
`;
export const TotalCost = styled(Title).attrs({
  color: 'blueLight',
  level: 4,
})`
  margin-left: 11px;
`;

export const DataCenterInfoWrapper = styled(Text)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 53px 0 54px;
  width: 98px;
  background: ${theme.pallet.background.quinary};
  height: 132px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const DataCenterPrice = styled(Text).attrs({
  color: 'gray2',
  level: 2,
})``;

export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  & > label {
    width: 215px;
  }
`;

export const Footer = styled.div`
  height: 99px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;
  width: calc(100% - 82px);
`;

export const SecurityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 402px;
`;

export const StripeWrapper = styled(Text).attrs({
  color: 'gray',
  size: 14,
})`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background: ${theme.pallet.background.primary};
  border-radius: 10px;
  cursor: pointer;
  padding: 0 19px;
  width: 149px;
  height: 46px;
`;

export const MetamaskConfirmWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 41px;
  width: calc(100% - 82px);
  height: 61px;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 54px;
  margin-bottom: 41px;
  & > button {
    width: 323px;
  }
`;

export const AgreeTermsWrap = styled.label`
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  text-underline: none;
  text-underline-color: ${theme.pallet.common.gray};
  margin-left: 12px;
`;

export const AgreeTerms = styled(Subtitle).attrs({
  level: 1,
  color: 'gray',
})`
  text-decoration: underline;
`;

export const SeparateCardsWrapper = styled.div`
  margin-top: ${(props) => (props.theme.stripeMode ? '108px' : '64px')};
  width: 445px;
  margin-bottom: ${(props) => (props.theme.stripeMode ? '256px' : 0)};
`;

export const KycContentWrapper = styled.div`
  width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
`;
