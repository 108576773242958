import React from 'react';
import {
  FormWrapper,
  FormLine,
  FormTitle,
  SecondaryDataWrap,
  EnterCardWrapper,
  CardLine,
  BackButtonWrapper,
  SeparateCardsWrapper,
  BackButtonText,
} from './styles';
import {DropSelect} from '../../ui-kit/Form/Select/DropDownSelect';
import {Input} from '../../ui-kit/Form/Input';
import {CardFormType} from '../../types/card';
import {IconSvg} from '../../ui-kit/Icons/Svg';
import {FormFooter} from './FormFooter';
interface Props {
  cardType: string;
  cardData: CardFormType;
  selectCard: (arg1: string, arg2: string) => void;
  setCardData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  cardList: {value: string; label: string}[] | null;
  setTypeCard: (arg1: string) => void;
  backStep: () => void;
}

const CardAssets: {[name: string]: React.ReactElement<any, any> | null} = {
  visa: IconSvg({type: 'visa', width: '38', height: '38', viewBox: '0 0 48 48'}),
  mastercard: IconSvg({type: 'mastercard', width: '38', height: '38', viewBox: '0 0 48 48'}),
  amex: IconSvg({type: 'amex', width: '38', height: '38', viewBox: '0 0 48 48'}),
};

export const CardStep: React.FC<Props> = ({
  cardData,
  setTypeCard,
  setCardData,
  selectCard,
  backStep,
  cardList,
  cardType,
}) => {
  return (
    <FormWrapper>
      <FormTitle>
        Enter your card:
        <BackButtonWrapper onClick={backStep}>
          <IconSvg type="arrowLeft" width="9" height="16" viewBox="0 0 9 16" />
          <BackButtonText>Back</BackButtonText>
        </BackButtonWrapper>
      </FormTitle>
      <FormLine />
      {cardList && (
        <SeparateCardsWrapper theme={{stripeMode: cardData.id.length > 0}}>
          <DropSelect
            selectOption={cardData.cardBrand + ' ' + cardData.cardNumber.value}
            options={cardList}
            label={'Card Number'}
            onChange={selectCard}
            name={'Choose your card'}
          />
        </SeparateCardsWrapper>
      )}
      {cardData.id.length === 0 && (
        <>
          <EnterCardWrapper>
            <Input
              name={'cardName'}
              sizetype={'middle'}
              placeholdertype={'primary'}
              label={'Card Holder'}
              onChange={setCardData}
              value={cardData.cardName.value}
              errorMessage={cardData.cardName.error}
              status={cardData.cardName.error.length > 0 ? 'error' : undefined}
              placeholder={'John Jackson'}
            />
          </EnterCardWrapper>
          <EnterCardWrapper>
            <Input
              name={'cardNumber'}
              sizetype={'middle'}
              placeholdertype={'primary'}
              label={'Card Number'}
              onChange={setCardData}
              value={cardData.cardNumber.value}
              placeholder={'card'}
              options={{
                creditCard: true,
                onCreditCardTypeChanged: setTypeCard,
                delimiter: '     ',
              }}
              errorMessage={cardData.cardNumber.error}
              status={cardData.cardNumber.error?.length > 0 ? 'error' : undefined}
              addonBefore={<CardLine />}
              addonAfter={cardType !== 'unknown' ? CardAssets[cardType] : null}
            />
          </EnterCardWrapper>
          <SecondaryDataWrap>
            <Input
              name={'date'}
              sizetype={'middle'}
              placeholdertype={'primary'}
              aligntext={'center'}
              label={'Expire Date'}
              onChange={setCardData}
              value={cardData.date.value}
              placeholder={'mm/yy'}
              errorMessage={cardData.date.error}
              status={cardData.date.error.length > 0 ? 'error' : undefined}
              options={{
                date: true,
                datePattern: ['m', 'y'],
              }}
            />
            <Input
              type={'password'}
              name={'cvcCode'}
              sizetype={'middle'}
              placeholdertype={'primary'}
              aligntext={'center'}
              label={'CVC'}
              errorMessage={cardData.cvcCode.error}
              status={cardData.cvcCode.error.length > 0 ? 'error' : undefined}
              onChange={setCardData}
              value={cardData.cvcCode.value}
              placeholder={'....'}
            />
          </SecondaryDataWrap>
        </>
      )}
      <FormLine />
      <FormFooter />
    </FormWrapper>
  );
};
