import styled from 'styled-components';
import {theme} from '../../../ui-kit/theme';
import {Text, Title} from '../../../ui-kit/Typography/Typography';
import {Button} from '../../../ui-kit/Buttons/Button';

export const HistoryWrapperTable = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  gap: 25px;
`;

export const MintWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 130px;
  width: 280px;
  max-height: 180px;
  padding: 0 45px;
  background-color: ${theme.pallet.background.tertiary};
  border-radius: 10px;
`;

export const ButtonSecondarySelect = styled(Button).attrs({
  variant: 'primary',
})`
  margin-top: 5px;
  height: 60px;
`;

export const MainTitle = styled(Title).attrs({
  color: 'white',
  level: 2,
})`
  margin-top: 64px;
  margin-bottom: 10px;
  width: 100%;
`;

export const EmptyText = styled(Text).attrs({
  color: 'white',
  level: 2,
})`
  width: 100%;
  text-align: center;
`;

export const CollectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 230px);
  grid-column-gap: 10px;
  grid-row-gap: 46px;
  justify-content: space-between;
  width: 100%;
`;

export const EmptyState = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 230px;
  background-color: ${theme.pallet.common.blueGray};
  border-radius: 10px;
  object-fit: cover;
`;

export const DetailedWrapper = styled.div`
  background-color: ${theme.pallet.common.blueGray};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 25px;
`;

export const ImageDetailed = styled.img`
  width: 1024px;
  background-color: ${theme.pallet.common.blueGray};
  border-radius: 10px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  cursor: pointer;
`;

export const TokenName = styled(Text).attrs({
  strong: true,
})``;

export const DescrText = styled(Text).attrs({})`
  text-transform: capitalize;
`;

export const TokenDescription = styled(Text).attrs({
  level: 2,
})`
  text-align: center;
  display: flex;
  gap: 15px;
`;

export const CollectionButton = styled(Button).attrs({
  type: 'secondary',
})`
  min-width: 125px;
  min-height: 50px;
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  position: relative;
`;

export const ModalCollectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 40px;

  .FTList {
    width: calc(100% + 40px);
    min-height: revert;
    max-height: 0;
  }

  .paginator {
    padding-bottom: 40px;
  }
`;
