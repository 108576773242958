import {ColorProps, DecorationProps, SubTitleBaseProps, TextBaseProps, TitleBaseProps, WeightProps} from './types';
import styled, {css} from 'styled-components';
import {Link, LinkProps} from 'react-router-dom';
import {theme} from '../theme';

const fontWeight = ({strong}: WeightProps) => {
  switch (strong) {
    case true:
      return 600;
    case false:
      return 400;
    default:
      return 400;
  }
};
const decoration = ({underline}: DecorationProps) => {
  return underline ? 'underline' : 'none';
};
const color = ({color}: ColorProps) => {
  return color ? theme.pallet.common[color] : theme.pallet.common.white;
};

const ellipsis = ({ellipsis}: TextBaseProps) => {
  return ellipsis ? 'overflow: hidden; text-overflow: ellipsis;' : '';
};
const fontSizeText = ({level}: TextBaseProps) => {
  switch (level) {
    case 1:
      return 'font-size: 16px; line-height: 24px';
    case 2:
      return 'font-size: 18px; line-height: 27px';
    case 3:
      return 'font-size: 20px; line-height: 27px';
    default:
      return 'font-size: 18px; line-height: 27px';
  }
};
const fontNoWrap = ({nowrap}: TextBaseProps) => {
  switch (nowrap) {
    case true:
      return 'white-space: nowrap';
    case false:
      return 'white-space: normal';
    default:
      return 'white-space: normal';
  }
};

const stylesText = css`
  font-family: Poppins, sans-serif;
  font-weight: ${fontWeight};
  text-decoration: ${decoration};
  ${ellipsis};
  ${fontNoWrap};
  ${fontSizeText};
`;
export const StyledText = styled.span<TextBaseProps>`
  ${stylesText};
  color: ${color};
`;
export const StyledLink = styled(Link)<TextBaseProps & LinkProps>`
  ${stylesText};
  color: ${color};
`;

const fontSizeTitle = ({level}: TitleBaseProps) => {
  switch (level) {
    case 1:
      return 'font-size: 22px; line-height: 32px';
    case 2:
      return 'font-size: 24px; line-height: 36px';
    case 3:
      return 'font-size: 28px; line-height: 42px';
    case 4:
      return 'font-size: 32px; line-height: 48px';
    default:
      return 'font-size: 22px; line-height: 32px';
  }
};
const stylesTitle = css`
  font-family: Poppins, sans-serif;
  font-weight: ${fontWeight};
  text-decoration: ${decoration};
  ${fontSizeTitle};
`;
export const StyledTitle = styled.span<TitleBaseProps>`
  ${stylesTitle};
  color: ${color};
`;

const fontTypeSubtitle = ({font}: SubTitleBaseProps) => {
  return font === 'primary' ? 'Lato, sans-serif' : 'Poppins, sans-serif';
};
const stylesSubtitle = css`
  font-family: ${fontTypeSubtitle};
  font-weight: ${fontWeight};
  font-size: 14px;
  line-height: 21px;
`;
const fontSizeSubtitle = ({level}: SubTitleBaseProps) => {
  switch (level) {
    case 1:
      return 'font-size: 10px; line-height: 14px';
    case 2:
      return 'font-size: 12px; line-height: 18px';
    case 3:
      return 'font-size: 14px; line-height: 21px';
    default:
      return 'font-size: 10px; line-height: 14px';
  }
};
const colorSubtitle = ({color}: ColorProps) => {
  return color ? theme.pallet.common[color] : theme.pallet.common.gray;
};
export const StyledSubtitle = styled.span<SubTitleBaseProps>`
  ${fontSizeSubtitle};
  ${stylesSubtitle};
  text-decoration: ${decoration};
  color: ${colorSubtitle};
`;
