import {useViewer} from './user';
import {SetterOrUpdater, useRecoilValue, useSetRecoilState} from 'recoil';
import {getFilteredTransactionsByTo, transactionState} from '../states/transactions';
import {getTransaction, getTransactionReceipt, tokenProperties} from './metamask';
import {useEffect, useState} from 'react';
import {blockTypes, Transaction, TypeOfTransactions} from '../types/transaction';
import {useTokens} from './tokens';
import {TokenTypes} from '../types/tokens';
import {useGetContacts} from './contacts';
import {toLocalTransaction} from '../helpers/transaction';
import {queryClient} from '../index';

export const useGetTransactionsList = (filter?: string): Transaction[] => {
  return useRecoilValue(getFilteredTransactionsByTo(filter || null));
};

export const useSetTransactionsList = (): SetterOrUpdater<Transaction[]> => {
  return useSetRecoilState(transactionState);
};

export const useTransactionsPending = () => {
  const transactions = useGetTransactionsList();
  const [loading, setLoading] = useState<boolean>(false);
  const setTransactionRecoil = useSetTransactionsList();
  const {addTokens, tokens} = useTokens();
  const {contacts} = useGetContacts();
  const viewer = useViewer();

  useEffect(() => {
    let idInterval: NodeJS.Timer | null = null;

    const waitForResult = async (transactionHash: string, transactionType: string, data: any) => {
      !loading && setLoading(true);
      try {
        const receiptTransaction = await getTransactionReceipt(transactionHash);
        if (!receiptTransaction) {
          idInterval && clearInterval(idInterval);
          idInterval = setInterval(() => {
            transactions.forEach(
              (item) => !item.status && waitForResult(item.transactionHash, item.transactionType, item.data),
            );
          }, 5000);
          return;
        }
        const mainTransaction = await getTransaction(transactionHash);
        let localTransaction = {
          ...receiptTransaction,
          data: data,
          transactionType: transactionType,
          value: mainTransaction?.value,
          input: mainTransaction?.input,
        };
        localTransaction = toLocalTransaction(localTransaction, contacts);
        switch (localTransaction?.transactionType) {
          case TypeOfTransactions.tokenCreate: {
            const prop = await tokenProperties(localTransaction?.contractAddress);
            if (!tokens.some((item) => item?.contractAddress === localTransaction?.contractAddress)) {
              addTokens({
                type: prop.decimals ? TokenTypes.FT : TokenTypes.NFT,
                contractAddress: localTransaction?.contractAddress,
                blockHash: blockTypes.latest,
                data: {
                  name: prop?.name || '',
                  symbol: prop?.symbol || '',
                  decimals: prop?.decimals || '',
                },
              });
            }
            break;
          }
        }
        let newTransactionsList: Transaction[] = [];
        setTransactionRecoil((transactionsList) => {
          newTransactionsList = transactionsList.map((transaction: Transaction) =>
            transaction.transactionHash === localTransaction?.transactionHash ? localTransaction : transaction,
          );
          localStorage.setItem(`transactions[${viewer?.address}]`, JSON.stringify(newTransactionsList));
          return newTransactionsList;
        });
        await queryClient.refetchQueries();
        if (newTransactionsList.every((item) => item.status)) {
          setLoading(false);
          idInterval && clearInterval(idInterval);
        } else {
          idInterval && clearInterval(idInterval);
          idInterval = setInterval(() => {
            transactions.forEach(
              (item) =>
                !item.status &&
                item.transactionHash !== transactionHash &&
                waitForResult(item.transactionHash, item.transactionType, item.data),
            );
          }, 5000);
        }
      } catch (e) {
        console.log(e);
      }
    };

    transactions.forEach(
      (item) => !item.status && waitForResult(item.transactionHash, item.transactionType, item.data),
    );

    return () => {
      idInterval && clearInterval(idInterval);
    };
  }, [transactions.length]);

  return {loading};
};

export const useTransactionsMethods = () => {
  const viewer = useViewer();
  const setTransactionRecoil = useSetTransactionsList();

  const addTransaction = (newTransactionsItem: Transaction) => {
    setTransactionRecoil((transactionsList) => {
      const localData = localStorage.getItem(`transactions[${viewer?.address}]`);
      const ParsedData = JSON.parse(localData || '[]');
      localStorage.setItem(
        `transactions[${viewer?.address}]`,
        JSON.stringify([
          {
            ...newTransactionsItem,
          },
          ...ParsedData,
        ]),
      );
      return [newTransactionsItem, ...transactionsList];
    });
  };

  const clearTransactions = () => {
    setTransactionRecoil([]);
    localStorage.setItem(`transactions[${viewer?.address}]`, '[]');
  };

  return {clearTransactions, addTransaction};
};

export const useGetTransaction = () => {
  const setTransactionRecoil = useSetTransactionsList();
  const transactions = useGetTransactionsList();
  const {contacts} = useGetContacts();
  const viewer = useViewer();
  useEffect(() => {
    const getLocalData = async () => {
      const localData = localStorage.getItem(`transactions[${viewer?.address}]`);
      if (localData) {
        try {
          const parseLocal = JSON.parse(localData);
          const items = parseLocal.map((item: any) => toLocalTransaction(item, contacts));
          setTransactionRecoil(items);
        } catch (e) {
          console.log(e);
        }
      }
    };
    getLocalData();
  }, [contacts.length]);

  return {transactions};
};
