import React from 'react';
import {LeftArrowWrapper, RightArrowWrapper, StepCircle, StepValue, StepWrapper, Wrapper} from './styles';
import {IconSvg} from '../Icons/Svg';
import {SeparatePoints} from '../Icons/SeparatePoints';

interface Props {
  current: number;
  total: number;
  onPage: React.Dispatch<React.SetStateAction<number>>;
}
export const Paginator: React.FC<Props> = ({current, total, onPage}) => {
  const forwardPage = () => {
    onPage(current + 1 <= total ? current + 1 : current);
  };
  const backPage = () => {
    onPage(current - 1 !== 0 ? current - 1 : current);
  };
  const currentPage = (page: number) => () => {
    onPage(page);
  };
  return (
    (total > 1 && (
      <Wrapper>
        {(current !== 1 && (
          <LeftArrowWrapper onClick={backPage}>
            <IconSvg type="arrowLeft2" width="9" height="16" viewBox="0 0 9 16" />
          </LeftArrowWrapper>
        )) || <LeftArrowWrapper />}
        <StepWrapper>
          {total <= 6 &&
            Array.from(Array(total).keys()).map((el) => (
              <StepCircle key={el + 1} theme={{current: current === el + 1}} onClick={currentPage(el + 1)}>
                <StepValue>{el + 1}</StepValue>
              </StepCircle>
            ))}
          {total > 6 && current + 4 < total && (
            <>
              <StepCircle theme={{current: current === 1}} onClick={currentPage(1)}>
                <StepValue>1</StepValue>
              </StepCircle>
              {current === 1 ? (
                <StepCircle onClick={currentPage(2)}>
                  <StepValue>2</StepValue>
                </StepCircle>
              ) : (
                <>
                  <SeparatePoints type="secondary" />
                  <StepCircle theme={{current: true}}>
                    <StepValue>{current}</StepValue>
                  </StepCircle>
                </>
              )}
              {Array.from(Array(current === 1 ? 3 : 2).keys()).map((el) =>
                current === 1 ? (
                  <StepCircle key={current + 2 + el} onClick={currentPage(current + 2 + el)}>
                    <StepValue>{current + 2 + el}</StepValue>
                  </StepCircle>
                ) : (
                  <StepCircle key={current + 1 + el} onClick={currentPage(current + 1 + el)}>
                    <StepValue>{current + 1 + el}</StepValue>
                  </StepCircle>
                ),
              )}
              {current + 4 < total && <SeparatePoints type="secondary" />}
              <StepCircle onClick={currentPage(total)}>
                <StepValue>{total}</StepValue>
              </StepCircle>
            </>
          )}
          {total > 6 && current + 4 >= total && (
            <>
              <StepCircle onClick={currentPage(1)}>
                <StepValue>1</StepValue>
              </StepCircle>
              <SeparatePoints type="secondary" />
              {Array.from(Array(5).keys())
                .reverse()
                .map((el) => (
                  <StepCircle key={el} theme={{current: total - el === current}} onClick={currentPage(total - el)}>
                    <StepValue>{total - el}</StepValue>
                  </StepCircle>
                ))}
            </>
          )}
        </StepWrapper>
        {(current !== total && (
          <RightArrowWrapper onClick={forwardPage}>
            <IconSvg type="arrowRight" width="9" height="16" viewBox="0 0 9 16" />
          </RightArrowWrapper>
        )) || <RightArrowWrapper />}
      </Wrapper>
    )) ||
    null
  );
};
