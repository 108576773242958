import React from 'react';
import {Wrapper, Item, ImageWrapper} from './styles';
import {Button} from '../../Buttons/Button';
import {Options} from './types';

interface PropsSelect {
  options?: Options[];
  name: string;
  onChange: (value: string, name: string) => void;
  selectOption: string;
}

const PrimarySelect = ({options, onChange, selectOption, name}: PropsSelect) => {
  const handleChange = (value: string) => () => {
    onChange(value, name);
  };
  return (
    <Wrapper>
      {options?.map((el, index) => (
        <Item key={index}>
          {(el?.image && el?.offImage && (el?.value || el.label) !== selectOption && (
            <ImageWrapper>{el.offImage}</ImageWrapper>
          )) ||
            (el?.image && el?.offImage && (el?.value || el.label) === selectOption && (
              <ImageWrapper>{el.image}</ImageWrapper>
            )) ||
            (el.image && <ImageWrapper>{el.image}</ImageWrapper>)}
          {
            <Button selected={selectOption === (el?.value || el.label)} onClick={handleChange(el.value || el.label)}>
              {el.label}
            </Button>
          }
        </Item>
      ))}
    </Wrapper>
  );
};

export const Select: React.FC<PropsSelect> = ({options, onChange, selectOption, name}) => {
  return <PrimarySelect name={name} onChange={onChange} selectOption={selectOption} options={options} />;
};
