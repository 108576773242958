import React, {PropsWithChildren} from 'react';
import {
  Table as TableStyle,
  Header,
  HeaderRow,
  HeaderCol,
  Row,
  Col,
  DetailsInfo,
  EmptyWrapper,
  EmptyDataWrapper,
  EmptyColumn,
  PaginatorWrapper,
  HeaderSecondary,
  HeaderRowSecondary,
  SecondaryRow,
  Tbody,
} from './styles';
import {PrimaryRowType, SelectionRowType, TableProps, HiddenContentType} from './types';
import {Loader} from '../Loader';
import {Paginator} from '../Paginator';

const PrimaryRows = <T,>({columns, dataSource, isStandard, type}: PrimaryRowType<T>) => {
  return (
    <>
      {dataSource.map((item, indexOfData) => (
        <Row $isStandard={isStandard} $type={type} key={indexOfData}>
          {columns.map((column, index) => (
            <Col key={`${String(column.key || column.dataIndex || index)}`}>
              {column?.render ? column.render(item) : '-'}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

const SecondaryRows = <T,>({columns, dataSource}: PrimaryRowType<T>) => {
  return (
    <>
      {dataSource.map((item, indexOfData) => (
        <SecondaryRow index={indexOfData} key={indexOfData}>
          {columns.map((column, index) => (
            <Col key={`${String(column.key || column.dataIndex || index)}`}>
              {column?.render ? column.render(item) : '-'}
            </Col>
          ))}
        </SecondaryRow>
      ))}
    </>
  );
};

export const SelectionRows = <T,>({
  columns,
  dataSource,
  rowSelection,
  pagination,
  isStandard,
  type,
}: SelectionRowType<T>) => {
  const {setItem, selectItemIndex, hiddenContent} = rowSelection;
  const getHiddenContent = (dataIndex: string): HiddenContentType | undefined => {
    return hiddenContent?.find((el) => el.dataIndex === dataIndex);
  };
  const indexCalculation = (index: number) => {
    if (pagination) {
      return (pagination.current - 1) * pagination.pageSize + index + 1;
    }
    return index;
  };

  return (
    <>
      {dataSource.map((item, indexOfData) => (
        <Row
          $isStandard={isStandard}
          key={indexOfData}
          $type={type}
          onClick={isStandard ? undefined : setItem(indexCalculation(indexOfData))}
          theme={{select: selectItemIndex === indexCalculation(indexOfData)}}>
          {columns.map((column, index) =>
            column.label === 'Details' && isStandard ? (
              <Col
                key={`${String(column.key || column.dataIndex || index)}`}
                onClick={setItem(indexCalculation(indexOfData))}>
                {getHiddenContent(column.dataIndex as string)
                  ? getHiddenContent(column.dataIndex as string)?.contentHidingComponent(
                      selectItemIndex === indexCalculation(indexOfData),
                    )
                  : column?.render
                  ? column.render(item, selectItemIndex === indexCalculation(indexOfData))
                  : '-'}
              </Col>
            ) : (
              <Col key={`${String(column.key || column.dataIndex || index)}`}>
                {getHiddenContent(column.dataIndex as string)
                  ? getHiddenContent(column.dataIndex as string)?.contentHidingComponent(
                      selectItemIndex === indexCalculation(indexOfData),
                    )
                  : column?.render
                  ? column.render(item, selectItemIndex === indexCalculation(indexOfData))
                  : '-'}
              </Col>
            ),
          )}
          {selectItemIndex === indexCalculation(indexOfData) &&
            columns.map(
              (column, index) =>
                getHiddenContent(column.dataIndex as string) && (
                  <DetailsInfo key={`${String(column.key || column.dataIndex || index)}`}>
                    {column?.render ? column.render(item, selectItemIndex === indexCalculation(indexOfData)) : '-'}
                  </DetailsInfo>
                ),
            )}
        </Row>
      ))}
    </>
  );
};

export const Table = <T,>({
  dataSource,
  columns,
  rowSelection,
  loading,
  pagination,
  emptyMessage,
  type = 'primary',
}: PropsWithChildren<TableProps<T>>) => {
  const isStandard = columns.some((item) => item.label);
  return (
    <>
      <TableStyle>
        {type === 'primary' || type === 'custom' ? (
          <Header $isHeader={isStandard}>
            <HeaderRow $type={type}>
              {columns.map(({label, key, dataIndex}, index) => (
                <HeaderCol key={`${String(key || dataIndex || index)}`}>{label}</HeaderCol>
              ))}
            </HeaderRow>
          </Header>
        ) : (
          <HeaderSecondary $isHeader={isStandard}>
            <HeaderRowSecondary>
              {columns.map(({label, key, dataIndex}, index) => (
                <HeaderCol key={`${String(key || dataIndex || index)}`}>{label}</HeaderCol>
              ))}
            </HeaderRowSecondary>
          </HeaderSecondary>
        )}
        <Tbody>
          {(dataSource.length > 0 && !loading && (
            <>
              {type === 'primary' || type === 'custom' ? (
                (rowSelection && (
                  <SelectionRows
                    isStandard={isStandard}
                    type={type}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                  />
                )) || <PrimaryRows isStandard={isStandard} columns={columns} dataSource={dataSource} type={type} />
              ) : (
                <SecondaryRows columns={columns} dataSource={dataSource} type={type} isStandard={isStandard} />
              )}
            </>
          )) ||
            (!loading && (
              <>
                {type === 'primary' || 'custom' ? (
                  <Row $type={type} $isStandard={isStandard}>
                    <EmptyColumn>
                      <EmptyDataWrapper>{emptyMessage || ''}</EmptyDataWrapper>
                    </EmptyColumn>
                  </Row>
                ) : (
                  <SecondaryRow index={0}>
                    <EmptyColumn>
                      <EmptyDataWrapper>{emptyMessage || ''}</EmptyDataWrapper>
                    </EmptyColumn>
                  </SecondaryRow>
                )}
              </>
            )) ||
            (loading && (
              <EmptyWrapper>
                <EmptyColumn>
                  <Loader />
                </EmptyColumn>
              </EmptyWrapper>
            ))}
        </Tbody>
      </TableStyle>
      {pagination && pagination.total > 1 && (
        <PaginatorWrapper className={'paginator'}>
          <Paginator current={pagination.current} total={pagination.total} onPage={pagination.handlePage} />
        </PaginatorWrapper>
      )}
    </>
  );
};

export default Table;
