import React, {useState} from 'react';
import {CardWrapper, Image, TokenName} from './styles';
import {CollectionItemType} from '../../../types/transaction';
import {CollectionItemDetailsContainer} from '../../../containers/Assets/CollectionItemDetails';
import noImage from './images/noImage.jpg';

interface IProps {
  data: CollectionItemType;
  tokenAddress: string;
}

const CollectionItem: React.FC<IProps> = ({data, tokenAddress}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const closeModalWindow = () => setIsWindowOpen(false);
  const openModalWindow = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsWindowOpen(true);
  };

  return (
    <>
      {isWindowOpen && (
        <CollectionItemDetailsContainer tokenAddress={tokenAddress} data={data} closeModalWindow={closeModalWindow} />
      )}
      <CardWrapper onClick={openModalWindow}>
        <Image src={data?.image?.length ? data?.image : noImage} />
        <TokenName>{data?.name || 'No Name'}</TokenName>
      </CardWrapper>
    </>
  );
};

export default CollectionItem;
