import React, {useState} from 'react';
import {Home} from '../../components/Home';
import {useGetBalanceTokens, useHistoryByUser} from '../../hooks/tokens';
import {useHistory} from 'react-router';
import {TokenTypes} from '../../types/tokens';
import {useGetInstances} from '../../hooks/validator';
import {route} from '../../constants/route';
import {useViewer} from '../../hooks/user';

export const HomeContainer = () => {
  const {historyList, loading} = useHistoryByUser(5);
  const [type, setType] = useState<TokenTypes>(TokenTypes.FT);

  const handleSetType = (type: TokenTypes) => {
    setType(type);
  };

  const {push} = useHistory();

  const detailPageForward = (index: number) => {
    push(`/assets/${type || TokenTypes.FT}/${tokensList[index]?.actions}`);
  };
  const {tokensList, loading: LoadingBalance} = useGetBalanceTokens(type || TokenTypes.FT, 5);
  const {data, loading: loadingValidator, updateValidatorStatus} = useGetInstances(5, route.homePage);
  const viewer = useViewer();
  const stakingAccess = !Boolean(viewer?.isVerified && viewer?.kycData?.country_code_parsed === 'USA');
  return (
    <Home
      stakingAccess={stakingAccess}
      dataInstances={data}
      loadingValidator={loadingValidator}
      handleSetType={handleSetType}
      type={type}
      updateValidatorStatus={updateValidatorStatus}
      detailPageForward={detailPageForward}
      loadingBalance={LoadingBalance}
      balanceList={tokensList}
      historyList={historyList}
      loadingHistory={loading}
    />
  );
};
