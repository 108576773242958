import React, {ChangeEvent, SetStateAction, useState} from 'react';

import {
  BackGroundQrCodeContainer,
  ContactsButton,
  InputSearch,
  InputWrapper,
  QrCodeContainer,
  TableContainer,
  Address,
  NameWrapper,
  TitleWrapper,
  TitleText,
  ButtonWrapper,
  AsideWrapper,
} from './styles';
import {Columns} from '../../ui-kit/Table/types';
import {CopyIcon} from '../../ui-kit/Icons/CopyIcon';
import List from './List';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import QRCode from 'react-qr-code';
import {ContactType} from '../../types/contacts';
import {AddContactForm, AddContactQR} from './Form';
import {Layout} from '../../ui-kit/Layout';
import {ActionButton} from './ActionButton';
import Avatar from 'react-avatar';
import {IconSvg} from '../../ui-kit/Icons/Svg';

type ContactsPagePropsType = {
  contacts: ContactType[];
  addContact: (contact: ContactType) => void;
  editContact: (contact: ContactType, oldContact: ContactType) => void;
  deleteContact: (address: string) => void;
  currentAddress: string | undefined;
  searchValue: string;
  setSearchValue: (value: string) => void;
  setPage: React.Dispatch<SetStateAction<number>>;
  page: number;
  count: number;
  pageSize: number;
  error: string;
};

export const ContactsPage: React.FC<ContactsPagePropsType> = ({
  contacts,
  addContact,
  searchValue,
  setSearchValue,
  currentAddress,
  editContact,
  deleteContact,
  setPage,
  page,
  count,
  pageSize,
  error,
}) => {
  const onInputSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };
  const pageCount = Math.ceil(count / pageSize);
  const [scan, setScan] = useState<boolean>(false);
  const handlerScan = () => {
    setScan(!scan);
  };
  const columns: Columns<ContactType> = [
    {
      label: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (item: ContactType) => (
        <NameWrapper>
          <Avatar name={item.name} size={'46'} round="50%" />
          {item.name}
        </NameWrapper>
      ),
    },
    {
      label: 'Address',
      key: 'address',
      dataIndex: 'address',
      render: (item: ContactType) => (
        <>
          <Address>{item.address}</Address>
          <div>
            <CopyIcon dataToCopy={item.address} />
          </div>
        </>
      ),
    },
    {
      label: 'Actions',
      dataIndex: 'actions',
      render: (item: ContactType) => (
        <ButtonWrapper>
          <ActionButton title={'Send'} buttonValue={'Send'} sendTransaction={{address: item.address}} />
          <ActionButton
            title={'Edit'}
            buttonValue={'Edit'}
            editContact={{onSubmit: editContact, deleteContact, contact: item}}
          />
        </ButtonWrapper>
      ),
    },
  ];

  return (
    <Layout>
      <InputWrapper>
        <InputSearch
          value={searchValue}
          onChange={onInputSearchChangeHandler}
          type="text"
          placeholder={'Enter...'}
          spellCheck="false"
        />
        <IconSvg width="33" height="34" viewBox="0 0 33 34" type="search" />
      </InputWrapper>
      <TitleWrapper>
        <TitleText>Contacts</TitleText>
      </TitleWrapper>
      {scan && <AddContactQR onSubmit={addContact} closeModal={handlerScan} />}
      <Layout
        RightSideBar={
          <AsideWrapper>
            <QrCodeContainer>
              {currentAddress ? (
                <BackGroundQrCodeContainer>
                  <QRCode value={`ethereum:${currentAddress}`} size={160} />
                </BackGroundQrCodeContainer>
              ) : (
                'Invalid account'
              )}
            </QrCodeContainer>
            <ContactsButton onClick={handlerScan}>Scan QR Code</ContactsButton>
            <AddContactForm onSubmit={addContact} error={error} />
          </AsideWrapper>
        }>
        <TableContainer>
          <List
            data={contacts}
            columns={columns}
            paginator={{current: page, pageSize: pageSize, total: pageCount, handlePage: setPage}}
          />
        </TableContainer>
      </Layout>
    </Layout>
  );
};
