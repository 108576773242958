import React, {Dispatch, SetStateAction, useCallback, useMemo} from 'react';
import {Action, BalanceWrapper, Name, NameWrapper, ViewCollection} from './styles';
import {Columns} from '../../../ui-kit/Table/types';
import {IBalance} from '../../../types/tokens';
import {Text} from '../../../ui-kit/Typography/Typography';
import Table from '../../../ui-kit/Table';
import Avatar from 'react-avatar';
import {WATERFALL_SERVER} from '../../../constants/env';

interface IPropsBalance {
  balanceList: IBalance[];
  setPageBalance?: Dispatch<SetStateAction<number>>;
  pageBalance?: number;
  pageSizeBalance?: number;
  totalCountBalance?: number;
  loadingBalance: boolean;
  detailPageForward: (index: number) => void;
}

export const NFTList: React.FC<IPropsBalance> = ({
  balanceList,
  setPageBalance,
  pageSizeBalance,
  pageBalance,
  totalCountBalance,
  detailPageForward,
  loadingBalance,
}) => {
  const columns: Columns<IBalance> = [
    {
      key: 'name',
      dataIndex: 'name',
      render: (item: IBalance) => (
        <NameWrapper>
          <Avatar
            size={'46'}
            name={item.name}
            src={item.imagePath ? `${WATERFALL_SERVER}${item.imagePath}` : '#'}
            round="50%"
          />
          <Name>{item.name}</Name>
        </NameWrapper>
      ),
    },
    {
      key: 'balance',
      dataIndex: 'balance',
      render: (item: IBalance) => (
        <Text color={'blueGreen'} strong={true} level={2}>
          {item.balance} {item.symbol}
        </Text>
      ),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      render: () => (
        <Action>
          <ViewCollection>
            <Text color={'blueGreen'} nowrap={true} level={1} strong={true}>
              View Collection
            </Text>
          </ViewCollection>
        </Action>
      ),
    },
  ];

  const handlerChange = useCallback(
    (index: number) => () => {
      detailPageForward(index);
    },
    [detailPageForward],
  );

  const memoizedChange = useMemo(() => {
    return {setItem: handlerChange};
  }, [handlerChange]);

  const memoizedPagination = useMemo(() => {
    if (pageBalance && pageSizeBalance && totalCountBalance && setPageBalance)
      return {
        current: pageBalance,
        pageSize: pageSizeBalance,
        total: totalCountBalance,
        handlePage: setPageBalance,
      };
  }, [pageBalance, pageSizeBalance, totalCountBalance, setPageBalance]);

  return (
    <BalanceWrapper>
      <Table<IBalance>
        loading={loadingBalance}
        emptyMessage={'There are no NFT tokens yet'}
        type={'primary'}
        dataSource={balanceList}
        columns={columns}
        rowSelection={memoizedChange}
        pagination={memoizedPagination}
      />
    </BalanceWrapper>
  );
};
