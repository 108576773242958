import styled, {css, StyledProps} from 'styled-components';
import {StyledButtonProps} from './types';
import {Link as RouteLink} from 'react-router-dom';
import {theme} from '../theme';

const color = ({color, variant, selected}: StyledButtonProps) => {
  switch (color) {
    case 'primary':
      return theme.pallet.common.white;
    case 'inherit':
      return '';
    case 'secondary':
      return selected ? theme.pallet.common.white : theme.pallet.common.blue;
    case 'success':
      return '#3CFF33'; // mock color
    case 'error':
      return '#FF4F33'; // mock color
    case 'info':
      return '#33FFDA'; // mock color
    case 'warning':
      return '#FF9633'; // mock color
    default:
      if (typeof color === 'string') {
        return color;
      }
      if (variant === 'primary') {
        return theme.pallet.common.white;
      }
      if (variant === 'secondary') {
        return selected ? theme.pallet.common.white : theme.pallet.common.blue;
      }
      return theme.pallet.common.white;
  }
};

const width = (props: StyledButtonProps) => {
  if (props.block) {
    return '100%';
  }
  return props.width ? props.width : '100%';
};

const height = (props: StyledButtonProps) => {
  return props.height ? props.height : '100%';
};

const border = (props: StyledProps<StyledButtonProps>) => {
  if (props.variant == 'secondary') {
    return `2px solid ${theme.pallet.blueScale._3}`;
  }
  return 'none';
};

const background = (props: StyledProps<StyledButtonProps>) => {
  if (props.ghost) {
    return '';
  }
  switch (props.variant) {
    case 'primary':
      return 'linear-gradient(270.32deg, rgba(0, 226, 255, 0.62) -4.89%, rgba(0, 142, 238, 0.62) 85.28%), #008EEE';
    case 'secondary':
      return props.disabled
        ? theme.pallet.background.quinary
        : props.selected
        ? theme.pallet.background.senary
        : 'none';
    default:
      if (props.disabled) {
        return theme.pallet.background.quaternary;
      }
      return props.selected ? theme.pallet.background.septenary : theme.pallet.background.primary;
  }
};

const lineHeightDesktop = (props: StyledProps<StyledButtonProps>) => {
  switch (props.size) {
    case 'large':
      return '30px';
    case 'default':
      return '27px';
    case 'small':
      return '24px';
    default:
      return '24px';
  }
};

const fontSize = (props: StyledProps<StyledButtonProps>) => {
  switch (props.size) {
    case 'large':
      return '20px';
    case 'default':
      return '18px';
    case 'small':
      return '16px';
    default:
      return '18px';
  }
};

const fontWeight = (props: StyledProps<StyledButtonProps>) => {
  switch (props.size) {
    case 'large':
      return 800;
    case 'default':
      return 600;
    case 'small':
      return 400;
    default:
      return 600;
  }
};

const borderRadius = (props: StyledProps<StyledButtonProps>) => {
  switch (props.shape) {
    case 'circle':
      return '50%';
    case 'default':
      return 0;
    case 'round':
      return '10px';
    default:
      return '10px';
  }
};

const hover = ({variant, disabled}: StyledButtonProps) => {
  if (!disabled) {
    switch (variant) {
      case 'primary':
        return 'hover {background: linear-gradient(270.32deg, rgba(0, 142, 238, 0.62) -4.89%, rgba(0, 226, 255, 0.62) 104.98%), #008EEE;}';
      case 'secondary':
        return 'hover {background: #05AB9F; color: #FFffff;span {color: #FFffff};path {fill: #FFffff};}';
      default:
        return '';
    }
  }
};

const styles = css`
  box-sizing: border-box;
  align-items: center;
  background: ${background};
  border: ${border};
  color: ${color};
  display: flex;
  justify-content: center;
  position: relative;
  width: ${width};
  height: ${height};
  text-decoration: none;
  border-radius: ${borderRadius};
  font-family: Poppins, sans-serif;
  font-style: normal;
  line-height: ${lineHeightDesktop};
  font-weight: ${fontWeight};
  font-size: ${fontSize};
  :${hover};
`;

export const Button = styled.button<StyledButtonProps>`
  ${styles};
  cursor: pointer;
`;

export const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-underline: none;
`;

export const LinkRoute = styled(RouteLink)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-underline: none;
`;
