import {atom, selectorFamily} from 'recoil';
import {TokensType} from '../types/tokens';

export const tokensState = atom<TokensType[]>({
  key: 'tokensState',
  default: [],
});

export const getFilteredTokensType = selectorFamily<TokensType[], string | undefined>({
  key: 'getFilteredTokensType',
  get:
    (filter) =>
    ({get}) => {
      const tokenList = get(tokensState);
      if (!filter) {
        return tokenList;
      }
      return tokenList.filter((token) => token.type === filter);
    },
});

export const getFilteredTokensAddress = selectorFamily<TokensType[], string | undefined>({
  key: 'getFilteredTokensAddress',
  get:
    (filter) =>
    ({get}) => {
      const tokenList = get(tokensState);
      if (!filter) {
        return tokenList;
      }
      return tokenList.filter((token) => token.contractAddress === filter);
    },
});
