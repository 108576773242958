import {ServerOperationsTypes, Transaction} from '../types/transaction';
import {operationTypes, TokensType, TransactionTable, TransactionType} from '../types/tokens';
import {tokenProperties} from '../hooks/metamask';
import {toLocalDate} from './format';
import {ContactType} from '../types/contacts';

export const truncate = (str: string) => {
  return str.length > 20 ? str.slice(0, 10) + '…' + str.slice(str.length - 10, str.length) : str;
};

export const toLocalTransaction = (transaction: Transaction, contacts: ContactType[]): Transaction => {
  const contact =
    contacts?.filter((contact) => {
      if (contact.address?.toLowerCase() === transaction.data?.recipient?.toLocaleLowerCase()) return contact.name;
    })[0]?.name || '';
  return {
    transactionType: transaction.transactionType,
    blockHash: transaction.blockHash || '',
    blockNumber: transaction.blockNumber || 0,
    contractAddress: transaction.contractAddress || '',
    cumulativeGasUsed: transaction.cumulativeGasUsed || 0,
    effectiveGasPrice: transaction.effectiveGasPrice || 0,
    from: transaction.from || '',
    gasUsed: transaction.gasUsed || 0,
    logs: transaction.logs || [],
    logsBloom: transaction.logsBloom || '',
    status: transaction.status || false,
    to: transaction.to || '',
    transactionHash: transaction.transactionHash || '',
    transactionIndex: transaction.transactionIndex || 0,
    type: transaction.type || '',
    value: transaction?.data?.amount || transaction?.value || '',
    input: transaction.input || '',
    contactName: contact,
    data: transaction?.data,
  };
};

const getOperationType = (operation: string): ServerOperationsTypes => {
  let type = '';
  switch (operation) {
    case '0x0c': {
      type = 'Create';
      break;
    }
    case '0x1e': {
      type = 'Transfer';
      break;
    }
    case '0x1f': {
      type = 'Transfer';
      break;
    }
    case '0x26': {
      type = 'Mint';
      break;
    }
    case '0x27': {
      type = 'Burn';
      break;
    }
    default: {
      type = 'Transfer';
      break;
    }
  }
  return <ServerOperationsTypes>type;
};

export const toLocalTableTransaction = async (
  transaction: TransactionType[],
  viewer: string,
  contacts: ContactType[],
  tokens: TokensType[],
): Promise<TransactionTable[]> => {
  const result = transaction.map(async (item) => {
    const operation = getOperationType(item?.decodeInput?.operation || '');
    let tokenProp: any = tokens.filter(
      (tk) =>
        tk.contractAddress === item?.to ||
        (tk?.data?.name === item?.decodeInput?.name && tk?.data?.symbol === item?.decodeInput?.symbol),
    )[0];
    if (!tokenProp && item?.to) {
      try {
        const prop = await tokenProperties(item?.to);
        tokenProp = {
          data: prop,
        };
      } catch {
        tokenProp = undefined;
      }
    }
    const operationType = item?.decodeInput?.to
      ? item?.decodeInput?.to?.toLowerCase() === viewer.toLowerCase()
        ? operationTypes.from
        : operationTypes.to
      : item?.from.toLowerCase() === viewer?.toLowerCase() && !item?.to
      ? operationTypes.from
      : item?.to?.toLowerCase() === viewer?.toLowerCase()
      ? operationTypes.from
      : operationTypes.to;
    const addressField = operationType === operationTypes.from ? item?.from : item?.decodeInput?.to || item?.to;
    const contact = contacts.find((contact) => contact.address.toLowerCase() === addressField?.toLowerCase())?.name;
    return {
      address: addressField,
      contactName: addressField?.toLowerCase() === viewer?.toLowerCase() ? 'Me' : contact ? contact : '',
      operationType: operationType,
      date: toLocalDate(item.timestamp * 1000),
      link: item.hash,
      name: tokenProp?.data?.name
        ? tokenProp?.data?.name
        : item?.decodeInput?.name
        ? item?.decodeInput?.name
        : item?.decodeInput
        ? '—'
        : 'Water',
      symbol: tokenProp?.data?.symbol
        ? tokenProp?.data?.symbol
        : item?.decodeInput?.symbol
        ? item?.decodeInput?.symbol
        : item?.decodeInput
        ? '—'
        : 'Water',
      value:
        item?.decodeInput && item?.decodeInput.standard !== 721
          ? item?.decodeInput?.value || item?.decodeInput?.totalSupply
            ? item?.decodeInput?.value && item?.decodeInput?.value !== '0'
              ? item?.decodeInput?.value
              : String(item?.decodeInput?.totalSupply || '')
            : item?.value && item?.value !== '0'
            ? item?.value
            : '0'
          : String(item?.value),
      tokenId:
        item?.decodeInput && item?.decodeInput.standard !== 20
          ? item?.decodeInput?.value && item?.decodeInput?.tokenId
            ? item?.decodeInput?.tokenId !== '0'
              ? item?.decodeInput?.tokenId
              : item?.decodeInput?.value
            : '—'
          : String(item?.value),
      operation: operation,
    };
  });
  return await Promise.all(result);
};
