import React from 'react';
import {SwitchBall, SwitchWrapper} from './styles';

interface IProps {
  onClick: () => void;
  checked: boolean;
}

export const Switch: React.FC<IProps> = ({onClick, checked}) => {
  return (
    <SwitchWrapper onClick={onClick}>
      <SwitchBall $checked={checked} />
    </SwitchWrapper>
  );
};
