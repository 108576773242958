import {gql} from '@apollo/client';

export const Rates = gql`
  fragment RatesFragment on Rate {
    datacenter
    zones {
      name
      regions {
        id
        name
        priceAmount
        priceId
        productId
        countWorker
        workerPrices
      }
    }
  }
`;
