import {ServerOperationsTypes} from './transaction';

export interface IFieldsMineToken {
  to?: {value?: string; error?: string};
  tokenID?: {value?: string; error?: string};
  external_url?: {value?: string; error?: string};
  image_url?: {value?: string; error?: string};
  name?: {value?: string; error?: string};
}

export interface IFieldsMintData {
  to: string;
  tokenID: string;
  external_url?: string;
  image_url?: string;
  name?: string;
  attributes?: {type: string; label: string; value: string | Date | boolean; error: string}[];
}

export interface IFieldsCreateTokenFT {
  name?: {value?: string; error?: string};
  symbol?: {value?: string; error?: string};
  decimals?: {value?: string; error?: string};
  total?: {value?: string; error?: string};
  img?: {value?: string; error?: string; file?: File};
}

export interface IFieldsCreateTokenNFT {
  name?: {value?: string; error?: string};
  symbol?: {value?: string; error?: string};
  url?: {value?: string; error?: string};
  img?: {value?: string; error?: string; file?: File};
}

export interface IFieldsSendToken {
  address?: {value?: string; error?: string};
  value?: {value?: string; error?: string};
}

export interface IOptions {
  name?: string;
  symbol?: string;
  decimals?: string;
  totalSupply?: string;
  baseURI?: string;
}

export type TokensType = {
  type: TokenTypes;
  contractAddress: string;
  blockHash: string;
  data: {name: string; symbol: string; decimals?: string; totalSupply?: string; image?: File};
};

export interface IProperty {
  name?: string;
  symbol?: string;
  decimals?: string;
  totalSupply?: string;
  byTokenId?: ITokenId;
}

export interface ITokenId {
  tokenURI?: string;
  ownerOf?: string;
  getApproved?: string;
  metadata?: string;
}

export enum FilterByTypes {
  byType = 'byType',
  byAddress = 'byAddress',
}

export enum TokenTypes {
  FT = 'ft',
  NFT = 'nft',
}

export interface IBalance {
  type: TokenTypes;
  name: string;
  symbol: string;
  balance: number;
  actions: string;
  imagePath?: string;
}

export enum operationTypes {
  from = 'from',
  to = 'to',
}

export interface TransactionTable {
  address: string;
  contactName: string;
  operationType: operationTypes;
  value: string;
  name: string;
  symbol: string;
  date: string;
  link: string;
  tokenId: string;
  operation: ServerOperationsTypes;
}

export interface block {
  baseFeePerGas: string;
  epoch: number;
  extraData: string;
  gasLimit: number;
  gasUsed: number;
  hash: string;
  height: number;
  logsBloom: string;
  miner: string;
  mixHash: string;
  nonce: string;
  number: number;
  parentHashes: string[];
  receiptsRoot: string;
  size: number;
  slot: number;
  stateRoot: string;
  timestamp: number;
  transactions: string[];
  transactionsRoot: string;
}

export type TransactionType = {
  blockNumber: number;
  from: string;
  gas: number;
  gasPrice: string;
  maxFeePerGas: string;
  maxPriorityFeePerGas: string;
  hash: string;
  input: string;
  nonce: number;
  to: string;
  transactionIndex: number;
  value: string | null;
  type: number;
  accessList: [];
  chainId: string;
  v: string;
  r: string;
  s: string;
  blockHashes: string[];
  timestamp: number;
  decodeInput: decode;
};

interface decode {
  standard: number;
  commonAddress: string;
  tokenId: string;
  owner: string;
  spender: string;
  operator: string;
  from: string;
  to: string;
  value: string;
  index: string;
  isApproved: string;
  data: string;
  operation: string;
  decimal: number;
  name: string;
  symbol: string;
  totalSupply: string;
}
