import React, {FormEvent, useEffect, useState} from 'react';
import {Input} from '../../ui-kit/Form/Input';
import {
  AddContactFormContainer,
  AddContactFormContainerQr,
  ContactsButton,
  FormModalContainer,
  ModalButtonWrapper,
  StyledModalButton,
  DeleteButton,
  TitleText,
} from './styles';
import {ContactType} from '../../types/contacts';
import {QrReader} from 'react-qr-reader';
import {ModalWindow} from '../../ui-kit/Modal';
import {InputAutocomplete} from '../../ui-kit/Form/InputAutocomplete';
import {ErrorMessage} from '../../ui-kit/Form/ErrorMessage/ErrorMessage';

export type SendTransactionFormProps = {
  onSubmit: (to: string, amount: string) => Promise<boolean>;
  sendTo?: string;
  noModal?: boolean;
  contacts: ContactType[];
  error: string;
};

export type EditContactProps = {
  onSubmit: (item: ContactType, oldItem: ContactType) => void;
  deleteContact: (address: string) => void;
  contact: ContactType;
  closeModal: () => void;
};

type AddContactFormPropsType = {
  onSubmit: (item: ContactType) => void;
  error: string;
};
type AddContactFormPropsTypeQR = {
  onSubmit: (item: ContactType) => void;
  closeModal: () => void;
};

export const SendTransactionForm: React.FC<SendTransactionFormProps> = ({
  onSubmit,
  sendTo,
  noModal,
  contacts,
  error,
}) => {
  const [fields, setFields] = useState<{address: string; amount: string}>({address: sendTo || '', amount: ''});

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (fields.address && fields.amount) {
      const result = await onSubmit(fields.address, fields.amount);
      if (result) {
        setFields({address: '', amount: ''});
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'amount' && !isNaN(Number(value)) && !value.includes(' ')) {
      setFields({...fields, amount: value});
    } else if (name === 'address' && !value.includes(' ')) {
      setFields({...fields, address: value});
    }
  };

  const handleSetAddress = (address: string) => {
    setFields({
      ...fields,
      address: address,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormModalContainer $noModal={noModal}>
        {noModal && <TitleText>Send Water</TitleText>}
        <InputAutocomplete
          data={contacts}
          autocompleteField={'address'}
          promptField={'address'}
          searchField={'name'}
          setValue={handleSetAddress}
          name={'address'}
          onChange={handleChange}
          value={fields.address}
          placeholder={'Address'}
        />
        <Input placeholder={'Amount'} onChange={handleChange} value={fields.amount} name={'amount'} />
        {error && <ErrorMessage errorMessage={error} />}
        <ModalButtonWrapper>
          <StyledModalButton type={'secondary'}>Send</StyledModalButton>
        </ModalButtonWrapper>
      </FormModalContainer>
    </form>
  );
};

export const EditContact: React.FC<EditContactProps> = ({contact, onSubmit, deleteContact, closeModal}) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: {value: string};
      address: {value: string};
    };
    const address = target.address.value;
    const name = target.name.value;
    if (address.trim() && name.trim()) {
      onSubmit({name, address}, contact);
      closeModal();
    }
  };

  const handlerDelete = () => {
    deleteContact(contact.address);
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormModalContainer>
        <Input placeholder={'Name'} defaultValue={contact.name} name={'name'} />
        <Input placeholder={'Address'} defaultValue={contact.address} name={'address'} />
        <ModalButtonWrapper $space={true}>
          <DeleteButton onClick={handlerDelete} type={'secondary'}>
            Delete
          </DeleteButton>
          <StyledModalButton type={'secondary'}>Save</StyledModalButton>
        </ModalButtonWrapper>
      </FormModalContainer>
    </form>
  );
};

export const AddContactForm: React.FC<AddContactFormPropsType> = ({onSubmit, error}) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: {value: string};
      address: {value: string};
    };
    const name = target.name.value;
    const address = target.address.value;
    if (name.trim() && address.trim()) {
      onSubmit({name, address});
      e.currentTarget.reset();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <AddContactFormContainer>
        <Input placeholder={'Name'} name={'name'} />
        <Input placeholder={'Address'} name={'address'} />
        {error && <ErrorMessage errorMessage={error} />}
        <ContactsButton>Add Contact</ContactsButton>
      </AddContactFormContainer>
    </form>
  );
};

export const AddContactQR: React.FC<AddContactFormPropsTypeQR> = ({onSubmit, closeModal}) => {
  const [data, setData] = useState<string>('');
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: {value: string};
      address: {value: string};
    };
    const name = target.name.value;
    const address = target.address.value;
    if (name.trim() && address.trim()) {
      onSubmit({name, address});
      closeModal();
      e.currentTarget.reset();
    }
  };

  useEffect(() => {
    if (data) {
      const Address = document.getElementById('Address');
      Address?.setAttribute('value', data.replace('ethereum:', ''));
    }
  }, [data]);

  return (
    <ModalWindow visible={true} onClose={closeModal}>
      <AddContactFormContainerQr>
        <form onSubmit={handleSubmit}>
          <QrReader
            constraints={{width: 100}}
            videoId="video"
            onResult={(result) => {
              if (!!result) {
                setData(result.getText());
              }
            }}
            containerStyle={{
              width: '328px',
              border: 'none',
              marginTop: '-30px',
              marginBottom: '20px',
              padding: 0,
              background: '#202227',
            }}
            videoContainerStyle={{borderRadius: '10px'}}
            videoStyle={{borderRadius: '10px'}}
          />
          <Input placeholder={'Name'} name={'name'} />
          <Input placeholder={'Address'} name={'address'} id={'Address'} />
          <ContactsButton>Add Contact</ContactsButton>
        </form>
      </AddContactFormContainerQr>
    </ModalWindow>
  );
};
