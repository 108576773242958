export enum TypeOfTransactions {
  tokenCreate = 'tokenCreate',
  tokenSendFT = 'tokenSendFT',
  tokenSendNFT = 'tokenSendNFT',
  sendWater = 'sendWater',
  tokenMint = 'tokenMint',
  tokenBurn = 'tokenBurn',
}

export enum ServerOperationsTypes {
  Create = 'Create',
  Transfer = 'Transfer',
  Mint = 'Mint',
  Burn = 'Burn',
  Unknown = 'Unknown',
}

export interface TransactionType {
  gasPrice?: string | number;
  gas?: string | number;
  to?: string;
  from?: string;
  value?: string | number;
  data?: string;
  chainId?: string;
}

export interface Transaction {
  transactionType: TypeOfTransactions;
  blockHash?: string;
  blockNumber?: number;
  contractAddress?: string;
  cumulativeGasUsed?: number;
  effectiveGasPrice?: number;
  from?: string;
  gasUsed?: number;
  logs?: any[];
  logsBloom?: string;
  status?: boolean;
  to?: string;
  transactionHash: string;
  transactionIndex?: number;
  type?: string;
  input?: string;
  value?: string;
  contactName?: string;
  data?: any;
}
export enum blockTypes {
  earliest = 'earliest',
  latest = 'latest',
  pending = 'pending',
}

export interface CollectionItemType {
  attributes: Array<{trait_type: any; value: any}>;
  external_url: string;
  image: string;
  name: string;
  tokenID: string;
}
