import {gql} from '@apollo/client';
import {User} from './user';

export const Session = gql`
  fragment SessionFragment on Viewer {
    sessionToken
    user {
      ...UserFragment
    }
  }
  ${User}
`;
