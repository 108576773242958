import {InputBaseProps} from './types';
import styled, {css} from 'styled-components';
import Cleave from 'cleave.js/react';
import {Props as CleaverProps} from 'cleave.js/react/props';
import {theme} from '../../theme';
import {Text} from '../../Typography/Typography';

const color = ({disabled, status, color, placeholdertype}: InputBaseProps) => {
  if (disabled) {
    return theme.pallet.common.white;
  }
  if (status === 'error') {
    return theme.pallet.common.red;
  }
  if (placeholdertype === 'secondary') {
    return theme.pallet.blueScale._4;
  }
  if (color) {
    return color;
  }
  return theme.pallet.common.white;
};

const border = ({status}: InputBaseProps) => {
  if (status === 'error') {
    return `1px solid ${theme.pallet.common.red}`;
  }
  return '1px solid rgba(0, 0, 0, 0)';
};
const alignText = ({aligntext}: InputBaseProps) => {
  if (aligntext) {
    return aligntext;
  }
  return 'left';
};
export const fontSize = ({sizetype}: InputBaseProps) => {
  switch (sizetype) {
    case 'small':
      return '14px';
    case 'middle':
      return '18px';
    default:
      return '18px';
  }
};

const height = ({sizetype}: InputBaseProps) => {
  switch (sizetype) {
    case 'large':
      return '72px';
    case 'middle':
      return '60px';
    case 'small':
      return '48px';
    default:
      return '60px';
  }
};
export const fontWeight = ({sizetype}: InputBaseProps) => {
  switch (sizetype) {
    case 'large':
      return 600;
    case 'middle':
      return 400;
    default:
      return 400;
  }
};

const background = ({disabled}: InputBaseProps) => {
  if (disabled) {
    return theme.pallet.background.quinary;
  }
  return theme.pallet.background.primary;
};

const placeholderColor = ({placeholdertype}: InputBaseProps) => {
  if (placeholdertype === 'secondary') {
    return `:placeholder {color: ${theme.pallet.blueScale._4}`;
  }
  if (placeholdertype === 'ghost') {
    return `:placeholder {color: ${theme.pallet.common.grey7}`;
  }
  return `:placeholder {color:  ${theme.pallet.common.white}}`;
};

export const styles = css`
  font-family: Poppins, sans-serif;
  font-size: ${fontSize};
  text-align: ${alignText}!important;
  font-weight: ${fontWeight};
  text-decoration: none;
  font-style: normal;
  line-height: 27px;
  color: ${color}!important;
  -webkit-appearance: none;
  filter: none;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus{
    background-image: none!important;
    -webkit-text-fill-color: ${color};
    transition: background-color 5000s ease-in-out 0s;
  }
  background-color: ${background};
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  : ${placeholderColor};
`;

const stylesWrapper = css`
  background: ${background};
  border: ${border};
  height: ${height};
`;

export const InputWrapper = styled.div<InputBaseProps>`
  ${stylesWrapper};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 0 18px;
  width: calc(100% - 36px);
  position: relative;
`;

export const InnerInput = styled.input<InputBaseProps>`
  ${styles};
`;

export const CleaveInput = styled(Cleave)<InputBaseProps & CleaverProps>`
  ${styles};
`;

export const InnerLabel = styled.label`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Title = styled(Text).attrs({
  color: 'gray',
  level: 1,
})`
  line-height: 19px;
  margin-bottom: 15px;
  margin-left: 5px;
`;

export const ErrorMessageWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 70px;
  left: 0px;
  width: 100%;
`;
