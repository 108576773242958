import styled from 'styled-components';
import {theme} from '../theme';
import {Text} from '../Typography/Typography';

export const Table = styled.table.attrs({
  cellSpacing: 0,
  cellPadding: 0,
})`
  border: none;
  width: 100%;
`;

export const Tbody = styled.tbody`
  & > tr:last-child {
    border-radius: 0 0 10px 10px;
  }
`;

export const Header = styled.thead<{$isHeader: boolean}>`
  display: ${({$isHeader}) => ($isHeader ? 'flex' : `inline-flex`)};
  justify-content: center;
  background: ${theme.pallet.background.quinary};
  height: ${({$isHeader}) => ($isHeader ? '71px' : `0px`)};
  border-radius: 10px;
  color: ${theme.pallet.common.gray};
  width: 100%;
  margin-bottom: 15px;
`;

export const HeaderSecondary = styled.thead<{$isHeader: boolean}>`
  display: flex;
  justify-content: center;
  background: ${theme.pallet.common.grey6};
  height: ${({$isHeader}) => ($isHeader ? '71px' : 0)};
  border-radius: 10px 10px 0 0;
  color: ${theme.pallet.common.grey7};
  width: 100%;
`;

export const HeaderRow = styled.tr<{$type?: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  height: 71px;
  padding: 0 15px 0 15px;
  width: calc(100% - 30px);
  & > th:nth-child(1) {
    display: flex;
    width: ${({$type}) => ($type === 'custom' ? '150px' : '233px')};
    justify-content: flex-start;
    @media (max-width: 1600px) {
      width: ${({$type}) => ($type === 'custom' ? '150px' : '180px')};
    }
    @media (max-width: 1470px) {
      width: ${({$type}) => ($type === 'custom' ? '150px' : '90px')};
    }
    @media (max-width: 1366px) {
      width: ${({$type}) => ($type === 'custom' ? '123px' : '106px')};
    }
  }
  & > th:nth-child(2) {
    display: flex;
    ${({$type}) => $type === 'custom' && 'width: 150px'};
    @media (max-width: 1366px) {
      ${({$type}) => $type === 'custom' && 'width: 123px'};
    }
    justify-content: center;
  }
  & > th:nth-child(3) {
    display: flex;
    ${({$type}) => $type === 'custom' && 'width: 150px'};
    @media (max-width: 1366px) {
      ${({$type}) => $type === 'custom' && 'width: 123px'};
    }
    justify-content: center;
  }
`;
export const HeaderCol = styled.th`
  display: flex;
  width: 110px;
  padding: 0 15px;
  justify-content: center;
  @media (max-width: 1366px) {
    padding: 0 7px 0 7px;
  }
`;

export const HeaderRowSecondary = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  height: 71px;
  padding: 0 20px 0 20px;
  width: 100%;
  & > th:nth-child(1) {
    display: flex;
    width: 240px;
    justify-content: center;
    @media (max-width: 1366px) {
      width: 190px;
    }
  }
  & > th:nth-child(2) {
    width: 60px;
  }
  & > th:nth-child(6) {
    width: 50px;
  }
`;

export const SecondaryRow = styled.tr<{index: number}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: ${({index}) => (index % 2 == 0 ? theme.pallet.common.grey5 : theme.pallet.common.grey6)};
  font-family: Poppins, sans-serif;
  height: 90px;
  padding: 0 20px 0 20px;
  & > td:nth-child(1) {
    display: flex;
    min-width: 270px;
    justify-content: flex-start;
    @media (max-width: 1366px) {
      min-width: 220px;
    }
  }
  & > td:nth-child(2) {
    width: 90px;
  }
  & > td:nth-child(6) {
    width: 80px;
  }
`;

export const Row = styled.tr<{$isStandard: boolean; $type: string; $isHiddenElement?: boolean}>`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  align-items: ${({$type}) => ($type === 'custom' ? 'flex-start' : 'center')};
  background: ${(props) => (props.theme.select ? theme.pallet.background.quinary : theme.pallet.background.denary)};
  font-family: Poppins, sans-serif;
  height: ${(props) => (props.theme.select ? 'auto' : props?.$type === 'custom' ? 'auto' : '111px')};
  border-radius: 10px;
  margin-bottom: ${({$isHiddenElement}) => ($isHiddenElement ? '4px' : '12px')};
  padding: ${({$isHiddenElement}) => ($isHiddenElement ? '0' : '0 15px 0 15px')};
  padding-top: ${({$type}) => ($type === 'custom' ? '10px' : '0')};
  padding-bottom: ${({$type}) => ($type === 'custom' ? '10px' : '0')};
  & > td:nth-child(1) {
    display: flex;
    width: ${({$isStandard, $type}) => ($isStandard ? ($type === 'custom' ? '178px' : '263px') : '120px')};
    @media (max-width: 1600px) {
      width: ${({$isStandard, $type}) => ($isStandard ? ($type === 'custom' ? '178px' : '210px') : '120px')};
    }
    @media (max-width: 1470px) {
      width: ${({$isStandard, $type}) => ($isStandard ? ($type === 'custom' ? '178px' : '120px') : '120px')};
    }
    @media (max-width: 1366px) {
      width: ${({$isStandard, $type}) => ($isStandard ? ($type === 'custom' ? '137px' : '120px') : '120px')};
    }
    justify-content: ${({$isStandard}) => ($isStandard ? 'flex-start' : 'center')};
  }
  & > td:nth-child(2) {
    ${({$type}) => ($type === 'custom' ? 'width: 178px' : '')};
    @media (max-width: 1366px) {
      width: ${({$isStandard, $type}) => ($isStandard ? ($type === 'custom' ? '137px' : '120px') : '120px')};
    }
  }
  & > td:nth-child(3) {
    ${({$type}) => ($type === 'custom' ? 'width: 178px' : '')};
    @media (max-width: 1366px) {
      width: ${({$isStandard, $type}) => ($isStandard ? ($type === 'custom' ? '137px' : '120px') : '120px')};
    }
  }
`;

export const Col = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 44px;
  @media (max-width: 1366px) {
    width: 124px;
  }
`;

export const DetailsInfo = styled.td`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100% !important;
`;

export const EmptyWrapper = styled.tr`
  width: 100%;
  padding-top: 50px;
  height: 150px;
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
`;

export const EmptyColumn = styled.td`
  padding: 0 30px 0 0;
  width: calc(100% - 30px) !important;
`;

export const EmptyDataWrapper = styled(Text)`
  text-align: center;
  width: 100%;
`;
