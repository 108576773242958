import React, {useState} from 'react';
import {SendTransactionContainer} from '../../containers/Contacts/SendTransaction';
import {Button} from '../../ui-kit/Buttons/Button';
import {ModalWindow} from '../../ui-kit/Modal';
import {EditContact} from './Form';
import {ContactType} from '../../types/contacts';

type ActionButtonProps = {
  title: string;
  buttonValue: string;
  sendTransaction?: {address: string};
  editContact?: {
    onSubmit: (item: ContactType, oldItem: ContactType) => void;
    deleteContact: (address: string) => void;
    contact: ContactType;
  };
};

export const ActionButton: React.FC<ActionButtonProps> = ({editContact, sendTransaction, title, buttonValue}) => {
  const [isSendWindowOpen, setIsSendWindowOpen] = useState(false);
  const closeSendWindow = () => setIsSendWindowOpen(false);
  const openSendWindow = () => {
    setIsSendWindowOpen(true);
  };

  return (
    <>
      <Button type={'secondary'} onClick={openSendWindow}>
        {buttonValue}
      </Button>
      <ModalWindow visible={isSendWindowOpen} title={title} onClose={closeSendWindow}>
        {sendTransaction && <SendTransactionContainer sendTo={sendTransaction.address} closeModal={closeSendWindow} />}
        {editContact && (
          <EditContact
            onSubmit={editContact.onSubmit}
            deleteContact={editContact.deleteContact}
            contact={editContact.contact}
            closeModal={closeSendWindow}
          />
        )}
      </ModalWindow>
    </>
  );
};
