import {useMutation} from '@apollo/client';
import {GetKycTokenResponse, getKYCToken} from '../queries/kyc';
import {useEffect, useState} from 'react';
import {UpdateSessionIdRequest, UpdateSessionIdResponse, updateUserSessionId} from '../queries/user';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {user} from '../states/session';

export const useKYC = (closeKys: () => void) => {
  const userState = useRecoilValue(user);
  const setUser = useSetRecoilState(user);
  const [GetKycToken] = useMutation<GetKycTokenResponse, null>(getKYCToken);
  const [SendSessionId] = useMutation<UpdateSessionIdResponse, UpdateSessionIdRequest>(updateUserSessionId);
  const [kycToken, setKycToken] = useState<string>('');
  const getKycTokenHandler = async () => {
    try {
      const response = await GetKycToken();
      if (response.data?.geKYCToken.token) {
        setKycToken(response.data.geKYCToken.token);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const sendSessionId = async (sessionId: string) => {
    try {
      const response = await SendSessionId({
        variables: {
          sessionId: sessionId,
          id: userState?.id || '',
        },
      });
      if (response.data?.updateUser.user) {
        setUser(response.data.updateUser.user);
        alert('Your data has been sent successfully, please wait for a response');
        closeKys();
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getKycTokenHandler();
  }, []);
  return {
    kycToken,
    sendSessionId,
  };
};
