import React from 'react';
import {ContactsPage} from '../../components/Contacts';
import {useGetContacts} from '../../hooks/contacts';
import {useViewer} from '../../hooks/user';

export const ContactsContainer: React.FC = () => {
  const {
    contacts,
    searchValue,
    setSearchValue,
    setPage,
    page,
    count,
    pageSize,
    addContact,
    deleteContact,
    editContact,
    error,
  } = useGetContacts();
  const user = useViewer();

  return (
    <ContactsPage
      error={error}
      contacts={contacts}
      addContact={addContact}
      editContact={editContact}
      deleteContact={deleteContact}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      currentAddress={user?.address}
      setPage={setPage}
      page={page}
      count={count}
      pageSize={pageSize}
    />
  );
};
