import React, {FC} from 'react';
import {CollectionItemType} from '../../../types/transaction';
import {useHistoryByTokenID} from '../../../hooks/tokens';
import CollectionItemDetails from '../../../components/Assets/NFTViewPage/CollectionItemDetails';

interface IProps {
  data: CollectionItemType;
  tokenAddress: string;
  closeModalWindow: () => void;
}

export const CollectionItemDetailsContainer: FC<IProps> = ({data, closeModalWindow, tokenAddress}) => {
  const {pageSize, setPageHistory, historyList, pageHistory, totalCount, loading} = useHistoryByTokenID(
    tokenAddress,
    data.tokenID,
  );
  return (
    <CollectionItemDetails
      data={data}
      tokenAddress={tokenAddress}
      closeModalWindow={closeModalWindow}
      pageSize={pageSize}
      setPageHistory={setPageHistory}
      historyList={historyList}
      pageHistory={pageHistory}
      totalCount={totalCount}
      loadingHistory={loading}
    />
  );
};
