const blueScale = {
  _0: '#1173B8',
  _1: '#01A6F3',
  _2: '#05AB9F',
  _3: '#149589',
  _4: '#03E1D1',
  _5: '#10958c',
  _6: '#01b9f7',
  _7: '#008EEE',
};

const commonColors = {
  gray: '#788FA9',
  gray2: '#9BA1B5',
  darkGray: '#202227',
  blue: '#04D8C5',
  blueLight: '#01A6F3',
  blueLight2: '#1FA5FF',
  blueLight3: '#109EFF',
  blueLight4: '#01BDF8',
  blueGray: 'rgba(106, 132, 149, 1)',
  blueGreen: blueScale._4,
  red: '#FF6E40',
  white: '#FFFFFF',
  whiteGray: '#D2E1F2',
  gray3: '#A4A5A8',
  grey4: 'rgba(40, 43, 50, 1)',
  grey5: 'rgba(32, 34, 39, 1)',
  grey6: 'rgba(27, 29, 34, 1)',
  grey7: 'rgba(123, 133, 143, 1)',
  black: '#1e191e',
};

const grayscale = {
  _0: '#1e191e',
  _1: '#16171c',
  _2: '#24262c',
  _3: '#3e414a',
  _4: '#2f323a',
  _5: '#454956',
  _6: 'rgba(19, 19, 23, 0.88)',
  _7: '#3a4350',
  _8: 'rgba(36, 38, 44, 0.71)',
};

export const pallet = {
  common: commonColors,
  grayscale: grayscale,
  blueScale: blueScale,
  background: {
    primary: grayscale._1,
    secondary: grayscale._0,
    tertiary: grayscale._2,
    quaternary: grayscale._3,
    quinary: grayscale._4,
    senary: blueScale._2,
    septenary: grayscale._5,
    octonary: grayscale._6,
    nonary: grayscale._7,
    denary: grayscale._8,
  },
};
