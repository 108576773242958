import React, {Dispatch, SetStateAction} from 'react';
import {Columns} from '../../../../ui-kit/Table/types';
import {TransactionTable} from '../../../../types/tokens';
import Table from '../../../../ui-kit/Table';

export interface IList<T> {
  historyList: TransactionTable[];
  setPageHistory: Dispatch<SetStateAction<number>>;
  pageHistory: number;
  pageSize: number;
  totalCount: number;
  loadingHistory: boolean;
  columns: Columns<T>;
  emptyMessage: string;
}

export const List: React.FC<IList<TransactionTable>> = ({
  historyList,
  loadingHistory,
  setPageHistory,
  pageHistory,
  pageSize,
  totalCount,
  columns,
  emptyMessage,
}) => {
  return (
    <Table<TransactionTable>
      emptyMessage={emptyMessage}
      loading={loadingHistory}
      type={'secondary'}
      dataSource={historyList}
      columns={columns}
      pagination={{current: pageHistory, pageSize: pageSize, total: totalCount, handlePage: setPageHistory}}
    />
  );
};
