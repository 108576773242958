import Web3 from 'web3';
import {ETH_RPC, EXPLORER_URL, NET_NAME, RPC_URL, TRANSACTION_CHAIN_ID, WATERFALL_CHAIN_ID} from '../constants/env';
import {TransactionType} from '../types/transaction';
import {IOptions, IProperty} from '../types/tokens';
import {useMetaMask} from 'metamask-react';
import {nativeCurrency} from '../constants/networks';

export const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL));

export const useValidChain = () => {
  const {connect, switchChain, chainId, addChain, status} = useMetaMask();
  const checkChain = async () => {
    if (!connect) {
      throw new Error();
    }
    if (status === 'notConnected') {
      await connect();
    }
    if (chainId !== WATERFALL_CHAIN_ID) {
      try {
        await switchChain(WATERFALL_CHAIN_ID);
      } catch {
        try {
          await addChain({
            chainId: WATERFALL_CHAIN_ID,
            blockExplorerUrls: [EXPLORER_URL],
            chainName: NET_NAME,
            nativeCurrency: nativeCurrency,
            rpcUrls: [RPC_URL],
          });
          await switchChain(WATERFALL_CHAIN_ID);
        } catch (error) {
          throw error;
        }
      }
    }
  };
  return {checkChain};
};

export const gasAssessment = async (sender: string, contractAddress: string) => {
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');
  const gasPrice = await web3.eth.getGasPrice();
  const gasUsed = await web3.eth.estimateGas(
    {
      to: contractAddress,
      from: sender,
    },
    function (error: any, gas: any) {
      if (error) {
        console.log(error);
        return;
      }
      return gas;
    },
  );
  const totalFee = Number(web3.utils.fromWei(gasPrice, 'ether')) * gasUsed;
  return {
    totalFee: totalFee,
    gasPrice,
    gas: gasUsed,
  };
};

export const transferTokenFT = async (from: string, tokenAddress: string, to: string, amount: string) => {
  try {
    const data = await web3.wat.wrc20Transfer(web3.utils.toChecksumAddress(to), amount);
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: from,
          to: web3.utils.toChecksumAddress(tokenAddress),
          data: data,
        },
      ],
    });
  } catch (error) {
    throw error;
  }
};

export const transferTokenNFT = async (from: string, tokenAddress: string, to: string, tokenID: string) => {
  try {
    const data = await web3.wat.wrc721TransferFrom(from, to, tokenID);
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: from,
          to: web3.utils.toChecksumAddress(tokenAddress),
          data: data,
        },
      ],
    });
  } catch (error) {
    throw error;
  }
};

export const tokenMint = async (to: string, tokenAddress: string, tokenID: string, metadata: string, from: string) => {
  try {
    const data = await web3.wat.wrc721Mint(to, tokenID, metadata);
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: from,
          to: web3.utils.toChecksumAddress(tokenAddress),
          data: data,
        },
      ],
    });
  } catch (error) {
    throw error;
  }
};

export const tokenBurn = async (from: string, tokenAddress: string, tokenID: string) => {
  try {
    const data = await web3.wat.wrc721Burn(tokenID);
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: from,
          to: tokenAddress,
          data: data,
        },
      ],
    });
  } catch (error) {
    throw error;
  }
};

export const sendTransaction = async (transactionParameters: TransactionType) => {
  let data;
  try {
    data = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
  } catch (error) {
    throw error;
  }
  return data;
};

export const transfer = async (sender: string, recipient: string, amount: string) => {
  const gasData = await gasAssessment(sender, recipient);
  return await sendTransaction({
    gasPrice: gasData.gasPrice,
    gas: gasData.gas?.toString(),
    to: recipient,
    from: sender,
    value: web3.utils.toHex(web3.utils.toWei(amount)),
    chainId: TRANSACTION_CHAIN_ID,
  });
};

export const getBalance = async (address: string) => {
  return await web3.eth.getBalance(address);
};

export const getTransactionReceipt = async (hash: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    web3.eth.getTransactionReceipt(hash, (error: any, result: any) => {
      if (!error) {
        resolve(result);
      } else {
        reject(error);
      }
    });
  });
};

export const getTransaction = async (hash: string) => {
  return await web3.eth.getTransaction(hash);
};

export const createToken = async (options: IOptions, address: string) => {
  try {
    const token = await web3.wat.tokenCreate(options);
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: address,
          data: token,
        },
      ],
    });
  } catch (error) {
    throw error;
  }
};

export const tokenBalance = async (tokenAddress: string, ownerAddress: string): Promise<number> => {
  try {
    const result = await web3.wat.tokenBalanceOf(tokenAddress, ownerAddress);
    return Number(result);
  } catch (error) {
    throw error;
  }
};

export const tokenProperties = async (tokenAddress: string, block?: string, tokenID?: string): Promise<IProperty> => {
  try {
    return await web3.wat.tokenProperties(web3.utils.toChecksumAddress(tokenAddress), block, tokenID);
  } catch (error) {
    throw error;
  }
};

export const batchRequest = () => {
  let batch = new web3.BatchRequest();
  let requests: any[] = [];

  const batchAdd = (_request: any, ...params: any) => {
    const request = new Promise((resolve, reject) => {
      batch.add(
        _request.call(null, ...params, (err: any, data: any) => {
          if (err) return reject(err);
          resolve(data);
        }),
      );
    });
    requests.push(request);
  };

  const clearBatch = () => {
    batch = new web3.BatchRequest();
    requests = [];
  };

  const batchExecute = async () => {
    batch.execute();
    return await Promise.all(requests);
  };

  return {batchAdd, batchExecute, clearBatch};
};

export const getConfirmation = async (txHash?: string, rpc?: string) => {
  const web3 = new Web3(new Web3.providers.HttpProvider(rpc || ETH_RPC));
  if (txHash) {
    const transaction = await web3.eth.getTransactionReceipt(txHash);
    const currentBlock = await web3.eth.getBlockNumber();
    return transaction?.blockNumber ? currentBlock - transaction.blockNumber : 0;
  } else return 0;
};
