import styled from 'styled-components';
import {Text} from '../../ui-kit/Typography/Typography';
import {theme} from '../../ui-kit/theme';
import {NavLink} from 'react-router-dom';
import {LinkButton} from '../../ui-kit/Buttons/LinkButton';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Main = styled.main`
  flex-grow: 1;
`;

export const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 160px 0 160px;
  width: calc(100% - 320px);
  height: 125px;
  background-color: ${theme.pallet.background.tertiary};
  border: none;
  @media (max-width: 1550px) {
    padding: 0 60px 0 60px;
    width: calc(100% - 120px);
  }
  @media (max-width: 1300px) {
    padding: 0 30px 0 30px;
    width: calc(100% - 60px);
  }
`;

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 160px 0 160px;
  width: calc(100% - 320px);
  height: 96px;
  background-color: ${theme.pallet.background.tertiary};
  border: none;
  @media (max-width: 1400px) {
    padding: 0 60px 0 60px;
    width: calc(100% - 120px);
  }
  @media (max-width: 1200px) {
    padding: 0 30px 0 30px;
    width: calc(100% - 60px);
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  .active {
    span {
      color: ${theme.pallet.common.blueLight2};
    }
  }
  & > button:last-child {
    width: 125px;
    height: 50px;
    margin-right: 20px;
  }
  & > a:nth-child(n) {
    margin-right: 30px;
    @media (max-width: 1200px) {
      margin-right: 20px;
    }
  }
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 33px;
`;

export const Logo = styled(LinkButton)`
  width: 167px;
  background-color: transparent;
  margin-right: 90px;
  @media (max-width: 1200px) {
    margin-right: 40px;
  }
`;

export const MenuText = styled(Text).attrs({
  nowrap: true,
  color: 'whiteGray',
})``;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  & > button:nth-child(2) {
    width: 200px;
  }
`;

export const Balance = styled(Text).attrs({
  strong: true,
  level: 1,
  color: 'blueLight4',
})``;

export const BalanceTooltip = styled(Text).attrs({
  level: 1,
})`
  visibility: hidden;
  background: rgba(19, 19, 23, 0.88);
  color: #fff;
  text-align: center;
  padding: 7px 22px 9px 18px;
  border-radius: 10px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 80px;
  z-index: 1;
`;

export const Line = styled.div`
  width: 1px;
  height: 31px;
  background-color: ${theme.pallet.common.white};
  opacity: 0.29;
  margin-right: 9px;
  margin-left: 14px;
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: flex-end;
  margin-right: 30px;
  @media (max-width: 1200px) {
    margin-right: 20px;
  }
  &:hover {
    & > span {
      visibility: visible;
    }
  }
`;
