import React from 'react';
import {AddValidationForm} from '../../components/AddValidationForm';
import {useAddValidation} from '../../hooks/validator';

export const AddValidationFormContainer = () => {
  const {
    values,
    actionsOfAddValidatorState,
    zoneData,
    cardList,
    sendRequestAddValidator,
    stepValidation,
    step,
    userState,
    handlerChangeStep,
    paymentList,
  } = useAddValidation();
  return (
    <AddValidationForm
      paymentList={paymentList}
      values={values}
      userState={userState}
      actionsOfValues={actionsOfAddValidatorState}
      step={step}
      handlerChangeStep={handlerChangeStep}
      stepValidation={stepValidation}
      zoneData={zoneData}
      cardList={cardList}
      addValidationRequest={sendRequestAddValidator}
    />
  );
};
