import React from 'react';
import styled from 'styled-components';
import {Subtitle} from '../../Typography/Typography';
import ArrowImg from './assets/image/arrow.svg';
import {theme} from '../../theme';

const ErrorText = styled(Subtitle).attrs({
  color: 'gray3',
  level: 1,
})`
  padding: 0 25px;
`;

const Arrow = styled.img`
  top: -5px;
  left: 50px;
  position: absolute;
`;

const ErrorMessageBackground = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background: ${theme.pallet.background.octonary};
  border-radius: 10px;
  min-height: 40px;
  width: 100%;
`;

interface Props {
  errorMessage: string;
}

export const ErrorMessage: React.FC<Props> = ({errorMessage}) => {
  return (
    <ErrorMessageBackground>
      <Arrow src={ArrowImg} />
      <ErrorText>{errorMessage}</ErrorText>
    </ErrorMessageBackground>
  );
};
