import React, {useEffect, useState} from 'react';
import {Mint} from '../../../components/Assets/Mint';
import {useGetTokens, useTokenMint} from '../../../hooks/tokens';
import {useViewer} from '../../../hooks/user';
import {useGetContacts} from '../../../hooks/contacts';
import {TokenTypes} from '../../../types/tokens';
import {Options} from '../../../ui-kit/Form/Select/types';
import {explorer_api} from '../../../queries/explorer';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router';

export const TokenMintContainer = ({}) => {
  const viewer = useViewer();
  const {contacts} = useGetContacts();
  const tokens = useGetTokens(TokenTypes.NFT);
  const {loading, error, onMint} = useTokenMint();
  const [collection, setCollection] = useState<Options[]>([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const {address} = useParams<{address: string}>();
  const {push, location} = useHistory();
  if (!viewer?.address) return null;

  const handleForwardBack = () => {
    push(`/assets/nft/${address}`);
  };

  const handleChangeCollection = (address: string) => {
    if (tokens) {
      const to = tokens?.filter((item) => item?.contractAddress?.toLowerCase() === address?.toLowerCase())?.[0]
        ?.contractAddress;
      to && push(`/assets/nft/${to}/mint`);
    }
  };

  useEffect(() => {
    if (tokens.some((item) => item.contractAddress.toLowerCase() === address.toLowerCase())) {
      setSelectedCollection(address?.toLowerCase());
      location.pathname !== `/assets/nft/${address}/mint` && push(`/assets/nft/${address}/mint`);
    }
  }, [address, tokens]);

  useEffect(() => {
    const getTokens = async () => {
      const existTokens = tokens.map((item) => item.contractAddress.toLowerCase());
      const tokensOwner = await explorer_api.getAccountTokens(viewer?.address || '');
      const result = tokensOwner.data
        .filter((item: any) => existTokens.includes(item?.hash?.toLowerCase()))
        .map((item: any) => {
          return {
            label: item?.hash,
          };
        }) as Options[];
      setCollection(result);
    };
    if (tokens.length) getTokens();
  }, [tokens]);

  return (
    <Mint
      forwardBack={handleForwardBack}
      changeCollection={handleChangeCollection}
      selectedCollection={selectedCollection}
      collection={collection}
      contacts={contacts}
      onMint={onMint}
      viewer={viewer.address}
      error={error}
      status={loading}
    />
  );
};
