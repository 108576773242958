import {web3} from '../hooks/metamask';
import {format} from 'date-fns';

export const hiddenCardNumberValue = (value: string) => {
  return '**** **** **** ' + value.slice(-4);
};

export const formatEthValue = (value: any) => {
  return web3.utils.fromWei(value, 'ether');
};

export const formatUserBalance = (balance: any, slice: boolean) => {
  if (balance)
    try {
      const format = formatEthValue(balance).toString();
      return format.length > 12 && slice ? format.slice(0, 12).concat('...') : format;
    } catch (e) {
      console.log(e);
    }
};

export const toLocalDate = (date: number) => {
  const localDate = new Date(date);
  return format(localDate, 'MM.dd.yyyy HH:mm:ss');
};

export const changeFileName = (file: File): File => {
  let myRenamedFile: File | null = null;
  const ext = file.name.split('.').pop();
  const newName = `file_${Date.now()}.${ext}`;
  myRenamedFile = new File([file], newName, {type: file.type});

  return myRenamedFile || file;
};

export const toLocalAppFiles = (data: any): {name: string; filePath: string}[] => {
  return data?.data?.appFiles?.edges?.map((item: any) => item?.node);
};
