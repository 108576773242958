import {useViewer} from './user';
import {
  batchRequest,
  createToken,
  tokenBalance,
  tokenBurn,
  tokenMint,
  tokenProperties,
  transferTokenFT,
  transferTokenNFT,
  useValidChain,
  web3,
} from './metamask';
import {IBalance, IFieldsMintData, IOptions, TokensType, TokenTypes, TransactionTable} from '../types/tokens';
import {SetterOrUpdater, useRecoilValue, useSetRecoilState} from 'recoil';
import {useEffect, useState} from 'react';
import {getFilteredTokensAddress, getFilteredTokensType, tokensState} from '../states/tokens';
import {PAGE_SIZE} from '../constants/data';
import {useTransactionsMethods} from './transactions';
import {blockTypes, CollectionItemType, Transaction, TypeOfTransactions} from '../types/transaction';
import {useGetContacts} from './contacts';
import {explorer_api} from '../queries/explorer';
import {useQuery} from 'react-query';
import {toLocalTableTransaction, toLocalTransaction} from '../helpers/transaction';
import {errorValues} from '../helpers/metamaskHelper';
import {changeFileName, toLocalAppFiles} from '../helpers/format';
import {useMutation, useQuery as useQ} from '@apollo/client';
import {GetTokenQuery, SaveTokenQuery} from '../queries/tokens';
import {GQLAppFileWhereInput} from '../graphql.schema';

export const useGetTokens = (filter?: TokenTypes): TokensType[] => {
  return useRecoilValue(getFilteredTokensType(filter));
};

export const useSetTokens = (): SetterOrUpdater<TokensType[]> => {
  return useSetRecoilState(tokensState);
};

export const useTokens = () => {
  const viewer = useViewer();
  const tokens = useGetTokens();
  const setTokensRecoil = useSetTokens();

  useEffect(() => {
    const data = localStorage.getItem(`tokens[${viewer?.address}]`);
    if (data) {
      setTokensRecoil(JSON.parse(data));
    }
  }, []);

  const addTokens = (newToken: TokensType) => {
    setTokensRecoil((tokensList) => {
      if (!tokensList.some((item) => item.contractAddress === newToken.contractAddress)) {
        localStorage.setItem(`tokens[${viewer?.address}]`, JSON.stringify([...tokensList, newToken]));
        return [...tokensList, newToken];
      } else return tokensList;
    });
  };

  return {tokens, addTokens};
};

export const useTokenMint = () => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {checkChain} = useValidChain();
  const {addTransaction} = useTransactionsMethods();
  const {contacts} = useGetContacts();
  const tokens = useGetTokens();
  const viewer = useViewer();
  const [uploadMeta] = useMutation(SaveTokenQuery);

  const onMint = async (tokenAddress: string, mintData: IFieldsMintData) => {
    setError('');
    try {
      setLoading(true);
      if (!viewer?.address) return false;
      await checkChain();
      if (
        !mintData.attributes?.every((item) => (item.label && item.value) || (item.label && item.type === 'Boolean'))
      ) {
        setError('Fill all attribute forms or delete some');
        return false;
      }
      const localAttr = mintData.attributes?.map((item) => {
        return {
          trait_type: item.label,
          value: item.type === 'Date' ? new Date(String(item.value)).getTime() : item.value,
        };
      });
      const metadata = {
        external_url: mintData.external_url,
        image: mintData.image_url,
        name: mintData.name,
        attributes: localAttr,
      };
      let metaString = '';
      try {
        metaString = JSON.stringify(metadata || '');
      } catch (e) {
        console.log(e);
      }
      const token = tokens.filter((item) => item.contractAddress.toLowerCase() === tokenAddress.toLowerCase())[0]?.data
        ?.name;
      const data = {tokenAddress, tokenID: mintData.tokenID, name: mintData.name, collectionName: token};
      const hash = await tokenMint(mintData.to, tokenAddress, mintData.tokenID, metaString, viewer?.address);
      const transaction: Transaction = {
        transactionHash: hash,
        transactionType: TypeOfTransactions.tokenMint,
        data: data,
      };
      if (hash) {
        addTransaction(toLocalTransaction(transaction, contacts));
        try {
          const blob = new Blob([metaString], {type: 'application/json'});
          const file = new File([blob], 'file.json');
          await uploadMeta({
            variables: {
              fields: {
                file: {upload: file},
                Owner: {link: viewer?.objectId},
                type: 'metadata',
                info: {tx: hash},
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }
      return true;
    } catch (e) {
      console.log(e);
      errorValues[e.code]?.message ? setError(errorValues[e.code]?.message || '') : setError('Transaction failed');
      return false;
    } finally {
      setLoading(false);
    }
  };
  return {error, loading, onMint};
};

export const useCreateToken = () => {
  const viewer = useViewer();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {checkChain} = useValidChain();
  const {addTransaction} = useTransactionsMethods();
  const {contacts} = useGetContacts();
  const [uploadImage] = useMutation(SaveTokenQuery);

  const onCreate = async (option: IOptions, file?: File) => {
    setError('');
    try {
      if (!viewer?.address) return false;
      setLoading(true);
      await checkChain();
      const data = {...option};
      const hash = await createToken(option, viewer?.address);
      if (file) {
        try {
          await uploadImage({
            variables: {
              fields: {
                file: {upload: changeFileName(file)},
                Owner: {link: viewer?.objectId},
                type: 'tokenIcon',
                info: {tx: hash},
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }
      const transaction: Transaction = {
        transactionHash: hash,
        transactionType: TypeOfTransactions.tokenCreate,
        data: data,
      };
      if (hash) addTransaction(toLocalTransaction(transaction, contacts));
      return true;
    } catch (e) {
      console.log(e);
      errorValues[e.code]?.message ? setError(errorValues[e.code]?.message || '') : setError('Transaction failed');
      return false;
    } finally {
      setLoading(false);
    }
  };
  return {error, loading, onCreate};
};

export const useAddToken = () => {
  const viewer = useViewer();
  const [error, setError] = useState<string>('');
  const {addTokens} = useTokens();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadImage] = useMutation(SaveTokenQuery);

  const onAdd = async (tokenAddress: string, file?: File) => {
    setError('');
    if (!viewer?.address) return false;
    try {
      setLoading(true);
      const token = await tokenProperties(tokenAddress);
      if (file && token) {
        try {
          await uploadImage({
            variables: {
              fields: {
                file: {upload: changeFileName(file)},
                Owner: {link: viewer?.objectId},
                type: 'tokenIcon',
                info: {tokenAddress: tokenAddress},
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }
      if (token) {
        const newToken: TokensType = {
          type: token.decimals ? TokenTypes.FT : TokenTypes.NFT,
          contractAddress: web3.utils.toChecksumAddress(tokenAddress),
          blockHash: blockTypes.latest,
          data: {
            name: token.name || '',
            symbol: token.symbol || '',
            decimals: token.decimals || '',
            totalSupply: token.totalSupply || '',
          },
        };
        addTokens(newToken);
        return true;
      } else {
        setError('Transaction failed');
        return false;
      }
    } catch (e) {
      console.log(e);
      setError('Transaction failed');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {error, loading, onAdd};
};

export const useGetBalanceFT = (address: string) => {
  const viewer = useViewer();
  const token = useRecoilValue(getFilteredTokensAddress(address));
  const [balance, setBalance] = useState<{name: string; symbol: string; balance: string; filePath: string}>({
    name: '',
    symbol: '',
    balance: '',
    filePath: '',
  });
  const {refetch} = useQ(GetTokenQuery, {skip: true});

  const getBalance = async () => {
    try {
      if (!viewer?.address) return;
      const balance = await tokenBalance(address, viewer?.address);
      const res = await refetch({
        where: {
          AND: [
            {
              isResized: {
                equalTo: true,
              },
            },
            {
              name: {
                equalTo: token[0]?.contractAddress.toLowerCase(),
              },
            },
          ],
        } as GQLAppFileWhereInput,
      });
      const hasImages = toLocalAppFiles(res);
      setBalance({
        name: token[0]?.data.name || '',
        symbol: token[0]?.data.symbol || '',
        balance: String(balance || '0'),
        filePath: hasImages?.[0]?.filePath || '',
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getBalance();
  }, [address, viewer?.address]);

  return {balance, update: getBalance};
};

export const useGetBalanceTokens = (type: TokenTypes, limit?: number) => {
  const viewer = useViewer();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [list, setList] = useState<IBalance[]>([]);
  let tokens = useGetTokens(type);
  tokens = limit ? tokens.slice(0, limit) : tokens;
  const {data} = useQuery(
    ['tokenBalance', tokens],
    () => {
      const {batchExecute, batchAdd} = batchRequest();
      tokens.forEach((item) => {
        batchAdd(
          web3.wat.tokenBalanceOf.request,
          web3.utils.toChecksumAddress(item.contractAddress),
          web3.utils.toChecksumAddress(viewer?.address || ''),
        );
      });
      return batchExecute();
    },
    {
      enabled: !!tokens.length && !!viewer?.address,
      initialData: [],
    },
  );
  const {refetch} = useQ(GetTokenQuery, {skip: true});

  useEffect(() => {
    const getBalance = async () => {
      setPage(1);
      if (!viewer?.address) return;
      setLoading(true);
      if (tokens.length && data?.length) {
        const arr = tokens
          .map((item, index) => {
            try {
              const actions = item.contractAddress;
              const token = tokens.filter((tk) => tk.contractAddress === item.contractAddress)[0];
              return {
                type: item.type,
                name: token?.data?.name,
                symbol: token?.data?.symbol,
                balance: data[index],
                actions,
              };
            } catch (e) {
              console.log(e);
            }
          })
          .filter((item) => {
            if (item && item.name && item.type) return item;
          }) as IBalance[];
        const res = await refetch({
          where: {
            AND: [
              {
                isResized: {
                  equalTo: true,
                },
              },
              {
                name: {in: tokens.map((item) => item.contractAddress.toLowerCase())},
              },
            ],
          } as GQLAppFileWhereInput,
        });
        const hasImages = toLocalAppFiles(res);
        const tokenBalanceArray = arr.map((item) => {
          const image = hasImages.filter((img) => img.name.toLowerCase() === item.actions.toLowerCase())?.[0];
          return image ? {...item, imagePath: image.filePath} : {...item};
        }) as IBalance[];
        setList(tokenBalanceArray);
      } else setList([]);
      setLoading(false);
    };
    getBalance();
  }, [data, viewer?.address]);

  const pageSizeBalance = 5;
  const total = Math.ceil(list.length / pageSizeBalance);
  const tokensList = list.slice((page - 1) * pageSizeBalance, pageSizeBalance * page);

  return {loading, tokensList, setPage, page, total, pageSizeBalance};
};

export const useCollectionNFT = (address: string) => {
  const viewer = useViewer();
  const [collection, setCollection] = useState<CollectionItemType[]>([]);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const {data: response} = useQuery(
    ['NFTCollection', {address, page}],
    () => explorer_api.getNFTCollection(address, viewer?.address || '', page * PAGE_SIZE - PAGE_SIZE),
    {
      enabled: !!address && !!viewer?.address,
      initialData: [],
    },
  );

  useEffect(() => {
    const getCollection = async () => {
      if (response.data) {
        try {
          const result = response.data.map((item: any) => {
            let attr = {};
            try {
              attr = JSON.parse(item.byTokenId.metadata);
            } catch (e) {
              attr = {};
            }
            return {...attr, tokenID: item.tokenId};
          });
          const tokens = await explorer_api.getAccountTokens(viewer?.address || '');
          tokens.data.some((item: any) => item.hash === address.toLowerCase()) ? setIsOwner(true) : setIsOwner(false);
          setCollection(result);
        } catch (e) {
          console.log(e);
        }
      }
    };
    getCollection();
  }, [response]);

  const pageSize = PAGE_SIZE;
  const totalCount = Math.ceil(response.count / pageSize);

  return {collection, isOwner, totalCount, setPage, page};
};

export const useSendTokenNFT = () => {
  const viewer = useViewer();
  const [error, setError] = useState<string>('');
  const {checkChain} = useValidChain();
  const [loading, setLoading] = useState<boolean>(false);
  const {addTransaction} = useTransactionsMethods();
  const {contacts} = useGetContacts();
  const tokens = useGetTokens();

  const onSend = async (to: string, tokenAddress: string, tokenID: string) => {
    setError('');
    if (!viewer?.address) return false;
    try {
      setLoading(true);
      await checkChain();
      const tokenData = tokens.filter((item) => item.contractAddress === tokenAddress)[0]?.data;
      const data = {...tokenData, recipient: to, tokenID};
      const hash = await transferTokenNFT(viewer?.address, tokenAddress, to, tokenID);
      const transaction: Transaction = {
        transactionHash: hash,
        transactionType: TypeOfTransactions.tokenSendNFT,
        data: data,
      };
      if (hash) addTransaction(toLocalTransaction(transaction, contacts));
      return true;
    } catch (e) {
      console.log(e);
      errorValues[e.code]?.message ? setError(errorValues[e.code]?.message || '') : setError('Transaction failed');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {error, loading, onSend};
};

export const useBurnTokenNFT = (address: string) => {
  const viewer = useViewer();
  const [error, setError] = useState<string>('');
  const {checkChain} = useValidChain();
  const [loading, setLoading] = useState<boolean>(false);
  const {addTransaction} = useTransactionsMethods();
  const {contacts} = useGetContacts();
  const tokens = useRecoilValue(getFilteredTokensAddress(address));

  const onBurn = async (tokenID: string, name: string) => {
    setError('');
    if (!viewer?.address) return setError('Transaction failed');
    try {
      setLoading(true);
      await checkChain();
      const token = tokens[0]?.data.name;
      const data = {tokenID, name, collectionName: token};
      const hash = await tokenBurn(viewer?.address, address, tokenID);
      const transaction: Transaction = {
        transactionHash: hash,
        transactionType: TypeOfTransactions.tokenBurn,
        data: data,
      };
      if (hash) addTransaction(toLocalTransaction(transaction, contacts));
    } catch (e) {
      console.log(e);
      errorValues[e.code]?.message ? setError(errorValues[e.code]?.message || '') : setError('Transaction failed');
    } finally {
      setLoading(false);
    }
  };

  return {error, loading, onBurn};
};

export const useSendTokenFT = () => {
  const viewer = useViewer();
  const [error, setError] = useState<string>('');
  const {checkChain} = useValidChain();
  const [loading, setLoading] = useState<boolean>(false);
  const {addTransaction} = useTransactionsMethods();
  const {contacts} = useGetContacts();
  const tokens = useGetTokens();

  const onSend = async (to: string, tokenAddress: string, amount: string) => {
    setError('');
    if (!viewer?.address) return setError('Transaction failed');
    try {
      setLoading(true);
      await checkChain();
      const tokenData = tokens.filter((item) => item.contractAddress === tokenAddress)[0]?.data;
      const data = {...tokenData, recipient: to, amount: amount};
      const hash = await transferTokenFT(viewer?.address, tokenAddress, to, amount);
      const transaction: Transaction = {
        transactionHash: hash,
        transactionType: TypeOfTransactions.tokenSendFT,
        data: data,
      };
      if (hash) addTransaction(toLocalTransaction(transaction, contacts));
    } catch (e) {
      console.log(e);
      errorValues[e.code]?.message ? setError(errorValues[e.code]?.message || '') : setError('Transaction failed');
    } finally {
      setLoading(false);
    }
  };

  return {error, loading, onSend};
};

export const useHistoryByTokenType = (type?: TokenTypes) => {
  const tokens = useGetTokens(type);
  const viewer = useViewer();
  const [pageHistory, setPageHistory] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [historyList, setHistoryList] = useState<TransactionTable[]>([]);
  const {contacts} = useGetContacts();
  const {data: response} = useQuery(
    ['historyByToken', {tokens, pageHistory}],
    () => explorer_api.getByTokensType(tokens, viewer?.address || '', pageHistory * PAGE_SIZE - PAGE_SIZE),
    {
      enabled: !!tokens.length && !!viewer?.address,
      initialData: [],
    },
  );
  useEffect(() => {
    const getHistory = async () => {
      if (response.data) {
        setLoading(true);
        const transactionList = await toLocalTableTransaction(response?.data, viewer?.address || '', contacts, tokens);
        transactionList ? setHistoryList(transactionList) : setHistoryList([]);
        setLoading(false);
      }
    };
    getHistory();
  }, [response, viewer?.address]);

  useEffect(() => {
    setPageHistory(1);
  }, [type]);

  const pageSize = PAGE_SIZE;
  const totalCount = Math.ceil(response.count / pageSize);

  return {historyList, setPageHistory, pageHistory, totalCount, pageSize, loading};
};

export const useHistoryByAddress = (address: string) => {
  const viewer = useViewer();
  const tokens = useGetTokens();
  const {contacts} = useGetContacts();
  const [pageHistory, setPageHistory] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [historyList, setHistoryList] = useState<TransactionTable[]>([]);
  const {balance, update} = useGetBalanceFT(address);

  const {data: response} = useQuery(
    ['historyByAddress', {address, pageHistory}],
    () => explorer_api.getByTokenAndAddress(address, viewer?.address || '', pageHistory * PAGE_SIZE - PAGE_SIZE),
    {
      enabled: !!viewer?.address && !!address,
      initialData: [],
    },
  );
  useEffect(() => {
    const getHistory = async () => {
      if (response.data) {
        setLoading(true);
        const transactionList = await toLocalTableTransaction(response.data, viewer?.address || '', contacts, tokens);
        transactionList ? setHistoryList(transactionList) : setHistoryList([]);
        update();
        setLoading(false);
      }
    };
    getHistory();
  }, [contacts.length, response, viewer?.address]);

  const pageSize = PAGE_SIZE;
  const totalCount = Math.ceil(response.count / pageSize);

  return {historyList, setPageHistory, pageHistory, totalCount, pageSize, loading, balance};
};

export const useHistoryByTokenID = (address: string, tokenID: string) => {
  const tokens = useGetTokens();
  const viewer = useViewer();
  const [pageHistory, setPageHistory] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [historyList, setHistoryList] = useState<TransactionTable[]>([]);
  const {contacts} = useGetContacts();

  const {data: response} = useQuery(
    ['historyByAddress', {tokenID, address, pageHistory}],
    () => explorer_api.getNFTByTokenID(address, tokenID, pageHistory * PAGE_SIZE - PAGE_SIZE),
    {
      enabled: !!tokenID && !!address,
      initialData: [],
    },
  );

  useEffect(() => {
    const getHistory = async () => {
      if (response.data) {
        setLoading(true);
        const transactionList = await toLocalTableTransaction(response.data, viewer?.address || '', contacts, tokens);
        transactionList ? setHistoryList(transactionList) : setHistoryList([]);
        setLoading(false);
      }
    };
    getHistory();
  }, [response, viewer?.address]);

  const pageSize = PAGE_SIZE;
  const totalCount = Math.ceil(response.count / pageSize);
  return {historyList, setPageHistory, pageHistory, totalCount, pageSize, loading};
};

export const useHistoryByUser = (limit?: number) => {
  const viewer = useViewer();
  const [pageHistory, setPageHistory] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [historyList, setHistoryList] = useState<TransactionTable[]>([]);
  const {contacts} = useGetContacts();
  const tokens = useGetTokens();
  const {data: response} = useQuery(
    ['historyByUser', {limit, tokens}],
    () => explorer_api.getByUser(viewer?.address || '', pageHistory * PAGE_SIZE - PAGE_SIZE, limit || undefined),
    {
      enabled: !!viewer?.address,
      initialData: [],
    },
  );

  useEffect(() => {
    const getHistory = async () => {
      if (response.data) {
        setLoading(true);
        const transactionList = await toLocalTableTransaction(response.data, viewer?.address || '', contacts, tokens);
        transactionList ? setHistoryList(transactionList.filter((item) => item?.link)) : setHistoryList([]);
        setLoading(false);
      }
    };
    getHistory();
  }, [response, viewer?.address]);

  const pageSize = PAGE_SIZE;
  const totalCount = limit ? Math.ceil(limit / pageSize) : Math.ceil(response.count / pageSize);
  return {historyList, setPageHistory, pageHistory, totalCount, pageSize, loading};
};
