import React from 'react';
import {LoginWrapper, Logo, Metamask} from './styles';
import {checkMetaMask} from '../../helpers/metamaskHelper';
import logoImage from './assets/images/logo.svg';
import metaMask from './assets/images/metamask.svg';
import {Button} from '../../ui-kit/Buttons/Button';
import {LinkButton} from '../../ui-kit/Buttons/LinkButton';
import {Layout} from '../../ui-kit/Layout';

interface Props {
  signIn: () => void;
}

export const AuthPage: React.FC<Props> = ({signIn}) => {
  return (
    <Layout>
      <Logo src={logoImage} alt={'Waterfall logo'} />
      <LoginWrapper>
        <Metamask src={metaMask} alt={'metamask logo'} />
        {(checkMetaMask() && (
          <Button type={'primary'} size={'large'} color={'primary'} onClick={signIn}>
            Metamask authorization
          </Button>
        )) || (
          <LinkButton type={'primary'} size={'large'} color={'primary'} href={'https://metamask.io/'} target={'_blank'}>
            Install MetaMask
          </LinkButton>
        )}
      </LoginWrapper>
    </Layout>
  );
};
