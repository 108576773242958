import React, {useState} from 'react';
import {ErrorMessage} from '../../../ui-kit/Form/ErrorMessage/ErrorMessage';
import {Input} from '../../../ui-kit/Form/Input';
import {ButtonSecondarySelect, InputWrapper, TokenCreateForm} from './styles';
import {IFieldsSendToken} from '../../../types/tokens';
import {validationSendFT} from '../../../helpers/validations';
import {InputAutocomplete} from '../../../ui-kit/Form/InputAutocomplete';
import {ContactType} from '../../../types/contacts';

interface IProps {
  onSend: (to: string, tokenAddress: string, amount: string) => void;
  loading: boolean;
  error: string;
  tokenAddress: string;
  contacts: ContactType[];
}

export const FTTokenSend: React.FC<IProps> = ({onSend, loading, error, tokenAddress, contacts}) => {
  const [fields, setFields] = useState<IFieldsSendToken>({
    address: {value: '', error: ''},
    value: {value: '', error: ''},
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'value' && !isNaN(Number(value)) && !value.includes(' ')) {
      setFields({...fields, [name]: {value: value, error: ''}});
    } else if (name === 'address') {
      setFields({...fields, [name]: {value: value, error: ''}});
    }
  };
  const handlerSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const validate = validationSendFT(fields);
    if (validate) {
      return setFields({
        ...fields,
        [validate.field]: {value: fields[validate.field]?.value, error: validate.error},
      });
    }
    if (fields.address?.value && fields.value?.value) {
      onSend(fields.address.value, tokenAddress, fields.value.value);
    }
  };

  const handleSetAddress = (address: string) => {
    setFields({
      ...fields,
      address: {value: address, error: ''},
    });
  };

  return (
    <TokenCreateForm onSubmit={handlerSubmit}>
      <InputWrapper>
        <InputAutocomplete
          data={contacts}
          autocompleteField={'address'}
          promptField={'address'}
          searchField={'name'}
          setValue={handleSetAddress}
          name={'address'}
          status={fields.address?.error ? 'error' : undefined}
          errorMessage={fields.address?.error && fields.address?.error}
          onChange={handleChange}
          value={fields.address?.value}
          placeholder={'Address'}
        />
        <Input
          name={'value'}
          status={fields.value?.error ? 'error' : undefined}
          errorMessage={fields.value?.error && fields.value.error}
          onChange={handleChange}
          value={fields.value?.value}
          placeholder={'Amount'}
        />
        {error && <ErrorMessage errorMessage={error} />}
      </InputWrapper>
      <ButtonSecondarySelect disabled={loading}>Send</ButtonSecondarySelect>
    </TokenCreateForm>
  );
};
