import {atom, selectorFamily} from 'recoil';
import {Transaction} from '../types/transaction';
export const transactionState = atom<Transaction[]>({
  key: 'transactionState',
  default: [],
});

export const getFilteredTransactionsByTo = selectorFamily<Transaction[], string | null>({
  key: 'getFilteredTransactionsByTo',
  get:
    (filter) =>
    ({get}) => {
      const transactionList = get(transactionState);
      if (!filter) {
        return transactionList;
      }
      return transactionList.filter((item) => item.to === filter.toLocaleLowerCase());
    },
});
