import {IFieldsCreateTokenFT, IFieldsCreateTokenNFT, IFieldsMineToken, IFieldsSendToken} from '../types/tokens';
import {web3} from '../hooks/metamask';

export const isServer = (): boolean => {
  return typeof window === 'undefined';
};
export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const ZIP_CODE_REGEX = /^[0-9]+$/;
export const VALID_NAME =
  /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/;

export const isEmpty = (value: string) => {
  return value.length === 0;
};

export const validateZipPostalCode = (value: string) => {
  if (value.length === 0) {
    return 'This is a required field';
  }

  if (ZIP_CODE_REGEX.test(value) && value.length >= 3) {
    return '';
  }
  return 'Please enter a valid cvc Code';
};

export const validatesName = (value: string) => {
  if (VALID_NAME.test(value)) {
    return '';
  }
  return 'Please enter valid name';
};

export const validationExpDate = (value: string) => {
  const month = value.split('/')[0];
  const year = value.split('/')[1];
  if (month && Number(month) > 12) {
    return 'Please enter valid date';
  }
  if ((year && Number(year) > 50) || (year && Number('20' + year) < new Date().getFullYear())) {
    return 'Please enter valid date';
  }
  if (year && month && Number('20' + year) === new Date().getFullYear() && Number(month) < new Date().getMonth()) {
    return 'Please enter valid date';
  }
  return '';
};

export const validationCardNumber = (value: string, type: string) => {
  if (value.length < 15) {
    return 'Invalid card number';
  }
  if (type === 'unknown') {
    return 'Unknown type card';
  }
  if ((type === 'mastercard' && value.length < 16) || (type === 'visa' && value.length < 16)) {
    return 'Invalid card number';
  }
  return '';
};

export const validationFormFT = (
  fields: IFieldsCreateTokenFT,
): {field: keyof IFieldsCreateTokenFT; error: string} | undefined => {
  if (!fields.name?.value?.length) {
    return {field: 'name', error: 'Name is required'};
  }
  if (!fields.symbol?.value?.length) {
    return {field: 'symbol', error: 'Symbol is required'};
  }
  if (Number(fields.decimals?.value) < 0 || Number(fields.decimals?.value) > 18) {
    return {field: 'decimals', error: 'Decimals should be from 0 to 18'};
  }
  if (!fields.total?.value) {
    return {field: 'total', error: 'Total is required'};
  }
  return undefined;
};

export const validationFormNFT = (
  fields: IFieldsCreateTokenNFT,
): {field: keyof IFieldsCreateTokenNFT; error: string} | undefined => {
  if (!fields.name?.value?.length) {
    return {field: 'name', error: 'Name is required'};
  }
  if (!fields.symbol?.value?.length) {
    return {field: 'symbol', error: 'Symbol is required'};
  }
  if (!fields.url?.value?.length) {
    return {field: 'url', error: 'Url is required'};
  }
  if (fields.url.value && !validationUrl(fields.url?.value)) {
    return {field: 'url', error: 'Url is incorrect'};
  }
  return undefined;
};

export const validationSendFT = (
  fields: IFieldsSendToken,
): {field: keyof IFieldsSendToken; error: string} | undefined => {
  if (!fields.address?.value?.length) return {field: 'address', error: 'Address is required'};
  if (fields.address?.value && !web3.utils.isAddress(fields.address?.value))
    return {field: 'address', error: 'Address is incorrect'};
  if (!fields.value?.value?.length) return {field: 'value', error: 'Value is required'};
  return undefined;
};

export const validationMint = (
  fields: IFieldsMineToken,
): {field: keyof IFieldsMineToken; error: string} | undefined => {
  if (!fields.to?.value?.length || !web3.utils.toChecksumAddress(fields.to?.value))
    return {field: 'to', error: 'Field "To" is incorrect'};
  if (!fields.tokenID?.value?.length) return {field: 'tokenID', error: 'TokenID is required'};
  if (!fields.name?.value?.length) return {field: 'name', error: 'Name is required'};
  if (fields.external_url?.value && !validationUrl(fields.external_url?.value))
    return {field: 'external_url', error: 'Incorrect external url'};
  if (fields.image_url?.value && !validationUrl(fields.image_url?.value))
    return {field: 'image_url', error: 'Incorrect image url'};
  return undefined;
};

export const validationUrl = (url: string): boolean => {
  const expression = /^(ftp|http|https):\/\/[^ "]+$/gi;
  return !!url.match(expression);
};

export const validatorStatus = (confirmations: number, total: number) => {
  if (confirmations >= Number(total)) {
    return 'Wait. The instance is being initialized';
  }
  return `Wait. Your transaction is being processed. Current number of confirmations: ${confirmations} / ${total}`;
};

export const getValidatorStatus = (validatorStatus?: string, instanceStatus?: string) => {
  if (!validatorStatus || !instanceStatus) {
    return 'Creating';
  }
  if ((validatorStatus === 'new' || validatorStatus === 'paid') && instanceStatus === 'init') {
    return 'Payment confirmation';
  }
  if (
    validatorStatus === 'subscriptionPaid' &&
    (instanceStatus === 'init' || instanceStatus === 'new' || instanceStatus === 'pending')
  ) {
    return 'Setup Instance';
  }
  if (
    instanceStatus === 'running' &&
    (validatorStatus === 'pending_initialized' ||
      validatorStatus === 'pending_queued' ||
      validatorStatus === 'subscriptionPaid' ||
      validatorStatus === 'paid')
  ) {
    return 'Worker activation';
  }
  if (validatorStatus === 'active_ongoing' && instanceStatus === 'running') {
    return 'Active';
  }
  return 'Something went wrong';
};

export const getInstanceStatus = (instanceStatus?: string, isSyncing?: boolean, validatorsCount?: number) => {
  if (!validatorStatus) return 'initializing';
  if (instanceStatus === 'init') return 'Payment confirmation';
  if (instanceStatus === 'new' || instanceStatus === 'pending' || !validatorsCount) return 'Setup';
  if (!isSyncing) return 'Syncing';
  if (instanceStatus === 'running') return 'Active';
  return 'Something went wrong';
};
