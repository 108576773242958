import React from 'react';
import {InputBaseProps} from './types';
import {
  InnerInput,
  InnerLabel,
  Title,
  InputWrapper,
  ErrorMessageWrapper,
  SuggestWrapper,
  Suggest,
  SuggestText,
} from './styles';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';

interface IAutoProps {
  data: any[];
  searchField: string;
  promptField?: string;
  autocompleteField: string;
  setValue: (value: string) => void;
}

export const InputAutocomplete: React.FC<InputBaseProps & IAutoProps> = ({
  type = 'text',
  onChange,
  placeholder,
  status,
  sizetype,
  value,
  label,
  placeholdertype,
  disabled,
  name,
  color,
  errorMessage,
  id,
  addonBefore,
  addonAfter,
  aligntext,
  defaultValue,
  autocompleteField,
  promptField,
  searchField,
  data,
  setValue,
}) => {
  const result = value?.length
    ? data.filter((item) => item[searchField].toLowerCase().includes(value?.toLowerCase() || ''))
    : [];
  const handleClick = (value: string) => {
    setValue(value);
  };
  return (
    <>
      <InnerLabel>
        {label && <Title>{label}</Title>}
        <InputWrapper status={status} disabled={disabled}>
          {addonBefore}
          <InnerInput
            autoComplete={'off'}
            type={type}
            status={status}
            onChange={onChange}
            placeholder={placeholder}
            sizetype={sizetype}
            name={name}
            id={id}
            value={value}
            aligntext={aligntext}
            defaultValue={defaultValue}
            placeholdertype={placeholdertype}
            color={color}
            disabled={disabled}
          />
          {errorMessage && (
            <>
              <ErrorMessageWrapper>
                <ErrorMessage errorMessage={errorMessage} />
              </ErrorMessageWrapper>
            </>
          )}
          {addonAfter}
        </InputWrapper>
        {result.length > 0 && (
          <SuggestWrapper className={'Suggest'}>
            {result.map((item, index) => (
              <Suggest key={index} onClick={() => handleClick(item[autocompleteField])}>
                <SuggestText>
                  {item[searchField]} {`| ${promptField && item[promptField]}`}
                </SuggestText>
              </Suggest>
            ))}
          </SuggestWrapper>
        )}
      </InnerLabel>
    </>
  );
};
