import MetaMaskOnboarding from '@metamask/onboarding';

export const checkMetaMask = () => {
  return !!window.ethereum?.request && MetaMaskOnboarding.isMetaMaskInstalled() && !!window.ethereum?.on;
};

export const errorValues: {[key: string]: {message: string}} = {
  '-32700': {
    message: 'Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.',
  },
  '-32600': {
    message: 'The JSON sent is not a valid Request object.',
  },
  '-32601': {
    message: 'The method does not exist / is not available.',
  },
  '-32602': {
    message: 'Invalid method parameter(s).',
  },
  '-32603': {
    message: 'Internal JSON-RPC error.',
  },
  '-32000': {
    message: 'Invalid input.',
  },
  '-32001': {
    message: 'Resource not found.',
  },
  '-32002': {
    message: 'Resource unavailable.',
  },
  '-32003': {
    message: 'Transaction rejected.',
  },
  '-32004': {
    message: 'Method not supported.',
  },
  '-32005': {
    message: 'Request limit exceeded.',
  },
  '4001': {
    message: 'User rejected the request.',
  },
  '4100': {
    message: 'The requested account and/or method has not been authorized by the user.',
  },
  '4200': {
    message: 'The requested method is not supported by this Ethereum provider.',
  },
  '4900': {
    message: 'The provider is disconnected from all chains.',
  },
  '4901': {
    message: 'The provider is disconnected from the specified chain.',
  },
};
