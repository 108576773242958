import React from 'react';
import {ClearAll, TransactionsHeader} from './styles';
import {Text} from '../../ui-kit/Typography/Typography';
import {TransactionItem} from './TransactionItem';
import {Transaction} from '../../types/transaction';

interface ITransactions {
  onClear: () => void;
  data: Transaction[];
}

export const Transactions: React.FC<ITransactions> = ({onClear, data}) => {
  const clearAll = () => {
    onClear();
  };

  return (
    <>
      {data.length ? (
        <>
          <TransactionsHeader>
            <Text color={'whiteGray'}>Recent Transactions</Text>
            <ClearAll onClick={clearAll}>Clear all</ClearAll>
          </TransactionsHeader>
          {data.map((item, index) => {
            return <TransactionItem key={index} item={item} />;
          })}
        </>
      ) : (
        <Text color={'gray'}>Your transactions will appear here.....</Text>
      )}
    </>
  );
};
