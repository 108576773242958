import React, {PropsWithChildren} from 'react';

import {ButtonColor, ButtonProps, ButtonSize, ButtonVariant} from './types';
import {Button as ButtonBase} from './styles';

// eslint-disable-next-line @typescript-eslint/ban-types
type $object = object;

// export type Diff<U1 extends any, U2 extends any> = Exclude<U1, U2> | Exclude<U2, U1>;

type Override<T extends $object, O extends $object> = {[K in Exclude<keyof O, keyof T>]?: O[K]} & {
  [K in Extract<keyof T, keyof O>]?: O[K];
};

type ButtonBaseProps<T extends $object> = {
  type?: ButtonVariant;
  size?: ButtonSize;
  color?: ButtonColor;
  selected?: boolean;
  id?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler;
  link?: boolean;
  disabled?: boolean;
} & Override<ButtonProps, T>;

export const Button = <T extends $object>({
  children,
  onClick,
  id,
  type,
  size,
  color,
  selected,
  loading,
  disabled,
  ...props
}: PropsWithChildren<ButtonBaseProps<T>>) => {
  return (
    <ButtonBase
      id={id}
      variant={type}
      size={size}
      color={color}
      selected={selected}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      {...props}>
      {children}
    </ButtonBase>
  );
};
