import React from 'react';
import {StyledLayout, Main} from './styles';

type LayoutProps = {
  topAppBar: React.ReactNode;
  footer: React.ReactNode;
  className?: string;
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({children, topAppBar, footer, className}) => {
  return (
    <StyledLayout className={className}>
      {topAppBar}
      <Main>{children}</Main>
      {footer}
    </StyledLayout>
  );
};
