import React from 'react';
import {StyledSubtitle, StyledText, StyledTitle} from './styles';
import {SubTitleBaseProps, TextBaseProps, TitleBaseProps} from './types';

export type TextProps = TextBaseProps & {as?: keyof JSX.IntrinsicElements};

export const Text: React.FC<TextProps> = StyledText;

export type TitleProps = TitleBaseProps & {as?: keyof JSX.IntrinsicElements};

export const Title: React.FC<TitleProps> = StyledTitle;

export type SubtitleProps = SubTitleBaseProps & {as?: keyof JSX.IntrinsicElements};

export const Subtitle: React.FC<SubtitleProps> = StyledSubtitle;
