import React from 'react';
import {BalanceWrapper, BalanceWrapperTable, HistoryWrapper, HomeTitle, PageWrapper} from './styles';
import {TransactionList} from './TransactionsList/TransactionList';
import {IBalance, TokenTypes, TransactionTable} from '../../types/tokens';
import {FTList} from '../Assets/FTList';
import {TabPane, Tabs} from '../../ui-kit/Tabs';
import {NFTList} from '../Assets/NFTList';
import {Layout} from '../../ui-kit/Layout';
import {InstanceType, ValidatorType} from '../../types/validator';
import {Validators} from '../Validators';
import {SendTransactionContainer} from '../../containers/Contacts/SendTransaction';

interface IProps {
  historyList: TransactionTable[];
  loadingHistory: boolean;

  balanceList: IBalance[];
  loadingBalance: boolean;
  detailPageForward: (index: number) => void;

  type: TokenTypes;
  handleSetType: (type: TokenTypes) => void;
  updateValidatorStatus: (id: string) => Promise<void>;
  dataInstances: InstanceType<ValidatorType[]>[];
  loadingValidator: boolean;
  stakingAccess: boolean;
}

export const Home: React.FC<IProps> = ({
  historyList,
  loadingHistory,
  detailPageForward,
  balanceList,
  loadingBalance,
  type,
  updateValidatorStatus,
  handleSetType,
  loadingValidator,
  dataInstances,
  stakingAccess,
}) => {
  const activeTab = type.toUpperCase();
  const handleSetFT = () => {
    handleSetType(TokenTypes.FT);
  };
  const handleSetNFT = () => {
    handleSetType(TokenTypes.NFT);
  };
  return (
    <PageWrapper>
      <Layout>
        <Validators
          stakingAccess={stakingAccess}
          data={dataInstances}
          loading={loadingValidator}
          updateValidatorStatus={updateValidatorStatus}
        />
        <HomeTitle>Water Activity</HomeTitle>
        <HistoryWrapper>
          <TransactionList historyList={historyList} loadingHistory={loadingHistory} />
          <SendTransactionContainer noModal={true} />
        </HistoryWrapper>
        <BalanceWrapper>
          <Tabs defaultActiveKey={activeTab}>
            <TabPane tab={'FT'} onClick={handleSetFT}>
              <BalanceWrapperTable>
                <FTList
                  loadingBalance={loadingBalance}
                  detailPageForward={detailPageForward}
                  balanceList={balanceList}
                />
              </BalanceWrapperTable>
            </TabPane>
            <TabPane tab={'NFT'} onClick={handleSetNFT}>
              <BalanceWrapperTable>
                <NFTList
                  detailPageForward={detailPageForward}
                  balanceList={balanceList}
                  loadingBalance={loadingBalance}
                />
              </BalanceWrapperTable>
            </TabPane>
          </Tabs>
        </BalanceWrapper>
      </Layout>
    </PageWrapper>
  );
};
