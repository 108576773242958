import styled from 'styled-components';
import {Button} from '../../ui-kit/Buttons/Button';
import {Text, Title} from '../../ui-kit/Typography/Typography';
import {Link} from 'react-router-dom';

export const ButtonWrapper = styled.div`
  width: 233px;
  height: 72px;
  margin-top: 35px;
  margin-bottom: 90px;
`;

export const LinkText = styled(Title).attrs({
  color: 'white',
  level: 2,
})`
  width: 100%;
`;

export const StyledLink = styled(Link)`
  margin-bottom: 10px;
  width: 100%;
  text-decoration: none;
`;

export const ActionButton = styled(Button).attrs({
  variant: 'secondary',
})`
  width: 125px;
`;
export const Address = styled(Text).attrs({
  level: 2,
  ellipsis: true,
})`
  padding: 0px 5px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  &:hover {
    & > span {
      visibility: visible;
    }
  }
`;

export const HiddenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6px;
  table > thead > tr > th {
    width: 120px;
    @media (max-width: 1366px) {
      width: 90px;
    }
  }
  table > thead > tr > th:nth-child(1) {
    width: 170px;
    @media (max-width: 1366px) {
      width: 150px;
    }
  }
  table > thead > tr > th:nth-child(2) {
    width: 170px;
    @media (max-width: 1366px) {
      width: 150px;
    }
  }
  table > thead > tr > th:nth-child(6) {
    width: 120px;
    @media (max-width: 1366px) {
      width: 90px;
    }
  }
  table > tbody > tr > td {
    width: 150px;
    min-width: 150px;
    @media (max-width: 1366px) {
      width: 104px;
      min-width: 104px;
    }
  }
  table > tbody > tr > td:nth-child(1) {
    width: 200px;
    min-width: 200px;
    @media (max-width: 1366px) {
      width: 164px;
      min-width: 164px;
    }
  }
  table > tbody > tr > td:nth-child(2) {
    width: 200px;
    min-width: 200px;
    @media (max-width: 1366px) {
      width: 164px;
      min-width: 164px;
    }
  }
`;

export const Tooltip = styled(Text).attrs({
  level: 1,
})`
  visibility: hidden;
  background: rgba(19, 19, 23, 0.88);
  color: #fff;
  text-align: center;
  padding: 7px 22px 9px 18px;
  border-radius: 10px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 26px;
  z-index: 1;
`;

export const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ValidatorStatus = styled(Text).attrs({
  level: 2,
})`
  left: 100px;
  position: absolute;
`;
