import React from 'react';
import {PageContainerType} from './types';
import {Wrapper, ColumnWrapper} from './styles';

export const Layout: React.FC<PageContainerType> = ({children, RightSideBar}) => {
  return (
    <Wrapper isRightSideBar={!!RightSideBar}>
      {children}
      {RightSideBar && <ColumnWrapper key={'rightSideBar'}>{RightSideBar}</ColumnWrapper>}
    </Wrapper>
  );
};
