import React, {Dispatch, SetStateAction} from 'react';
import {
  Action,
  Address,
  ColumnWrapper,
  CopyIconWrapper,
  DateText,
  HistoryWrapper,
  OperationType,
  Tooltip,
} from './styles';

import {operationTypes, TokenTypes, TransactionTable} from '../../../../types/tokens';
import {List} from '../List';
import {Columns} from '../../../../ui-kit/Table/types';
import {IconSvg} from '../../../../ui-kit/Icons/Svg';
import {CopyIcon} from '../../../../ui-kit/Icons/CopyIcon';
import {Text} from '../../../../ui-kit/Typography/Typography';
import {EXPLORER_URL} from '../../../../constants/env';

interface IPropsHistory {
  historyList: TransactionTable[];
  setPageHistory: Dispatch<SetStateAction<number>>;
  pageHistory: number;
  pageSize: number;
  totalCount: number;
  loadingHistory: boolean;
  type?: TokenTypes;
}

export const TransactionFTList: React.FC<IPropsHistory> = ({
  pageHistory,
  setPageHistory,
  historyList,
  totalCount,
  pageSize,
  loadingHistory,
}) => {
  const columns: Columns<TransactionTable> = [
    {
      label: 'From / To',
      key: 'address',
      dataIndex: 'address',
      render: (item: TransactionTable) => (
        <ColumnWrapper>
          <OperationType>
            {item.operationType === operationTypes.to ? (
              <IconSvg type="arrowOutcome" width="35" height="20" />
            ) : (
              <IconSvg type="arrowIncome" width="35" height="20" />
            )}
          </OperationType>
          <Address>{item.contactName || item.address}</Address>
          <Tooltip>{item.address}</Tooltip>
          <CopyIconWrapper>
            <CopyIcon dataToCopy={item.address} />
          </CopyIconWrapper>
        </ColumnWrapper>
      ),
    },
    {
      label: 'Amount',
      key: 'value',
      dataIndex: 'value',
      render: (item: TransactionTable) => (
        <Text color={'gray'} strong={true} level={1}>
          {item.value} {item.symbol}
        </Text>
      ),
    },
    {
      label: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (item: TransactionTable) => <Text>{item.name}</Text>,
    },
    {
      label: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (item: TransactionTable) => <DateText>{item.date}</DateText>,
    },
    {
      label: 'Type',
      key: 'operation',
      dataIndex: 'operation',
      render: (item: TransactionTable) => <Text>{item.operation}</Text>,
    },
    {
      label: 'Explorer',
      dataIndex: 'link',
      render: (item: TransactionTable) => (
        <Action target={'_blank'} href={`${EXPLORER_URL}/tx/${item.link}`}>
          <IconSvg type="arrowRightLong" width="50" height="22" />
        </Action>
      ),
    },
  ];
  return (
    <>
      <HistoryWrapper>
        <List
          emptyMessage={'There are no transactions yet'}
          columns={columns}
          historyList={historyList}
          pageHistory={pageHistory}
          setPageHistory={setPageHistory}
          pageSize={pageSize}
          loadingHistory={loadingHistory}
          totalCount={totalCount}
        />
      </HistoryWrapper>
    </>
  );
};
